import axios from "axios";
import {API} from './api.js';
const API_URL = API + '/auth/';
//const API_URL = "http://localhost:3000/api/auth/";
//const API_URL = "http://192.168.3.108:3000/api/auth/";

const sessionData = sessionStorage.getItem('user');
const userInfo = JSON.parse(sessionData);
//console.log(userInfo)
const register = (username, lastname, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    lastname,
    email,
    password,
  })
};

const login = async (email, password, rememberMe) => {
  const response = await axios
    .post(API_URL + "signin", {
      email,
      password,
    });
  //console.log(response.data);
  if (response.data.username) {
    localStorage.setItem("user", JSON.stringify(response.data));
    sessionStorage.setItem("user", JSON.stringify(response.data));
    localStorage.setItem("rememberMe", JSON.stringify({ "rememberMe": rememberMe }));

  }
  return response.data;
};

const login1 = (email, password, rememberMe) => {
  return axios
    .post(API_URL + "signin", {
      email,
      password,
    })
    .then((response) => {
      //console.log(response.data);
      if (response.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data));
        sessionStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("rememberMe", JSON.stringify({"rememberMe":rememberMe}));
                 
      }
      return response.data;
      
    });
};


const logout = async () => {
  let email = userInfo.email;
  let role = userInfo.role;
  localStorage.removeItem("user");
  sessionStorage.removeItem("user");
  localStorage.removeItem("rememberMe");
  //console.log(email)
  try{
    const response = await axios.post(API_URL + "signout", { email, role });
    return response.data;
  }catch (error) {
    // Handle errors (logging, rethrowing, etc.)
    console.error('Error during logout:', error);
    throw error; // Optional: Rethrow the error to propagate it
  }
  };

const getCurrentUser = () => {
  //return JSON.parse(localStorage.getItem("user"));
  return JSON.parse(sessionStorage.getItem("user"));
};


//Send Profile to to Server
const uploadProfilePhoto = (email, singleImage) => {

  let singleImageFile = new FormData();
  singleImageFile.append("fileName", email);
  singleImageFile.append("file", singleImage);
  

  
  return axios.post(API_URL + "uploadProfilePhoto", 
  singleImageFile,
  { headers: {'Content-Type': 'multipart/form-data'}}
  
  )
};

//Receive Profile to to Server
const downloadProfilePhoto = (email) => {

  return axios.get(API_URL + `downloadProfilePhoto/${email}`,
  {
    responseType: 'arraybuffer'
  }
  )
};


const changepassword = (email, password, newpassword, remark) => {
  return axios.post(API_URL + "changepassword", {
    email,
    password,
    newpassword,
    remark,
  })
};

const changeuserinfo = async (userid, username, lastname, email, password, remark) => {
  let role = userInfo.role;
  const response = await axios.post(API_URL + "changeuserinfo", {
    userid,
    username,
    lastname,
    email,
    password,
    remark,
    role,
  });
  //console.log(response)
  if (response.data.username) {
    localStorage.setItem("user", JSON.stringify(response.data));
    sessionStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const selectedProject = async (email, selectedproject) => {
  //console.log(email);

  const response = await axios.post(API_URL + "selectedProject", {
    email,
    selectedproject,
  });
  //console.log(response)
  if (response.data.username) {
    localStorage.setItem("user", JSON.stringify(response.data));
    sessionStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

// Get all rows data present inside the table for specific column/field value
const getuserlist = (dashboardName, columnName, columnValue) => {
  //console.log(dashboardName, columnName, columnValue)
  return axios.post(API_URL + "getuserlist", {
    dashboardName,
    columnName,
    columnValue,
  });
 
};

//Add user with details like username, lastname, email, password
const adduser = (dashboardName, rowData, auditTrailData) => {
  let role = userInfo.role;
  return axios.post(API_URL + "adduser", {
    dashboardName, 
    rowData,
    auditTrailData,
  })
};

//edit user
const edituser = (dashboardName, rowData, auditTrailData) => {
  let role = userInfo.role;
  return axios.post(API_URL + "edituser", {
    dashboardName, 
    rowData,
    auditTrailData,
  })
};

// Delete specific document/record from existing collection/table
const deleteuser = (dashboardName, rowData, auditTrailData) => {
  //let role = userInfo.role;
  return axios.post(API_URL + "deleteuser", {
    dashboardName,
    rowData,
    auditTrailData,
    })
};


const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  uploadProfilePhoto,
  changeuserinfo,
  changepassword,
  downloadProfilePhoto,
  selectedProject,
  getuserlist,
  adduser,
  edituser,
  deleteuser,
}

export default AuthService;
