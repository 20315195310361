import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';

import { TextField, Button, FormControl, makeStyles } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import UserManagementService from '../../services/usermanagement.service.js';
import AuditTrailViewService from '../../services/audittrailview.service.js';
import ConfigurationViewService from '../../services/configurationview.service.js';
import TableViewService from '../../services/tableview.service.js';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// Function to create auditTrailData
const createAuditTrailData = (oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus, parameterName) => {
  
  let auditTrailData = [];
  // Get the keys of the oldValues
  const keys1 = Object.keys(oldValues);
  //console.log(keys1)
  //console.log(Object.keys(newValues))
  //console.log(keys1.length, Object.keys(newValues).length)
  // Check if the number of keys is the same
  if (keys1.length !== Object.keys(newValues).length) {
    return auditTrailData;
  }else{
    // Iterate over the keys of the oldValues
    for (let key of keys1) {
      //console.log(key, oldValues[key], newValues[key]);
      // Check if the value of each key in oldValues matches the value in newValues
      if (oldValues[key] !== newValues[key]) {
        let activityStatus = activity;
        // modify Activity if event is enabled else activity will be deafualt value received form audittrail config collection
        if(eventStatus === "Enabled"){
          if(key === "Enable"){
            activityStatus = `for table - '${tagName}' and for '${parameterName}', status was modified from '${oldValues[key]}' to '${newValues[key]}'`;
            auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
          }

          if(key === "Description"){
            activityStatus = `for table - ${tagName} and for '${parameterName}', description was modified from '${oldValues[key]}' to '${newValues[key]}'`;
            auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
          }
          
          
        } else{
          if(key === "ParameterName"){
          auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
          }
        }  
      }
    }
    return auditTrailData;
  }

  

};

function SaveChangesDialog(props) {
  const [message, setMessage] = useState();

  const [open, setOpen] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFaildialog, setOpenFaildialog] = useState(false);

  const [saveData, setSaveData] = useState(false);

  const [auditTrailData, setAuditTrailData] = React.useState([]);  // AuditTrail Data

  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);
  const [password, setPassword] = useState('');
  const[pwdinterlock, setPwdInterlock] = useState("Disabled");
  

  const handleClickOpen = () => {
    if(((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === "--")) || ((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === ""))){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
    setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRemarkBlnkMsg(false);
  };

  const handleSuccessClose = () => {
    setOpenSuccessDialog(false);
    props.success(true);
  };

  const handleFildClose = () => {
    setOpenFaildialog(false);
  };
  
  
  const handleClickSaveChanges = () => { 
    let oldValues = props.oldValues;
    let newValues = props.newValues;
    let tagName = props.tagName;
    let activity = props.activity;
    let activityType = 'Edit Table Configuration';
    let userName = props.email;
    let role = props.role;
    let remark = props.auditTrailRemark;
    let eventStatus = props.rowData.EventStatus;

    let parameterName = props.rowData.ParameterName;

    let auditTrailData = createAuditTrailData(oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus, parameterName )
    //console.log(auditTrailData)
    setAuditTrailData(auditTrailData)
    setSaveData(true);
  };

  //Get Application Configuration data 
  useEffect(() =>{
    async function getColumnData()  {
      try {

        let dashboardName = "ApplicationConfig";
        const response = await ConfigurationViewService.getAllRowsData(dashboardName);

        if(response.data.length > 0){
          //setPwdInterlock(response.data[11].TaskStatus);
          //console.log(response.data[11].TaskStatus); 
        }

        
        
      } catch (error) {
        console.log(error);

      }
    }
    getColumnData();



  }, []);
  

  //  update existing table info or GrouptList
  useEffect(() =>{
    async function saveRowData()  {
    try {

      let dashboardName = "TableConfig"
      let rowData = {...props.rowData, PWDInterlock: props.rowData.PWDInterlock, PWD: password};
      //console.log(props.rowData);
      // Blank value check for the  one of the form field
      if (rowData.Description === '' || rowData.Width=== '' ) {
        setOpenFaildialog(true);
        setMessage("one or more fields are blank, please correct it.");
        setSaveData(false);
        setOpen(false); // Close "Save changes" window

      } else {
        //console.log(rowData);
        //let response = false;
        const response = await TableViewService.updateRowData(dashboardName, rowData, auditTrailData);  // update new record if id is present in array data ie existing data
        if(response){
          //console.log(response.data);
          setOpenSuccessDialog(true);
          setMessage("Record Edited Successfully.");
          setSaveData(false);
          setOpen(false); // Close "Save changes" window
        }
      }

    } catch (error) {
      setOpenFaildialog(true);
      setMessage("Error While Editing Record, Please very all parameters entered in form");
      setOpen(false); // Close "Save changes" window
      console.log(error);


    }
  };
  if(saveData){
    saveRowData();
    setSaveData(false);
  };


  }, [saveData]);

 //console.log(props.rowData)
  

  return (
    
    <div>
      <Button variant="outlined" startIcon ={<SaveIcon/>} sx={{mt:1}} onClick={handleClickOpen}>SAVE CHANGES </Button>

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Open Dialog to ask surity to edit user's data, with or withoout password */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>SAVE CHANGES</DialogTitle>
        <Box sx={{width: 400 }}>
        <DialogContent dividers>
          <DialogContentText>
            Are you sure to edit table details ?
          </DialogContentText>
          
        </DialogContent>

        {props.rowData.PWDInterlock === 'Enabled' && (<DialogContent dividers>
          <DialogContentText>
            Enter the Password to edit the table description or user groups assigned to it.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          </DialogContent>
        )}

        </Box>
        <DialogActions>
          <Button onClick={handleClickSaveChanges}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Success dialog after upgradtion of teh user info. */}
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessClose}
       
      >
        <DialogTitle > success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Save Changes Failed dialog /Wrong Password */}
      <Dialog
        open={openFaildialog}
        onClose={handleFildClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFildClose}>Close</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}

export default function TableConfigEdit ({params, open, close}) {

  const { user } = useSelector((state) => state.auth);
  const projectName = user.selectedproject;
  const dashboardType = 'TableView';
  const [widgetName, setWidgetname] = useState("");
  const splitbutton1Status = useSelector((state) => state.splitbutton1.widgetName);

  const initialUserData = {
    ProjectName:params.row.ProjectName,
    WidgetName:params.row.WidgetName,
    DashboardType:params.row.DashboardType,
    
    Enable: params.row.Enable,
    Description: params.row.Description,
    Width: params.row.Width,
    Color: params.row.Color,
    FontWeight: params.row.FontWeight,
    FontSize: params.row.FontSize,
    TextAlignment: params.row.TextAlignment,
    BorderStyle: params.row.BorderStyle,
    
    SrNo: params.row.SrNo,
    ParameterName: params.row.ParameterName,
    __v: params.row.__v,
    _id: params.row._id,

    email:user.email,
    role: user.role,
    RemarkStatus : "Disabled",
    EventStatus:"Disabled",
    PWDInterlock:"Disabled",
      
  };
  const [eventDescription, setEventDescription] = useState("--");
  const [auditTrailRemark, setAuditTrailRemark] = useState("--");
  // parameter list for audit trail log
  const oldValues = {Enable: params.row.Enable, Description: params.row.Description, Width: params.row.Width, Color: params.row.Color,
    FontWeight: params.row.FontWeight, FontSize: params.row.FontSize, TextAlignment: params.row.TextAlignment, BorderStyle: params.row.BorderStyle,
  };

  const [newValues, setNewValues] = useState(oldValues);

  const [tableListData, setTableListData] = React.useState(initialUserData);
  
  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Edit Table Configuration';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setTableListData({...tableListData, EventStatus: response.data[0].EnableEvent, PWDInterlock: response.data[0].EnablePwdInterlock, RemarkStatus: response.data[0].EnableRemark});

        setEventDescription(response.data[0].DefaultEventDescription);
        // If remark field is disabled then set default remark vlaue to currrent Remark value
        if(response.data[0].EnableRemark === "Disabled"){
          setAuditTrailRemark(response.data[0].DefaultEventRemark);
        }
        
      }
  
    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);

 
  const handleTextFieldChange = (event) => {

    const {name, value} = event.target;
    setTableListData({...tableListData, [name]:value});
    setNewValues ({...newValues, [name] :value});
  };


  const handleClose = () => {
    //console.log("Close Buton Pressed");
    close() // Close the alarm add main window
  };

  const handleRecordSaveSuccess = (data) =>{
    close(data)
  }



  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    if(tableListData.RemarkStatus === "Enabled"){
      setAuditTrailRemark(value);

    }
  };

  const disableColorSrNo = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 17, 18, 21, 23, 30, 31, 32, 35,36]
  const disableColor = disableColorSrNo.includes(parseInt(tableListData.SrNo));

  const SrNoToCheckForDisableStatus = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,27, 28, 29, 33, 34, 35, 36];
  const disable14to29Column = SrNoToCheckForDisableStatus.includes(parseInt(tableListData.SrNo));

  const disable14to32SrNo = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,27, 28, 29,30, 31, 32, 33, 34, 35, 36]
  const disable14to32Column = disable14to32SrNo.includes(parseInt(tableListData.SrNo));


  return (
    <div>
      
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            maxWidth: '600px', // Change this value as needed
          },
        }}
      >
        {/* Hnadle multiple functions on single click ie edit switch set to false and close the MyAccount Dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose();  }}>
          Edit table Configuration of the table columns.
        </BootstrapDialogTitle>

        {/*Edit user configuration of selected row*/}
        <DialogContent dividers>

          
          
          {/* Parameter Name */}
          <TextField
            autoFocus
            margin="dense"
            label="Parameter Name"
            type="text"
            fullWidth
            variant="standard"
            name="ParameterName"
            value={tableListData.ParameterName}
            onChange={handleTextFieldChange}
            disabled={true}
          />

          {/* Status */}
          <FormControl sx={{ m: 0, width: 200, mt: 1 }} variant="standard">
              <InputLabel id="demo-customized-select-label">Parameter Status</InputLabel>
              <Select
              autoFocus
              name="Enable"
              //value={tableListData.Enable}
              value={(tableListData.Enable !== null) ? tableListData.Enable : 'Enabled'}
              onChange={handleTextFieldChange}
              input={<BootstrapInput />}
              disabled = {disable14to29Column}
              >
              <MenuItem value={'Enabled'}>Enabled</MenuItem>
              <MenuItem value={'Disabled'}>Disabled</MenuItem>
              </Select>
          </FormControl>


          {/* Description */}
          <TextField
            autoFocus
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            name="Description"
            value={tableListData.Description}
            onChange={handleTextFieldChange}
            disabled={disable14to32Column}
          />

          {/* Width */}
          <TextField
            autoFocus
            margin="dense"
            label="Width"
            type="number"
            fullWidth
            variant="standard"
            name="Width"
            value={tableListData.Width}
            onChange={handleTextFieldChange}
            disabled={disable14to32Column}
          />

          {/* TextAlignment */}
          <TextField
            autoFocus
            margin="dense"
            label="TextAlignment"
            type="text"
            fullWidth
            variant="standard"
            name="TextAlignment"
            value={tableListData.TextAlignment}
            onChange={handleTextFieldChange}
            disabled={disable14to32Column}
          />

          {/* Color */}
          <TextField
            autoFocus
            margin="dense"
            label="Color"
            type="text"
            fullWidth
            variant="standard"
            name="Color"
            value={tableListData.Color}
            onChange={handleTextFieldChange}
            disabled={disableColor}
          />

          {/* FontWeight */}
          <TextField
            autoFocus
            margin="dense"
            label="FontWeight"
            type="text"
            fullWidth
            variant="standard"
            name="FontWeight"
            value={tableListData.FontWeight}
            onChange={handleTextFieldChange}
            disabled={(tableListData.SrNo !== "17") && (tableListData.SrNo !== "22") && (tableListData.SrNo !== "36")}
          />

          {/* FontSize */}
          <TextField
            autoFocus
            margin="dense"
            label="FontSize"
            type="number"
            fullWidth
            variant="standard"
            name="FontSize"
            value={tableListData.FontSize}
            onChange={handleTextFieldChange}
            disabled={(tableListData.SrNo !== "16") && (tableListData.SrNo !== "21") && (tableListData.SrNo !== "35")}
          />

          

          {/* BorderStyle */}
          <TextField
            autoFocus
            margin="dense"
            label="BorderStyle"
            type="text"
            fullWidth
            variant="standard"
            name="BorderStyle"
            value={tableListData.BorderStyle}
            onChange={handleTextFieldChange}
            disabled={(tableListData.SrNo !== "18") && (tableListData.SrNo !== "23")}
          />

        </DialogContent>

        {/*Remark */}
        {tableListData.RemarkStatus === 'Enabled' && (<DialogContent 
        dividers
        >
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 400 }}>
          Enter remark/reason 
          </Typography>

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            name="Remark"
            placeholder="Enter Remark"
            multiline
            rows={2}
            style={{ width: 550 }}
            onChange={handleRemarkChange}
            //defaultValue="Default Value"
          />

        </DialogContent>
        )}

        <DialogActions>
          <SaveChangesDialog
          projectName = {projectName}
          dashboardType = {dashboardType}
          widgetName = {splitbutton1Status}
          id = {params.row._id}
          rowData = {tableListData}

          email = {user.email}
          role = {user.role}
          oldValues = {oldValues}
          newValues = {newValues}
          activity = {eventDescription}
          tagName = {tableListData.WidgetName}
          auditTrailRemark = {auditTrailRemark}

          success = {handleRecordSaveSuccess}
          />
        </DialogActions>
        
      </BootstrapDialog>
    </div>
  );
}