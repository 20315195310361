import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { TextField, Button, FormControl, makeStyles } from '@mui/material';


import ConfigurationViewService from '../../services/configurationview.service';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

// Check blank values inside the row data
function hasBlankOrNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === '') {
      return true; // Found a blank or null value
    }

    if (typeof value === 'object' && hasBlankOrNullValues(value)) {
      return true; // Recursively check nested objects
    }
  }

  return false; // No blank or null values found
}



function SaveChangesDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {

    setOpen(true);
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccessClose = () => {
    setOpenSuccessDialog(false);
    props.success(true);
  };

  const handleFildClose = () => {
    setOpenFaildialog(false);
  };
  

  const [message, setMessage] = useState();

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFaildialog, setOpenFaildialog] = useState(false);

  const [saveData, setSaveData] = useState(false);
  const handleClickSaveChanges = () => { 
     setSaveData(true);
     };

  const [tagNameList, setTagNameList] = useState([]);   
  // Get  All row data from alarmconfig
 useEffect(() =>{
  async function getAllRowsData()  {
  try {
    let dashboardName = "AlarmConfig"
    const response = await ConfigurationViewService.getAllRowsData(dashboardName);
    if (response){
      setTagNameList(response.data.map((item) => item.TagName));
    }
    
    
  } catch (error) {
    console.log(error);

  }
};
getAllRowsData();
}, []);

  //  Add new row to alarmconfig collection 
  useEffect(() =>{
    async function saveRowData()  {
    try {

      let dashboardName = "AlarmConfig"
      let data = props.rowData;

      // Blank value check for the  one of the form field
      if (hasBlankOrNullValues(data)) {
        setOpenFaildialog(true);
        setMessage("One of the field is blank inside the form, please correct it.");
        setSaveData(false);
        setOpen(false); // Close "Save changes" window

      } else {
        // Tag Duplcation check for the Alarm
        if (tagNameList.includes(data.TagName)) {
          setOpenFaildialog(true);
          setMessage("Alarm already exist for the selected Tag, Choose different Tag");
          setSaveData(false);
          setOpen(false); // Close "Save changes" window

        }else{

          const response = await ConfigurationViewService.createRow(dashboardName, data);  // create new record if id is not present in array data ie existing data
          if(response){
            setOpenSuccessDialog(true);
            setMessage("Record Added Successfully");
            setSaveData(false);
            setOpen(false); // Close "Save changes" window
    
          }

        }

       }

      

     
    } catch (error) {
      setOpenFaildialog(true);
      setMessage("Error While Adding Record, Please very all parameters entered in form");
      setOpen(false); // Close "Save changes" window
      console.log(error);


    }
  };
  if(saveData){
    saveRowData();
    setSaveData(false);
  };


  }, [saveData]);
  

  return (
    
    <div>
      <Button variant="outlined" startIcon ={<SaveIcon/>} sx={{mt:1}} onClick={handleClickOpen}>SAVE CHANGES </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>SAVE CHANGES</DialogTitle>
        <Box sx={{width: 400 }}>
        <DialogContent dividers>
          <DialogContentText>
            Are you sure to add the Alarm configuration for selected tag.
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClickSaveChanges}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Success dialog after upgradtion of teh user info. */}
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessClose}
       
      >
        <DialogTitle > success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Save Changes Failed dialog /Wrong Password */}
      <Dialog
        open={openFaildialog}
        onClose={handleFildClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFildClose}>Close</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}


export default function WidgetConfigAdd ({open, close}) {

  const initialAlarmConfigData = {
    //id: null,
    AlarmStatus: "Enabled",
    BlockName: "",
    TagName: "",
    TagValue:"0",
    PresentAlarmStatus:"Normal",

    HiHiEnable: "Enabled",
    HiHiAlarmDescription: "Alarm High High",
    HiHiSP: "90",
    AlarmHiHi: "0",

    HighEnable: "Enabled",
    HighAlarmDescription: "Alarm High",
    HighSP: "80",
    AlarmHigh: "0",

    LoLoEnable: "Enabled",
    LoLoAlarmDescription: "Alarm Low Low",
    LoLoSP: "20",
    AlarmLoLo: "0",

    LowEnable: "Enabled",
    LowAlarmDescription: "Alarm Low",
    LowSP: "10",
    AlarmLow: "0",

    AlarmInRange: "Enabled",
    ConcatPVInAlarm: "Enabled",
    ConcatSPInAlarm: "Enabled",
    Alarmcknowledgement: "Enabled",
    AlarmInRangeDescription: "Normal",
    AlarmDelay: "0",
    AlarmHysterisis: "0",
        
  };

  const [alarmConfigData, setAlarmConfigData] = React.useState(initialAlarmConfigData);
  const[blockNames, setBlockNames] = React.useState([]);
  const[tagNames, setTagNames] = React.useState([]);


  const[fetchTagList, setFetchTagList] = React.useState(false); 

  
  // Get  All row data from modbus queries
  useEffect(() =>{
    async function getAllRowsData()  {
    try {

      let dashboardName = "ModbusConfig"

      const response = await ConfigurationViewService.getAllRowsData(dashboardName);
      if (response){
  
        setBlockNames(response.data.map((item) => item.BlockName)); 
      }
      
      
    } catch (error) {
      console.log(error);

    }
  };
  getAllRowsData();

  }, []);

  // Get  taglist from the modbustaglist for specific BlockName.
  useEffect(() =>{
    async function getAllRowsData()  {
    try {

      let dashboardName = "ModbusTagList"
      let columnName = "BlockName"
      let columnValue = alarmConfigData.BlockName;

      const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue);
      if (response){
        setTagNames(response.data.map((item) => item.TagName)); 
      }
      
      
    } catch (error) {
      console.log(error);

    }
  }
  if(fetchTagList){
    getAllRowsData();
    setFetchTagList(false);
    
  };


  }, [fetchTagList]);

  const handleBlockNameChange = (event) => {
    const {name, value} = event.target;
    setAlarmConfigData({...alarmConfigData, [name]:value});
    setFetchTagList(true);  // set true to Get Taglist for the selected BlockNmae
  };

  const handleTextFieldChange = (event) => {
    const {name, value} = event.target;
    setAlarmConfigData({...alarmConfigData, [name]:value});
  };


  const handleCheckboxChange = (event) => {

    if (event.target.checked){
      setAlarmConfigData({...alarmConfigData, [event.target.name]:"Enabled"});
    }else{

      setAlarmConfigData({...alarmConfigData, [event.target.name]:"Disabled"});
    }
    //console.log(alarmConfigData.LoLoEnable);
  };

  const handleClose = () => {
    //console.log("Close Buton Pressed");
    close() // Close the alarm add main window
  };

  const handleRecordSaveSuccess = (data) =>{
    close(data)
  }

  return (
    <div>
      
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            maxWidth: '1000px', // Change this value as needed
          },
        }}
      >
        {/* Hnadle multiple functions on single click ie edit switch set to false and close the MyAccount Dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose();  }}>
          Add Alarm configuration.
        </BootstrapDialogTitle>

        {/* Enable/disable alarm and tag selction for the alarm */}
        <DialogContent dividers>
          <Typography gutterBottom>
          Tag Selection for new Alarm.. 
          </Typography>

          {/* AlarmStatus */}
          <FormControl sx={{ m: 0, width: 200, mt: 1 }} variant="standard">
              <InputLabel id="demo-customized-select-label">Alarm Status</InputLabel>
              <Select
              autoFocus
              name="AlarmStatus"
              value={alarmConfigData.AlarmStatus}
              onChange={handleTextFieldChange}
              input={<BootstrapInput />}
              >
              <MenuItem value={'Enabled'}>Enabled</MenuItem>
              <MenuItem value={'Disabled'}>Disabled</MenuItem>
              </Select>
          </FormControl>

          {/* blockName */}
          <FormControl sx={{ m: 2, width: 200, mt: 1 }} variant="standard">
              <InputLabel id="demo-customized-select-label">Block Name</InputLabel>
              <Select
                name="BlockName"
                value={alarmConfigData.BlockName}
                onChange={handleBlockNameChange }
                input={<BootstrapInput />}
              >
                {/* <MenuItem disabled value={alarmConfigData.BlockName}><em>{blockNames[0]}</em></MenuItem> */}

                {blockNames.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    //style={getStyles(name, alarmConfigData.BlockName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>

          {/* TagName */}
          <FormControl sx={{ m: 0, width: 200, mt: 1 }} variant="standard">
              <InputLabel id="demo-customized-select-label">Tag Name</InputLabel>
              <Select
                name="TagName"
                value={alarmConfigData.TagName}
                onChange={handleTextFieldChange}
                input={<BootstrapInput />}
              >
               {/* <MenuItem disabled value={alarmConfigData.TagName}><em>{tagNames[0]}</em></MenuItem> */}

                {tagNames.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    //style={getStyles(name, alarmConfigData.BlockName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>

        </DialogContent>

        {/* Alarm Configuration */}
        <DialogContent dividers>
          <Typography gutterBottom>
            Alarm Configuration.. 
          </Typography>

          {/* HiHiEnable */}
          <FormControlLabel control={<Checkbox defaultChecked name="HiHiEnable" onChange={handleCheckboxChange} />} label="High High Alarm Enable" />
          
          {/* HiHiAlarmDescription */}
          <TextField
            autoFocus
            margin="dense"
            label="HiHi Alarm Description"
            type="text"
            fullWidth
            variant="standard"
            name="HiHiAlarmDescription"
            value={alarmConfigData.HiHiAlarmDescription}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* HiHiSP */}
          <TextField
            id="outlined-number"
            InputLabelProps={{
              shrink: true,
            }}

            margin="dense"
            label="HiHi Alarm Setpoint"
            type="number"
            fullWidth
            variant="standard"
            name="HiHiSP"
            value={alarmConfigData.HiHiSP}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* HighEnable */}
          <FormControlLabel control={<Checkbox defaultChecked name="HighEnable" onChange={handleCheckboxChange} />} label="High Alarm Enable" />

          {/* HighAlarmDescription */}
          <TextField
            margin="dense"
            label="High Alarm Description"
            type="text"
            fullWidth
            variant="standard"
            name="HighAlarmDescription"
            value={alarmConfigData.HighAlarmDescription}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* HighSP */}
          <TextField
            margin="dense"
            label="High Alarm Setpoint"
            type="number"
            fullWidth
            variant="standard"
            name="HighSP"
            value={alarmConfigData.HighSP}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* LowEnable */}
          <FormControlLabel control={<Checkbox defaultChecked name="LowEnable" onChange={handleCheckboxChange} />} label="Low Alarm Enable" />

          {/* LowAlarmDescription */}
          <TextField
            margin="dense"
            label="Low Alarm Description"
            type="text"
            fullWidth
            variant="standard"
            name="LowAlarmDescription"
            value={alarmConfigData.LowAlarmDescription}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* LowSP */}
          <TextField
            margin="dense"
            label="Low Alarm Setpoint"
            type="number"
            fullWidth
            variant="standard"
            name="LowSP"
            value={alarmConfigData.LowSP}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* LoLoEnable */}
          <FormControlLabel control={<Checkbox defaultChecked name="LoLoEnable" onChange={handleCheckboxChange} />} label="Low Low Alarm Enable" />

          {/* LoLoAlarmDescription */}
          <TextField
            margin="dense"
            label="Low Low Alarm Description"
            type="text"
            fullWidth
            variant="standard"
            name="LoLoAlarmDescription"
            value={alarmConfigData.LoLoAlarmDescription}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* LoLoSP */}
          <TextField
            margin="dense"
            label="Low Low Alarm Setpoint"
            type="number"
            fullWidth
            variant="standard"
            name="LoLoSP"
            value={alarmConfigData.LoLoSP}
            onChange={handleTextFieldChange}
            disabled={false}
          />

                 

        </DialogContent>

        {/* Common Settings */}
        <DialogContent dividers>
          <Typography gutterBottom>
            Common Settings.. 
          </Typography>

          {/* AlarmInRange */}
          <FormControlLabel control={<Checkbox defaultChecked name="AlarmInRange" onChange={handleCheckboxChange} />} label="Enable Alarm in Range" />

          {/* ConCatPVInAlarm */}
          <FormControlLabel control={<Checkbox defaultChecked name="ConcatPVInAlarm" onChange={handleCheckboxChange} />} label="Enable Concat PV in Alarm" />

          {/* ConCatSPInAlarm */}
          <FormControlLabel control={<Checkbox defaultChecked name="ConcatSPInAlarm" onChange={handleCheckboxChange} />} label="Enable Concat SP in Alarm" />

          {/* AlarmAcknowledgement */}
          <FormControlLabel control={<Checkbox defaultChecked name="AlarmAcknowledgement" onChange={handleCheckboxChange} />} label="Enable Aknowledgement" />
          
          {/* AlarmInRangeDescription*/}
          <TextField
            autoFocus
            margin="dense"
            label="Alarm In Range Description"
            type="text"
            fullWidth
            variant="standard"
            name="AlarmInRangeDescription"
            value={alarmConfigData.AlarmInRangeDescription}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* AlarmDelay*/}
          <TextField
            autoFocus
            margin="dense"
            label="Alarm Delay"
            type="number"
            fullWidth
            variant="standard"
            name="AlarmDelay"
            value={alarmConfigData.AlarmDelay}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* AlarmHysterisis */}
          <TextField
            margin="dense"
            label="Alarm Hysterysis"
            type="number"
            fullWidth
            variant="standard"
            name="AlarmHysterisis"
            value={alarmConfigData.AlarmHysterisis}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          

        </DialogContent>

        <DialogActions>
          <SaveChangesDialog
          rowData = {alarmConfigData}
          success = {handleRecordSaveSuccess}
          />
        </DialogActions>
        
      </BootstrapDialog>
    </div>
  );
}



