import * as React from 'react';
import {useState, useEffect, useCallback} from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useSelector } from "react-redux";

export default function ProjectDescription({projectName, projectDescrprops}) {
  const { user } = useSelector((state) => state.auth);

  return (
    <React.Fragment>
      {/* Project description */}
      

      <Box
      component="main"
      sx={{
        backgroundColor: projectDescrprops.backgroundColor,
        //display: 'flex',            // Use flex layout
        //flexGrow:1,
        //flexDirection: 'column',    // Align items in a column
        //justifyContent: 'center',   // Center vertically
        //alignItems: 'center',       // Center horizontally
        //height: `${parseInt(projectDescrprops.minHeight, 10)}vh`,
        //minWidth: `${parseInt(projectDescrprops.minWidth, 10)}vw`,
        //maxWidth: `${parseInt(projectDescrprops.minWidth, 10)}vw`,
        //boxSizing: 'border-box',
        //border: '1px solid #ccc',
      }}
    >
      <Typography
        component="h1"
        variant="h6"
        noWrap
        sx={{
          textAlign: 'center',
          fontSize: `${parseInt(projectDescrprops.fontSize)}px`, // Ensure units are correct
          fontWeight: parseInt(projectDescrprops.fontWeight),
          color: projectDescrprops.textColor,
          width: '100%',
        }}
      >
        {user.projectdescr}
      </Typography>
    </Box>
      
    </React.Fragment>
  );
}


