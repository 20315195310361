import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SplitButton1 from './splitButton1.js';


import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  //console.info('You clicked a breadcrumb.');
}

export default function CustomizedBreadcrumbs({projectName, dashboardType, selectedDashboard}) {
    const [selectedItem, setSelectedItem] = React.useState('');

    const handleChange = (event) => {
        setSelectedItem(event.target.value);
      };


  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <StyledBreadcrumb
          component="a"
          href="#"
          label={projectName}
          icon={<HomeIcon fontSize="small" color="inherit"/>}
        />

        <StyledBreadcrumb component="a" href="#" label={dashboardType} />
        
        {(dashboardType !== "DashboardView") && (<SplitButton1
          projectName = {projectName}
          dashboardType = {dashboardType}
          selectedDashboard = {selectedDashboard}
        />
      )}

      {(dashboardType === "DashboardView") && ( <div>
        <SplitButton1
          projectName = {projectName}
          dashboardType = {dashboardType}
          selectedDashboard = {selectedDashboard}
        />

        <SplitButton1
          projectName = {projectName}
          dashboardType = {dashboardType}
          selectedDashboard = {selectedDashboard}
        />
        </div>
      )}


      </Breadcrumbs>

      
    </div>
  );
}