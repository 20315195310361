import * as React from 'react';
import {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextField, Button, FormControl, makeStyles } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DoNotTouchOutlinedIcon from '@mui/icons-material/DoNotTouchOutlined';
import {GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, GridEditInputCell} from '@mui/x-data-grid';
import { createFakeServer } from '@mui/x-data-grid-generator';
import { useDispatch, useSelector } from "react-redux";

import TableViewService from '../../services/tableview.service';
import AuditTrailViewService from '../../services/audittrailview.service.js';
import ConfigurationViewService from '../../services/configurationview.service.js';
import DashboardAdd from './DashboardAdd.js';
import DashboardEdit from './DashboardEdit.js';

import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { styled } from '@mui/material/styles';

import {Resizable, ResizableBox} from "re-resizable";

import Alert from '@mui/material/Alert';
import { Hidden } from '@mui/material';


// Add form open for tad addition in selected widget/dashboard.
function EditToolbar(props) {
  const { addTagIconStatus, setAddRecordForm, widgetDescription, configData} = props;
  //console.log(configData)
  //console.log(addTagIconStatus, configData[33].Color)
  const handleClick = () => {
    setAddRecordForm(true);
  };

  return (
    <GridToolbarContainer
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: configData[32].Color,
    }}
    >
      {(addTagIconStatus === "Enabled") &&(
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        
        </Button>
      )}

      <span 
        style={{ 
          marginLeft: 'auto',
          marginRight: 'auto',
          fontWeight: configData[35].FontWeight, // Make text bold
          fontSize: parseInt(configData[34].FontSize), // Set font size to 16
          color: configData[33].Color, // Set color to black

        }}>
          {widgetDescription}
        </span>

      
      
    </GridToolbarContainer>
  );
}

// Calculate the total width required for all columns based on content
const calculateTotalWidth = (rows, columns) => {
  let totalWidth = 0;
  columns.forEach(column => {
    const maxWidthColumn = rows.reduce((max, row) => {
      const cellValue = row[column.field] || '';
      const cellWidth = cellValue.toString().length * 10; // Adjust this multiplier based on your font size
      return cellWidth > max ? cellWidth : max;
    }, 0);
    totalWidth += maxWidthColumn + 32; // Add some padding
  });
  return totalWidth;
};

export default function Dasboard() {
  const { user } = useSelector((state) => state.auth);
  const projectName = user.selectedproject;
  const dashboardType = 'TableView';
  const widgetName = useSelector((state) => state.splitbutton1.widgetName);
  const widgetDescription = useSelector((state) => state.splitButton1Description.widgetDescription);
  //console.log(widgetDescription)
  const initWidgetConfigData = [{
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "1",
    "ParameterName": "Column1",
    "Enable": "Enabled",
    "Description": "Sr. no.",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "2",
    "ParameterName": "Column2",
    "Enable": "Enabled",
    "Description": "Device Name",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "3",
    "ParameterName": "Column3 ",
    "Enable": "Enabled",
    "Description": "Tag Name",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "4",
    "ParameterName": "Column4",
    "Enable": "Enabled",
    "Description": "Tag Description",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "5",
    "ParameterName": "Column5 ",
    "Enable": "Enabled",
    "Description": "Block Name",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "6",
    "ParameterName": "Column6 ",
    "Enable": "Enabled",
    "Description": "Block ID",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "7",
    "ParameterName": "Column7 ",
    "Enable": "Enabled",
    "Description": "Process Value",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "8",
    "ParameterName": "Column8",
    "Enable": "Enabled",
    "Description": "Unit",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "9",
    "ParameterName": "Column9",
    "Enable": "Enabled",
    "Description": "LoLo SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
     "SrNo": "10",
    "ParameterName": "Column10",
    "Enable": "Enabled",
    "Description": "Low SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "11",
    "ParameterName": "Column11",
    "Enable": "Enabled",
    "Description": "High SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
     "SrNo": "12",
    "ParameterName": "Column12",
    "Enable": "Enabled",
    "Description": "HiHi SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "13",
    "ParameterName": "Column13 ",
    "Enable": "Enabled",
    "Description": "Alarm Status",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "14",
    "ParameterName": "Header Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "#A9BBD3",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "15",
    "ParameterName": "Header Font Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "16",
    "ParameterName": "Header Font Size",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "21",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "17",
    "ParameterName": "Header font Weight",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "bold",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "18",
    "ParameterName": "Header Text Align",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "19",
    "ParameterName": "Rows Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "white",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "20",
    "ParameterName": "Rows Font Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
     "SrNo": "21",
    "ParameterName": "Rows Font Size",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "14",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "22",
    "ParameterName": "Rows font Weight",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "normal",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "23",
    "ParameterName": "Rows Text Align",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "24",
    "ParameterName": "LoLo Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "#A2121D",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "25",
    "ParameterName": "Low Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "blue",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "26",
    "ParameterName": "High Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "purple",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "27",
    "ParameterName": "HiHi Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "red",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "28",
    "ParameterName": "No Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "29",
    "ParameterName": "Mouse Hover Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "skyblue",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na",
    "BorderStyle": "na",
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "30",
    "ParameterName": "Enable - Row Add",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "31",
    "ParameterName": "Enable - Row Edit",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "32",
    "ParameterName": "Enable - Row Delete",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "33",
    "ParameterName": "Table Title Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "white",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "34",
    "ParameterName": "Table Title Font Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "35",
    "ParameterName": "Table Title Font Size",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "20",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "36",
    "ParameterName": "Table Title font Weight",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "normal",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  }];

  const [fetchData, setFetchData] = useState(true);
  const[tableViewData, setTableViewData] = useState([]);
  const[tableViewFetchDone, setTableViewFetchDone] = useState(false);

  const[modbustaglistData, setModbustaglistData] = useState([]);
  const[modbustaglistFetchDone, setModbustaglistFetchDone] = useState(false);

  const[alarmconfigData, setAlarmconfigData] = useState([]);
  const[alarmconfigFetchDone, setAlarmconfigFetchDone] = useState(false);

  const[rowsData, setRowsData] = useState([]); // All rows data
  const[rowData, setRowData] = useState([]); // Selected row data
  const[rowsDataSetDone, setRowsDataSetDone] = useState(false);

  const[configData, setConfigData] = useState(initWidgetConfigData);
  const [addRecordForm, setAddRecordForm] = React.useState(false)
  const [editRecordForm, setEditRecordForm] = React.useState(false)
  const [editRowData, setEditRowData] = React.useState(false)

  const[deleteDialog, setDeleteDialog] = React.useState(false); // Delete Dialog bit
  const noButtonRef = React.useRef(null); 
  const [rowTagName, setRowTagName] = React.useState(); // Row Id refernace to delete the record
  const[deleteData, setDeleteData] = React.useState(false); //Delete row bit
  const [snackbar, setSnackbar] = React.useState(null); // if delete Success then set to success otherwise will set to fail
  const handleCloseSnackbar = () => setSnackbar(null);
  const [fetchingProgress, setFetchingProgress] = useState(0);
  const[addTagIconStatus, setAddTagIconStatus] = useState(true)

 const[remarkDialog, setRemarkDialog] = React.useState(false); // Remark Dialog bit
  const [eventDescription, setEventDescription] = useState("--");
  const [auditTrailRemark, setAuditTrailRemark] = useState("--");
  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);
  const [password, setPassword] = useState('');
  const[pwdinterlock, setPwdInterlock] = useState("Disabled");
    
    // Progress bar
    const ProgressBar = ({ progress }) => {
      return (
        <div className="progress">
          <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      );
    };

   //Get configuration of the selected widget
    useEffect(() =>{
      async function getColumnData()  {
      try {

        let dashboardName = "TableConfig";
        let columnName = "WidgetName";
        let columnValue = widgetName;
        
        const response = await TableViewService.getColumnData(dashboardName, columnName, columnValue );
        //console.log(response.data[16].FontWeight);
        if(response.data.length > 0){
          // Create a map from newObjects for quick lookup
          const newObjectsMap = new Map(response.data.map(obj => [obj.SrNo, obj]));

          // Merge existing data with new objects, replacing objects with matching SrNo
          const updatedData = configData.map(item => {
            const newObj = newObjectsMap.get(item.SrNo);
            return newObj ? newObj : item;
          });

          setConfigData(updatedData);
          //setConfigData(response.data);
          
          //console.log(response.data)
          //console.log(response.data[29].Enable)
          setAddTagIconStatus(response.data[29].Enable) 
          setFetchingProgress(100);
        }
        
        
      } catch (error) {
        console.log(error);

      }
    }
    getColumnData();



    }, [widgetName]);

    //Get Tableview data for selected widget
    useEffect(() =>{
      async function getColumnData()  {
      try {

        let dashboardName = "TableView";
        let columnName = "WidgetName";
        let columnValue = widgetName;
        
        const response = await TableViewService.getColumnData(dashboardName, columnName, columnValue );
        //console.log(response.data[16].FontWeight);
        if(response){
          //console.log(response.data);
          //console.log(response.data[0].TagName);
          setTableViewData(response.data);
          setTableViewFetchDone(true);
        }
        
        
      } catch (error) {
        console.log(error);

      }
    }
    if(fetchData){
      getColumnData();
      setFetchData(false);

    }
    
    }, [fetchData]);

    // Get data of specific columns from ModbusTagList table for specific tagnames
    useEffect(() =>{
      async function getModbusTagListData()  {
      try {
    
        // get list of values of TagName field from tableViewData
        const keyName = 'TagName'; // The key name you want to extract
        const valuesArray = tableViewData.map((item) => item[keyName]);

        let dashboardName = "ModbusTagList";
        let columnsToRetrieve = ["TagStatus", "TagName", "TagDescription1", "TagDescription2", "TagDescription3", "TagDescription4", "RawValue", "ProcessValue", "TagUnit", "TagType" , "OnLabel" , "OffLabel" , "StringTagLength", "TagQuality"];
        let columnToFilter ="TagName";
        let valueToMatch = valuesArray

        
        const response = await TableViewService.getMultiColumnsRows(dashboardName, columnsToRetrieve, columnToFilter, valueToMatch );
        //console.log(response.data);
        if(response){

          let  jsonArray = response.data;
          const updatedArray = jsonArray.map((obj) => {
            if (obj.TagType === 'Digital' && obj.RawValue === "1") {
              return { ...obj, ProcessValue: obj.OnLabel };
            } 
            if (obj.TagType === 'Digital' && obj.RawValue ==="0") {
              return { ...obj, ProcessValue: obj.OffLabel };
            }
            if (obj.TagType === 'Digital' && (obj.RawValue !== "1" || obj.RawValue !== "0" )) {
              return { ...obj, ProcessValue: obj.ProcessValue }
            }
            if (obj.TagType === 'Analog' || obj.TagType === 'String' ) {
              return { ...obj, ProcessValue: obj.ProcessValue };
            } 
          });
          
          //console.log(updatedArray);

          setModbustaglistData(updatedArray);
          setModbustaglistFetchDone(true);
        }
     
      } catch (error) {
        console.log(error);
    
      }
    }
    if(tableViewFetchDone){
      getModbusTagListData();
      setTableViewFetchDone(false);
    }
    
    
    }, [tableViewFetchDone]);

    // Get data of specific columns from AlarmConfig table for specific tagnames
    useEffect(() =>{
      async function getAlarmConfigData()  {
      try {
    
        // get list of values of TagName field from tableViewData
        const keyName = 'TagName'; // The key name you want to extract
        const valuesArray = tableViewData.map((item) => item[keyName]);

        let dashboardName = "AlarmConfig";
        let columnsToRetrieve = ["TagName", "LoLoSP", "LowSP", "HighSP", "HiHiSP", "PresentAlarmStatus"];
        let columnToFilter ="TagName";
        let valueToMatch = valuesArray;
        //console.log(tableViewData[0].TagName);
        
        const response = await TableViewService.getMultiColumnsRows(dashboardName, columnsToRetrieve, columnToFilter, valueToMatch );
        if(response){
          //console.log(response.data)
          setAlarmconfigData(response.data);
          setAlarmconfigFetchDone(true);
        }
        

        
      } catch (error) {
        console.log(error);
    
      }
    }
    if(modbustaglistFetchDone){
      getAlarmConfigData();
      setModbustaglistFetchDone(false);
    }
    
    
    }, [modbustaglistFetchDone]);


    // Merge two array data received from ModbusTagList and AlarmConfig tables and add SrNo to array.
    function mergeArrays(arr1, arr2) {
      // Create an array to store the merged data
      const mergedArray = [];
      // Merge data from the first array
      arr1.forEach((item) => {
        const existingItem = mergedArray.find((mergedItem) => mergedItem.TagName === item.TagName);
        if (existingItem) {
          Object.assign(existingItem, item);
        } else {
          mergedArray.push(item);
        }
      });
    
      // Merge data from the second array
      arr2.forEach((item) => {
        const existingItem = mergedArray.find((mergedItem) => mergedItem.TagName === item.TagName);
        if (existingItem) {
          Object.assign(existingItem, item);
        } else {
          mergedArray.push(item);
        }
      });
    
      return mergedArray;
    }    
    
    if(alarmconfigFetchDone){
      //console.log(modbustaglistData);
      //console.log(alarmconfigData);
      const mergedData = mergeArrays(modbustaglistData, alarmconfigData);

      let idCounter = 1;
      const mergedDataWithSrNo = mergedData.map((item) => {
        const newItem = { ...item, SrNo: idCounter };
        idCounter++; // Increment the counter for the next item
        return newItem;
      });

      //On communication error, Change PresentAlarmStatus = error
      const updatedData = mergedDataWithSrNo.map(item => {
        if (item.TagQuality === 'bad') {
          return { ...item, PresentAlarmStatus: 'error' };
        }
        return item;
      });
      setRowsData(updatedData);
      //console.log(updatedData);
      setAlarmconfigFetchDone(false);
      setRowsDataSetDone(true);
    }

    // update rowsdata perodically after every 1 second
    useEffect(() => {
      const intervalId = setInterval(() => {
        if(rowsDataSetDone === true && fetchData === false){
          setRowsDataSetDone(false); // make it false to reset the previous rowsdatasetDone
          setFetchData(true); // make it true to start fetching tableView-ModbusTaglist-alarmconfig data
          //console.log("fetchdata");
        }
        setFetchingProgress((prevProgress) => Math.min(prevProgress + 10, 100));

        

      }, 1500);
  
      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [rowsDataSetDone]);

    // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Remove Parameter From Table';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setRowData({...rowData, EventStatus: response.data[0].EnableEvent, PWDInterlock: response.data[0].EnablePwdInterlock, RemarkStatus: response.data[0].EnableRemark});
        setPwdInterlock(response.data[0].EnablePwdInterlock);
        setEventDescription(response.data[0].DefaultEventDescription);
        // If remark field is disabled then set default remark vlaue to currrent Remark value
        if(response.data[0].EnableRemark === "Disabled"){
          setAuditTrailRemark(response.data[0].DefaultEventRemark);
        }
        
      }

    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);

  //Get Application Configuration data 
  useEffect(() =>{
    async function getColumnData()  {
      try {

        let dashboardName = "ApplicationConfig";
        const response = await ConfigurationViewService.getAllRowsData(dashboardName);

        if(response.data.length > 0){
          //setPwdInterlock(response.data[10].TaskStatus);
          //console.log(response.data[10].TaskStatus); 

        }

        
        
      } catch (error) {
        console.log(error);

      }
    }
    getColumnData();



  }, []);
    
  // Delete  Specific Row row data 
  useEffect(() =>{
    async function deleteRowData()  {
    try {

      let dashboardName = "TableView"
      let auditTrailData =[{
        TimeStamp:new Date(),
        Activity: `Removed parameter '${rowData.TagName}' from table - '${widgetName}'`,
        ActivityType: "Remove Parameter From Table",
        UserName: user.email,
        Role: user.role,
        Remark: auditTrailRemark,
        EventStatus: rowData.EventStatus,
      }]
      //console.log(tableViewData);
      const searchValue = rowData.TagName; // The value you're searching in tableView collection to get record to retrive _id field from it.
   
      const foundObject = tableViewData.find((item) => item.TagName === searchValue);
      //console.log(tableViewData)
      //console.log(searchValue, foundObject)
      if (foundObject) {
        //console.log('Object found:', foundObject._id);
        let rowId = foundObject._id;
        //console.log(rowId, rowData._id)
        if (rowId === null){
          setSnackbar({ children: 'TagName value is null', severity: 'error' });
        }else{
          //let updatedRowData = {...rowData, _id: rowId, WidgetName: widgetName}
          let updatedRowData = {...rowData, _id: rowId, WidgetName: widgetName, email:user.email, role: user.role, PWDInterlock: pwdinterlock, PWD: password};
          //console.log(dashboardName, updatedRowData);
          const response = await TableViewService.deleteRow(dashboardName, updatedRowData, auditTrailData);  // update when id is present in data array
          if(response){
            setAuditTrailRemark("--"); // Clear remark field
            setSnackbar({ children: 'Tag Deleted successfully', severity: 'success' });
            setFetchData(true);
          
          }
        }

      } else {
        setSnackbar({ children: 'TagName is not present', severity: 'error' });
      }
    

    } catch (error) {
      setSnackbar({ children: "Tag deletion Failed", severity: 'error' });
      console.log(error);
      setAuditTrailRemark("--"); // Clear remark field

    }
  };
  if(deleteData){
    deleteRowData();
    setDeleteData(false);
  };


  }, [deleteData]);
    

  
  const handleClose = () => {
    setOpenRemarkBlnkMsg(false);
  };

  const handleRecordAdded = (data) => {
    
    setAddRecordForm(false);
    setFetchData(true);  // reload the data again from backend
    //console.log("Record Added", data);
    
  };

  const handleRecordEdited = (data) => {
    
    setEditRecordForm(false);
    setFetchData(true);  // reload the data again from backend
    
  };

  const handleDeleteYes = () => {
    setDeleteData(true);
    setDeleteDialog(false);
    setRemarkDialog(false);
  };

  const handleDeleteNo = () => {
    
    setDeleteDialog(false);
    setRemarkDialog(false);
    setAuditTrailRemark("--"); // Clear remark field
  };

  const renderDeleteConfirmDialog = () => {
    if (!deleteDialog) {
      return null;
    }

    //const { newRow, oldRow } = promiseArguments;
    //const mutation = computeMutation(newRow, oldRow);
    return (
      <div>

      <Dialog
        maxWidth="xs"
        //TransitionProps={{ onEntered: handleEntered }}
        open={!!deleteDialog}
      >
        <DialogTitle>Are you sure to delete selected Row?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will delete the selected row.`}
        </DialogContent>
        
        {pwdinterlock === 'Enabled' && (<DialogContent dividers>
          <DialogContentText>
            Enter the Password to delete the selected record from table.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          </DialogContent>
        )}

        <DialogActions>
          <Button ref={noButtonRef} onClick={handleDeleteNo}>
            No
          </Button>
          <Button onClick={handleDeleteYes}>Yes</Button>
        </DialogActions>
      </Dialog>

      </div>
    );
  };

  const handleDelete = () => ()=> {   
    if(((rowData.RemarkStatus === "Enabled") && (auditTrailRemark === "--")) || ((rowData.RemarkStatus === "Enabled") && (auditTrailRemark === ""))){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
      //console.log("test2");
      setDeleteDialog(true);
      setRemarkDialog(false);
      
    }
    
  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    if(rowData.RemarkStatus === "Enabled"){
      setAuditTrailRemark(value);

    }
  };

  const renderRemarkDialog = () => {
    if (!remarkDialog) {
      return null;
    }

    //console.log("test1");
    //const { newRow, oldRow } = promiseArguments;
    //const mutation = computeMutation(newRow, oldRow);
    return (
      <div>

      <Dialog
        maxWidth="xs"
        //TransitionProps={{ onEntered: handleEntered }}
        open={!!remarkDialog}
      >
        <DialogTitle>Enter Remark</DialogTitle>
        {/*Remark */}
        <DialogContent 
        dividers
        >
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 350 }}>
          Enter remark or reason for delete action
          </Typography>

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            name="Remark"
            placeholder="Enter Remark"
            multiline
            rows={2}
            style={{ width: 350 }}
            onChange={handleRemarkChange}
            //defaultValue="Default Value"
          />

        </DialogContent>


        <DialogActions>
          <Button ref={noButtonRef} onClick={handleDeleteNo}>
            Cancel
          </Button>
          <Button onClick={handleDelete()}>Delete</Button>
        </DialogActions>
      </Dialog>

      </div>
    );
  };


  const handleDeleteClick = (params) => () => {
    //setRowData({...params.row, email: user.email, role: user.role});
    //setRowTagName(tagName);
    //setDeleteDialog(true);
    //setRowData({...params.row, RemarkStatus: rowData.RemarkStatus, EventStatus:rowData.EventStatus, email:user.email, role: user.role, PWDInterlock: pwdinterlock, PWD: password});
    setRowData({...params.row, RemarkStatus: rowData.RemarkStatus, EventStatus:rowData.EventStatus});
    if(rowData.RemarkStatus === "Enabled"){
      //console.log("test0");
      setRemarkDialog(true);
    }else{
      //console.log("test0");
      const withoutRemark = handleDelete();
      withoutRemark();
    }
  };

  const handleEditClick = (params) => () => {
    //console.log(params);
    setEditRowData(params);
    setEditRecordForm(true);

  }

  const handleRowDoubleClick = (params, event) => {
    if(configData[30].Enable === "Enabled"){
      //console.log(params);
      setEditRowData(params);
      setEditRecordForm(true);
    }
    
  };

  const handleAddClick = (params) => () => {
    //console.log(params);
    setAddRecordForm(true);

  }

  const shouldHideColumn = (configData[30].Enable === "Disabled") && (configData[31].Enable === "Disabled");
  //console.log(shouldHideColumn)

  
  const columns = [
    { field: 'SrNo', headerName: configData[0].Description, width: configData[0].Width, headerAlign: configData[0].TextAlignment, align: configData[0].TextAlignment, headerClassName: 'theme--header', },
    { field: 'TagName', headerName: configData[1].Description, width: configData[1].Width, headerAlign: configData[1].TextAlignment, align: configData[1].TextAlignment, headerClassName: 'theme--header',},
    { field: 'TagDescription1', headerName: configData[2].Description, width: configData[2].Width, headerAlign: configData[2].TextAlignment, align: configData[2].TextAlignment, headerClassName: 'theme--header',},
    { field: 'TagDescription2', headerName: configData[3].Description, width: configData[3].Width, headerAlign: configData[3].TextAlignment, align: configData[3].TextAlignment, headerClassName: 'theme--header', },
    { field: 'TagDescription3', headerName: configData[4].Description, width: configData[4].Width, headerAlign: configData[4].TextAlignment, align: configData[4].TextAlignment, headerClassName: 'theme--header',},
    { field: 'TagDescription4', headerName: configData[5].Description, width: configData[5].Width, headerAlign: configData[5].TextAlignment, align: configData[5].TextAlignment, headerClassName: 'theme--header', },
    { field: 'ProcessValue', headerName: configData[6].Description, width: configData[6].Width, headerAlign: configData[6].TextAlignment, align: configData[6].TextAlignment, headerClassName: 'theme--header', },
    { field: 'TagUnit', headerName: configData[7].Description, width: configData[7].Width, headerAlign: configData[7].TextAlignment, align: configData[7].TextAlignment, headerClassName: 'theme--header', },
    { field: 'LoLoSP', headerName: configData[8].Description, width: configData[8].Width, headerAlign: configData[8].TextAlignment, align: configData[8].TextAlignment, headerClassName: 'theme--header', },
    { field: 'LowSP', headerName: configData[9].Description, width: configData[9].Width, headerAlign: configData[9].TextAlignment, align: configData[9].TextAlignment, headerClassName: 'theme--header', },
    { field: 'HighSP', headerName: configData[10].Description, width: configData[10].Width, headerAlign: configData[10].TextAlignment, align: configData[10].TextAlignment, headerClassName: 'theme--header', },
    { field: 'HiHiSP', headerName: configData[11].Description, width: configData[11].Width, headerAlign: configData[11].TextAlignment, align: configData[11].TextAlignment, headerClassName: 'theme--header', },
    { field: 'PresentAlarmStatus', headerName: configData[12].Description, width: configData[12].Width, headerAlign: configData[12].TextAlignment, align: configData[12].TextAlignment, headerClassName: 'theme--header', },
  
    //(user.role !== "visitor") && 
         
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerClassName: 'theme--header',
      width: 0,
      hideable: true,
      hide: shouldHideColumn,
      cellClassName: 'actions',
      getActions: (params) => {
        const tagName = params.row.TagName;
        const actions = [];

        if(configData[29].Enable === "Perm. Enabled"){
          actions.push(
            <GridActionsCellItem
            icon={<AddIcon />}
            label="Add"
            className="textPrimary"
            onClick={handleAddClick(params)}
            color="inherit"
          />
          );
        }

        if(configData[30].Enable === "Enabled"){
          actions.push(
            <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params)}
            color="inherit"
          />
          );
        }

        if(configData[31].Enable === "Enabled"){
          actions.push(
            <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params)}
            color="inherit"
          />
          );
        }

        return actions;

        
      },
      
    },
  ];

 // const [columnStates, setColumnStates]= useState([]);
 
  const columnStates = columns.map(() => true); // Initialize all columns as enabled
  if(configData[0].Enable === "Enabled"){
    columnStates[0] = true;
  }else{
    columnStates[0] = false;
  }
  if(configData[1].Enable === "Enabled"){
    columnStates[1] = true;
  }else{
    columnStates[1] = false;
  }
  if(configData[2].Enable === "Enabled"){
    columnStates[2] = true;
  }else{
    columnStates[2] = false;
  }
  if(configData[3].Enable === "Enabled"){
    columnStates[3] = true;
  }else{
    columnStates[3] = false;
  }
  if(configData[4].Enable === "Enabled"){
    columnStates[4] = true;
  }else{
    columnStates[4] = false;
  }
  if(configData[5].Enable === "Enabled"){
    columnStates[5] = true;
  }else{
    columnStates[5] = false;
  }
  if(configData[6].Enable === "Enabled"){
    columnStates[6] = true;
  }else{
    columnStates[6] = false;
  }
  if(configData[7].Enable === "Enabled"){
    columnStates[7] = true;
  }else{
    columnStates[7] = false;
  }
  if(configData[8].Enable === "Enabled"){
    columnStates[8] = true;
  }else{
    columnStates[8] = false;
  }
  if(configData[9].Enable === "Enabled"){
    columnStates[9] = true;
  }else{
    columnStates[9] = false;
  }
  if(configData[10].Enable === "Enabled"){
    columnStates[10] = true;
  }else{
    columnStates[10] = false;
  }
  if(configData[11].Enable === "Enabled"){
    columnStates[11] = true;
  }else{
    columnStates[11] = false;
  }
  if(configData[12].Enable === "Enabled"){
    columnStates[12] = true;
  }else{
    columnStates[12] = false;
  }
  if((configData[30].Enable === "Enabled") || (configData[31].Enable === "Enabled")){
    columnStates[13] = true;
  }else{
    columnStates[13] = false;
  }
 

  const totalWidth = calculateTotalWidth(rowsData, columns);
//console.log(configData[16].FontWeight)
  return (
    <div >

      {renderDeleteConfirmDialog()}
      {renderRemarkDialog()}
      <Paper
       style={{ maxWidth: 'fit-content', flex: 1, padding: '6px' }}
        sx={{
          //height: 400,
          width: '100%',
          margin: 'auto',
          position:"relative",
          overflow: 'hidden ! important',
          '&.MuiDataGrid-main':{overflowX:'hidden ! important', overflowY:'hidden ! important'},
          '& .theme--header':{backgroundColor: configData[13].Color, color: configData[14].Color, 
            fontSize: parseInt(configData[15].FontSize), fontWeight: configData[16].FontWeight, 
            border: configData[17].BorderStyle, whiteSpace: 'normal', wordWrap: 'break-word',},
          '& .theme--row': { 
            backgroundColor: configData[18].Color,
            color: configData[19].Color,
            fontSize:parseInt(configData[20].FontSize),
            fontWeight: configData[21].FontWeight,
            //border: configData[22].BorderStyle,
            //border: '1px solid #d4d4d4',
            //borderRight: 1,
            overflowWrap: 'break-word',
          },
          '& .theme--verborder': {
            border: configData[17].BorderStyle,
          },
          '& .theme--lolo': {
            color: configData[23].Color,
            //fontSize:15,
            //fontWeight: 'bold',
            //border: '1px solid #d4d4d4',
          },
          '& .theme--lo': {
            color: configData[24].Color,
            //fontSize:15,
            //fontWeight: 'bold',
            //border: '1px solid #d4d4d4',
          },
          '& .theme--hi': {
            color: configData[25].Color,
            //fontSize:15,
            //fontWeight: 'bold',
            //border: '1px solid #d4d4d4',
          },
          '& .theme--hihi': {
            color: configData[26].Color,
            //fontSize:15,
            //fontWeight: 'bold',
            //border: '1px solid #d4d4d4',
            

          },
          '& .theme--noalarm': {
            color: configData[27].Color,
            //fontSize:15,
            //fontWeight: 'bold',
            //border: '1px solid #d4d4d4',
            

          },
        }}
        elevation={24}
      >
         {/* <Box display="flex" alignItems="center" justifyContent="center" border={0} p={0} sx={{'&.MuiDataGrid-main':{overflowX:'hidden ! important', overflowY:'hidden ! important'}}}> */}
         <Box >
        {fetchingProgress < 100 ? (
          <div style={{ height: 50, width: '100%', overflowX: 'hidden', overflowY: 'hidden' }}>
          <p>Data fetching in progress...</p>
          <ProgressBar progress={fetchingProgress} />
        </div>
        ):(
          <div style={{ height: '100%', width: '100%', overflowX: 'hidden', overflowY: 'hidden' }} >
            <DataGrid
            rows={rowsData}
            getRowId={(rowsData) => rowsData._id} // This line will set id of the datagrid row = _id of the mongodb database, otherwisw error can occured.
            //columns={columns}
            columns={columns.filter((column, index) => columnStates[index])} // enable/disable individual column as per setting
            disableRowSelectionOnClick  // disable row selection
            onRowDoubleClick={handleRowDoubleClick} // Double click event handler
            //hideFooterPagination = {true}
            hideFooter
            // autoHeight
            //disableSelectionOnClick
            //disableColumnMenu
            //disableColumnSelector
            getCellClassName={(params) => {
                if (params.field === 'TagName' || params.value == null) {
                  return '';
                }

                if (params.field === 'ProcessValue' && parseFloat(params.value) <= parseFloat(params.row.LoLoSP)){
                  return 'theme--lolo';
                }

                if(params.row.LoLoSP === "NA"){
                  if(params.field === 'ProcessValue' && parseFloat(params.value) <= parseFloat(params.row.LowSP)){
                    return 'theme--lo';
                  }

                }
                if(params.row.LoLoSP !== "NA"){
                  if (params.field === 'ProcessValue' && parseFloat(params.value) <= parseFloat(params.row.LowSP) && parseFloat(params.value) > parseFloat(params.row.LoLoSP) ){
                    return 'theme--lo';
                  }

                }
                
                if (params.row.HiHiSP === "NA"){
                  if(params.field === 'ProcessValue' && parseFloat(params.value) >= parseFloat(params.row.HighSP)){
                    return 'theme--hi';
                  }
                  
                }
                if (params.row.HiHiSP !== "NA"){
                  if(params.field === 'ProcessValue' && parseFloat(params.value) >= parseFloat(params.row.HighSP) && parseFloat(params.value) < parseFloat(params.row.HiHiSP)){
                    return 'theme--hi';
                  }

                }
                
                if (params.field === 'ProcessValue' && parseFloat(params.value) >= parseFloat(params.row.HiHiSP)){
                  return 'theme--hihi';
                }
                
                if (params.field === 'ProcessValue' && parseFloat(params.value) > parseFloat(params.row.LowSP) && parseFloat(params.value) < parseFloat(params.row.HighSP) ){
                  return 'theme--noalarm';
                }
                if (params.field !== 'verborder' ){
                  return 'theme--verborder';
                }
                
      
              }}

            getHeaderClassName={(params) =>{return 'theme--header';}}
            getRowClassName={(params) => {return 'theme--row';}}
            //{...data}
            sx={{
              //width:'1678px',
              "& .MuiDataGrid-row:hover": {
                backgroundColor: configData[28].Color
                // on hover, background color of thr row
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
                // wrap the column header text
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: 'normal !important', /* Important to override other styles */
                lineHeight: "normal",
                wordWrap: 'break-word',
                // wrap the  cell text
              },
            }}

            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { addTagIconStatus, setAddRecordForm, widgetDescription, configData},
            }}
          />
          </div>
          
        )}
          
        </Box>

        {addRecordForm && (
        <DashboardAdd open= {addRecordForm} close = {handleRecordAdded} />
        )}

        {editRecordForm && (
        <DashboardEdit params = {editRowData} open= {editRecordForm} close = {handleRecordEdited} />
        )}

        {!!snackbar && (
          <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }}  open onClose={handleCloseSnackbar} autoHideDuration={4000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}

        {/* Open Remark Field is blank dialog box */}
        <Dialog open={openRemarkBlnkMsg} onClose={handleClose}>
          <DialogTitle>Enter Remark Field</DialogTitle>
          <Box sx={{width: 450 }}>
          <DialogContent dividers>
            <DialogContentText>
                Enter or change the Remark Field to proceed further.
                <br />
                (Note: '--' Value for remark field is not acceptable)
            </DialogContentText>
            
          </DialogContent>
          </Box>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

      </Paper>
    </div>
  );
}