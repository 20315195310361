import * as React from 'react';
import { useState, useEffect, useRef} from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import DashboardViewService from '../services/dashboardview.service';
import TableViewService from '../services/tableview.service';
import TrendViewService from '../services/trendview.service';
import ReportViewService from '../services/reportview.service';
import AuditTrailViewService from '../services/audittrailview.service';
import AlarmViewService from '../services/alarmview.service';
import UserConfigService from '../services/usermanagement.service.js';
import ConfigurationViewService from '../services/configurationview.service';
import FTPService from '../services/ftp.service';
import { useDispatch, useSelector } from "react-redux";
import { setWidgetName, setWidgetDescription} from "../slices/splitbutton1.js";


const options = ['Dashboard1', 'Dashboard2', 'Dashboard3'];

export default function SplitButton1({projectName, dashboardType, selectedDashboard} ) {
  
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dashboardList, setDashboardList] = useState([]);
  const [dashboard, setDashboard] = useState();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const groupNameList = user.groupnamelist; // get list of assigned groups to logged user

  useEffect(() =>{
    async function Test()  {
    try {
      if(dashboardType === "DashboardView"){
        let dashboardName = "DashboardGroupList";
        const response1 = await DashboardViewService.getAllRowsData(dashboardName);
        if(response1){
          const dashboardGroupNameList = [...new Set(
            response1.data
              .filter(obj => obj.GroupName)
              .map(obj => obj.GroupName)
          )]; 

          
          let dashboardName = "DashboardList";
          const response2 = await DashboardViewService.getAllRowsData(dashboardName);
          if(response2){
            const filteredObjects1 = response2.data.filter(obj =>
              obj.dashboardgroupnamelist.some(value => dashboardGroupNameList.includes(value))
            );

            const filteredObjects2 = response2.data.filter(obj =>
              obj.groupnamelist.some(value => groupNameList.includes(value))
            );
            //console.log(filteredObjects1)
            //let widgetlist = filteredObjects1.map(item => item.DashboardName);
            let widgetlist = dashboardGroupNameList;
            let sortedWidgetList = widgetlist.slice().sort();
            setDashboardList(sortedWidgetList);
            //selectedDashboard(sortedWidgetList[selectedIndex]);
            //dispatch(setWidgetName(sortedWidgetList[selectedIndex]));
            //return response2.data;

          }
        }

      }

      if(dashboardType === "TrendView"){
        let dashboardName = "TrendList";

        const response = await TrendViewService.getAllRowsData(dashboardName);

        const filteredObjects = response.data.filter(obj =>
          obj.groupnamelist.some(value => groupNameList.includes(value))
        );

        let widgetlist = filteredObjects.map(item => item.WidgetName);
        let sortedWidgetList = widgetlist.slice().sort();
        setDashboardList(sortedWidgetList);
        selectedDashboard(sortedWidgetList[selectedIndex]);
        dispatch(setWidgetName(sortedWidgetList[selectedIndex]));
        return response.data;

      }

      if(dashboardType === "TableView"){
        let dashboardName = "TableList";

        const response = await TableViewService.getAllRowsData(dashboardName);

        const filteredObjects = response.data.filter(obj =>
          obj.groupnamelist.some(value => groupNameList.includes(value))
        );
        
        let widgetlist = filteredObjects.map(item => item.WidgetName);
        let sortedWidgetList = widgetlist.slice().sort();

        let descriptionList = filteredObjects.map(item => item.WidgetDescription);
        // Filter and sort the descriptions array based on the sortedWidgetNames
        const sortedDescriptions = sortedWidgetList.map(widget => {
          const index = widgetlist.indexOf(widget);
          return descriptionList[index];
        });

        setDashboardList(sortedWidgetList);
        selectedDashboard(sortedWidgetList[selectedIndex]);
        dispatch(setWidgetName(sortedWidgetList[selectedIndex]));
        //console.log(sortedDescriptions[selectedIndex]);
        dispatch(setWidgetDescription(sortedDescriptions[selectedIndex]));
        return response.data;

      }

       if(dashboardType === "AlarmView"){
        let dashboardName = "AlarmGroupList";

        const response = await AlarmViewService.getAllRowsData(dashboardName);

        const filteredObjects = response.data.filter(obj =>
          obj.groupnamelist.some(value => groupNameList.includes(value))
        );

        let widgetlist = filteredObjects.map(item => item.GroupName); // GroupName assigned as widgetlsit
        let sortedWidgetList = widgetlist.slice().sort();
        setDashboardList(sortedWidgetList);
        selectedDashboard(sortedWidgetList[selectedIndex]);
        dispatch(setWidgetName(sortedWidgetList[selectedIndex]));
        return response.data;

      }

      if(dashboardType === "ReportView"){
        let dashboardName = "ReportList";

        const response = await ReportViewService.getAllRowsData(dashboardName);
        
        // Filter objects where groupnamelist of each dashbaord contains at least one value from groupNameList array
        // Filter the received data from back end, each record groupname value is exist in user.groupnamelist or not
        //Accordingly assign the widget naems to the splitButton 1 and rest widget name wil get filtered or eliminated from list
        const filteredObjects = response.data.filter(obj =>
          obj.groupnamelist.some(value => groupNameList.includes(value))
        );

        let widgetlist = filteredObjects.map(item => item.WidgetName);
        let sortedWidgetList = widgetlist.slice().sort();
        setDashboardList(sortedWidgetList);
        selectedDashboard(sortedWidgetList[selectedIndex]);
        dispatch(setWidgetName(sortedWidgetList[selectedIndex]));
        return response.data;

      }

      if(dashboardType === "AuditTrailView"){
        let dashboardName = "AuditTrailList";

        const response = await AuditTrailViewService.getAllRowsData(dashboardName);
        let widgetlist = response.data.map(item => item.WidgetName);
        let sortedWidgetList = widgetlist.slice().sort();
        setDashboardList(sortedWidgetList);
        selectedDashboard(sortedWidgetList[selectedIndex]);
        dispatch(setWidgetName(sortedWidgetList[selectedIndex]));
        //console.log(response.data);
        return response.data;

      }

       if(dashboardType === "UserManagement"){
        let dashboardName = "UserGroupList";

        const response = await UserConfigService.getAllRowsData(dashboardName);
        let widgetlist = response.data.map(item => item.GroupName); // GroupName assigned as widgetlsit
        let sortedWidgetList = widgetlist.slice().sort();
        setDashboardList(sortedWidgetList);
        selectedDashboard(sortedWidgetList[selectedIndex]);
        dispatch(setWidgetName(sortedWidgetList[selectedIndex]));
        return response.data;

      }

      if(dashboardType === "FTPSettings"){
        let dashboardName = "FTPFileList";

        const response = await FTPService.getAllRowsData(dashboardName);
        //console.log(response.data)
            
        let widgetlist = response.data.map(item => item.FileName);
        let sortedWidgetList = widgetlist.slice().sort();

        let descriptionList = response.data.map(item => item.FileDescription);
        // Filter and sort the descriptions array based on the sortedWidgetNames
        const sortedDescriptions = sortedWidgetList.map(widget => {
          const index = widgetlist.indexOf(widget);
          return descriptionList[index];
        });
        //console.log(sortedWidgetList)
        setDashboardList(sortedWidgetList);
        selectedDashboard(sortedWidgetList[selectedIndex]);
        dispatch(setWidgetName(sortedWidgetList[selectedIndex]));
        //console.log(sortedDescriptions[selectedIndex]);
        dispatch(setWidgetDescription(sortedDescriptions[selectedIndex]));
        return response.data;

      }

      
      
    } catch (error) {
      console.log(error);

    }
  }
  Test();

}, [open]);

  const handleClick = () => {
    //console.log("button Clicked")
    //console.info(`You clicked ${dashboardList[selectedIndex]}`);
    setDashboard(dashboardList[selectedIndex]);
    //console.log(dashboardList);
    
    
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    selectedDashboard(dashboardList[index]);
    dispatch(setWidgetName(dashboardList[index]));
    //console.log(dashboardList[index]);
    handleClick();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="text" size="small" ref={anchorRef} >
        
        <Button
          size="small"
          color="success"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          style={{textTransform: 'none'}} // To avoid conversion of the text to uppercase automatically.
        >
          {dashboardList[selectedIndex]}
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 10, // this should be higher than other componenet otherwise options can get disabled and user will not be able to select widget name
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {dashboardList.map((option, index) => (
                    <MenuItem
                      key={option}
                      //disabled={index === 3}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      style={{textTransform: 'none'}} // To avoid conversion of tyeh text to uppercase automatically.
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
