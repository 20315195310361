import * as React from 'react';
import {useState, useEffect, useCallback, useRef} from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockResetIcon from '@mui/icons-material/LockReset';
//import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, useFormik , ErrorMessage } from "formik";
import * as yup from 'yup';
import { Dialog, Paper, IconButton, Typography, Hidden } from '@mui/material';

import { login } from "../slices/auth";
import { clearMessage } from "../slices/message";

//import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';

import { changepassword,logout } from "../slices/auth";
import EventBus from "../common/EventBus";
//import {AuditTrailLog} from './AuditTrailLog.js';
import AuditTrailViewService from '../services/audittrailview.service';

import Tooltip from '@mui/material/Tooltip';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://radix.co.in" target="_blank">
        Radix Electrosystems
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


const validationSchema = yup.object({
  
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

function ChangePasswordDialog({pwdChangeSuccess}) {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [emailId, setemailId] = useState('');
  const [password, setPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [reconfirmPassword, setReconfirmPassword] = useState('');

  const [wrongPassword, setwrongPassword] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  //console.log(message);
  const[auditTrailConfigData, setAuditTrailConfigData] = useState({Remark: '--', RemarkStatus: 'Enabled' });
  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {dispatch(clearMessage());}, [dispatch]);

  const [pwdChangeCancl, setPwdChangeCancl] = useState(false);


  // log message to Audittrailog "Password change Cancelled by user"
  useEffect(() => {
    async function auditTrailLog (){
      try{
        let dashboardName = "AuditTrailLog";
        let TimeStamp = new Date();
        const rowData = {
        TimeStamp: TimeStamp,
        Activity: "Password change Cancelled by user",
        UserName: user.email,
        Role:user.role,
        Remark: "--",
        }

        const response = await AuditTrailViewService.createRow(dashboardName, rowData);  // create new record if id is not present in array data ie existing data
        if(response){
          setemailId('');
          setPassword('');
          setNewPassword('');
          setReconfirmPassword('');
          setOpen(false);
          setPwdChangeCancl(false);
          //console.log(response.data);
        }

      } catch(error){
        console.log (error);
      }
    }

    if(pwdChangeCancl === true){
      auditTrailLog();
    }
    

  },[pwdChangeCancl]);

  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Change Password';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setAuditTrailConfigData({...auditTrailConfigData, Remark: '--', RemarkStatus: response.data[0].EnableRemark});
      }
  
    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);

  const handleClickOpen = () => {
      setOpen(true);
  };

  const handlePwdChangeClose = (event, reason) => {
    // reason == 'backdropClick means clicked outside the Dialog component and not to close the window.
    if (reason !== 'backdropClick') {
  
      setPwdChangeCancl(true);

  }
    
  };

  const handleFailedDialogClose = () =>{

    setPasswordNotMatch(false);
    


  };

  const handleSuccessDialogClose = () =>{

    setPasswordChangeSuccess(false);
    //Navigate to layout page again after password chnage success.
    navigate("/layout");
    window.location.reload();
    

  };

  const handleSubmit = (event) =>{
    event.preventDefault();
    // Check remark field is blank or not if not proceed else display dialog
    if((auditTrailConfigData.RemarkStatus === "Enabled") && (auditTrailConfigData.Remark === "--") || (auditTrailConfigData.RemarkStatus === "Enabled") && (auditTrailConfigData.Remark === "")){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
      setPasswordChangeSuccess(false);
      setwrongPassword(false);

      if(newpassword && newpassword.toString().length >= 8 && newpassword.toString().length <= 40 && newpassword === reconfirmPassword){
        let email = emailId;
        let remark = auditTrailConfigData.Remark;
        console.log(remark)
        dispatch(changepassword({email, password, newpassword, remark}))
        .unwrap()
        .then(() => {

          setPasswordChangeSuccess(true);
          pwdChangeSuccess();
          //handleSuccessDialogClose();
          console.log("successfuly saved changes");
          
        })
        .catch((err) => {
          console.log(err);
          setPasswordChangeSuccess(false);
          //console.log("Failed to save changes");
          setwrongPassword(true);
          
          
        });

      }else{
        setPasswordNotMatch(true);
      }

      }

  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    setAuditTrailConfigData({...auditTrailConfigData, [name]:value});
    
    
  };

  const handleRemarkBlnkClose = () => {
    setOpenRemarkBlnkMsg(false);
  };

 

  return (
    <div>
      <Button variant="outlined" startIcon ={<LockResetIcon/>} sx={{mt:1}} onClick={handleClickOpen}>Change password </Button>

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleRemarkBlnkClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleRemarkBlnkClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* New Password entry dialog */}
      <Dialog open={open} onClose={handlePwdChangeClose} >
        <DialogTitle> Password Change</DialogTitle>
        <Box sx={{width: 400 }}>

          <DialogContent dividers>
            <DialogContentText>
              Enter the email Address and current & new password to change it.
            </DialogContentText>

            {/* email */}
            <TextField
              autoFocus
              margin="dense"
              label="Email Address"
              type="text"
              fullWidth
              variant="standard"
              name="email"
              //value={tableListData.email}
              onChange={(event) => {
                setemailId(event.target.value);
                }}
              //onChange={handleTextFieldChange}
              disabled={false}
            />

            <TextField
              margin="dense"
              id="password"
              label="Current Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />

            <TextField
              margin="dense"
              id="newpassword"
              label="New Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
            />

            <TextField
              margin="dense"
              id="confirmNewPassword"
              label="Confirm new Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setReconfirmPassword(event.target.value);
              }}
            />

          </DialogContent>

          {/*Remark */}
          {auditTrailConfigData.RemarkStatus === 'Enabled' && (<DialogContent 
          dividers
          >
            <Typography gutterBottom sx={{ mt: 0, mb:3, width: 350 }}>
            Enter remark/reason for Password change  
            </Typography>

            <TextField
              id="outlined-multiline-static"
              label="Remark"
              name="Remark"
              placeholder="Enter Remark"
              multiline
              rows={2}
              style={{ width: 350 }}
              onChange={handleRemarkChange}
              //defaultValue="Default Value"
            />

            </DialogContent>
          )}


        </Box>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={handlePwdChangeClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Wrong Old Password entered */}
      <Dialog
        open={wrongPassword}
        onClose={handleFailedDialogClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* {message} */}
            Password change failed... <br/>
            {/*{message.toString()} */}
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>{setwrongPassword(false);}}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* New Paaword and reconfirm Password is not matching */}
      <Dialog
        open={passwordNotMatch}
        onClose={handleFailedDialogClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-inputs" component={'span'}> {/* component={'span'} required herewith to avoid error of the nesting of <p> */}
            <Typography gutterBottom>
              One of the below condition is not fulfilled...
            </Typography>
            <Typography gutterBottom>
              1. New password and Reconfirmed password is not matching.
            </Typography>
            <Typography gutterBottom>
              2. Password length should be min 8 characters.
            </Typography>           
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFailedDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>


      {/* Password Changed Successfully */}
      <Dialog
        open={passwordChangeSuccess}
        onClose={handleSuccessDialogClose}
       
      >
        <DialogTitle > Success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Password change successful
            {/* {message} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default function SignIn() {

  const [openForgotPwd, setOpenForgotPwd] = useState(false);
  const [userDisabled, setUserDisabled] = useState(false);
  
  const [pwdWillExpire, setPwdWillExpire] = useState(false);
  const[willExpireAfterDays, setWillExpireAfterDays] = useState(0);

  const [openChangePwd, setOpenChangePwd] = useState(false);
  const [pwdChangeSuccess, setPwdChangeSuccess] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const[loginError, setLoginError] = useState('test');
  const [submiterror, setSubmiterror] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  useEffect(() => {dispatch(clearMessage());}, [dispatch]);
  

  const logOut = useCallback(() => {
    
    dispatch(logout());
    //console.log("Pwd change dialog close icon Pressed")
    
  }, [dispatch]);

  useEffect(() => {
    //console.log("test")
    //console.log(EventBus);

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [logOut]);

  const handleClickPasswordChange = () => {setOpenChangePwd(true);};

  const handleChangePwdClose = (event, reason) => { 
    //console.log(reason)
    if (reason !== 'backdropClick') {
      setOpenChangePwd(false);
      // log out when password change failed.
      logOut();
      //console.log("Logedout");
      //Navigate to login page again after password chnage success.
      //navigate("/login");
      //window.location.reload();
    }
  
  };

  const handleForgotPwdClickOpen  = () => {
    setOpenForgotPwd(true);
  
  };

  const handlePwdForgotClose = (event, reason) => { 
    //console.log(reason)
    if (reason !== 'backdropClick') {
      setOpenForgotPwd(false);
    }
  
  };

  const handleUserDisabledClose = (event, reason) => {
    //console.log(reason);
    if (reason !== 'backdropClick') {
      setUserDisabled(false);
     
    }
  }

  // After pressing close button on Password will expire after xx day dialog, close the dialog and navif=gate to "main window of application"
  const handlePwdExpiryDateClose = (event, reason) => {
    //console.log(reason);
    if (reason !== 'backdropClick') {
      setPwdWillExpire(false);

      setSubmiterror(false);
      setLoginSuccess(true)
      navigate("/layout");
      window.location.reload();
     
    }
  }

  const [rememberMe, setRememberMe] = useState(false);
  const handleCheckbox = () => {
    if(rememberMe){ setRememberMe(false) }
    else {setRememberMe(true)}
  };


  

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2), );
      
      const email = values.email
      const password = values.password
      //console.log(password);
      setLoading(true);
      dispatch(login({ email, password, rememberMe }))
      .unwrap()
      .then((res) => {
        let userInfo = res.user;
        //console.log(userInfo);
        if(userInfo.userstatus === "Disabled"){
          setUserDisabled(true); // Open the user disabled dialogue to guide user for further action.
        }
        
        if(userInfo.userstatus === "Enabled"){
          // Check password is expired or not?
          if((userInfo.passwordchange === "Enabled" || userInfo.expiryDateFlag === true)){
            //console.log(userInfo)
            setOpenChangePwd(true);
          }else{
            //console.log(userInfo);
            // If paaword is not expired and willexpire bit is true then show meesage that password will expire afterdays
            if(userInfo.willExpiryDateFlag === true){
              //console.log("Show password will expire message")
              setWillExpireAfterDays(userInfo.willExpireAfterDays);
              setPwdWillExpire(true);
            }else{
              setSubmiterror(false);
              setLoginSuccess(true)
              navigate("/layout");
              window.location.reload();
            }
            
          }
          

        }
        
      })
      .catch((err) => {
        console.log(err);
        if(message === "User Disabled!"){
          setUserDisabled(true); // Open the user disabled dialogue to guide user for further action.
        }
        //console.log(message);

        setLoginError(err.message);

        setLoading(false);
        setSubmiterror(true);
        
      });
      
    },
  });

  const handlePwdChangeSuccess = ()=> {
    setPwdChangeSuccess(true);
  };

  if (isLoggedIn === true && pwdChangeSuccess === true) {
    setPwdChangeSuccess(false);
    console.log(isLoggedIn, pwdChangeSuccess )
    return <Navigate to="/layout" />;
    
  }

  const handleTooltipToggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <ThemeProvider theme={defaultTheme}>

    <Container component="main" maxWidth="xs">
      <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            elevation={12}
        >
        {submiterror ? 
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          
          Email or password is wrong — <strong>check it out!</strong>
        </Alert> 
        : <></>
        }

        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        
        <Box sx={{mt: 1, position: 'relative', zIndex: 1 }} >
        <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="normal"
          autoFocus
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          //autoComplete="off"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          margin="normal"
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          autoComplete="off"
          //autoComplete="new-password" // Disable password saving prompt
          //autoComplete="current-password"

          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />


        {/*
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
          checked = {rememberMe}
          onChange = {handleCheckbox}
          disabled = {true}
        />
        */}

        <Button sx={{ mt: 3, mb: 2 }} color="primary" variant="contained" fullWidth type="submit">
          Sign In
        </Button>

        </form>
        </Box>
        <Grid container spacing={16}>
          
          <Grid item xs={6}>
          <div style={{ backgroundColor: 'white', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Link component="button" onClick={handleForgotPwdClickOpen} variant="body2">
              {"Forgot password?"}
            </Link>
          </div>

          </Grid>

          {/*
          <Grid item>
            <Link href="register" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
          */}

           {/* <Grid> <Typography> {loginError} </Typography> </Grid> */}
        </Grid>
        
        
        </Paper>
        </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>

    
    

    {/* forgot Password dialog*/}
    <Dialog
      open={openForgotPwd}
      onClose={handlePwdForgotClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <IconButton
          aria-label="close"
          onClick={handlePwdForgotClose}
          sx={{ position: 'absolute', top: 5, right: 5 }}
        >
          <CloseIcon />
        </IconButton>

      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Contact System Admin.
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Please contact your system administrator to receive temporary password.
          Do not forgot to change temporary password with your own.
        </DialogContentText>
          
      </DialogContent>

    </Dialog>

    {/* User Disabled dialog*/}
    <Dialog
      open={userDisabled}
      onClose={handleUserDisabledClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <IconButton
          aria-label="close"
          onClick={handleUserDisabledClose}
          sx={{ position: 'absolute', top: 5, right: 5 }}
        >
          <CloseIcon />
        </IconButton>

      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Contact System Admin.
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          User is disabled by admin <br /> 
          or <br />
          User was disabled due to max wrong password entered.<br />
          Please contact your system administrator for further support.<br /> 
        </DialogContentText>
      </DialogContent>

    </Dialog>

    {/* Password will expire after.. days dialog*/}
    <Dialog
        open={pwdWillExpire}
        onClose={handlePwdExpiryDateClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <IconButton
            aria-label="close"
            onClick={handlePwdExpiryDateClose}
            sx={{ position: 'absolute', top: 5, right: 5 }}
          >
            <CloseIcon />
          </IconButton>

        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Password Expiry.
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Password will expire after {willExpireAfterDays} day. <br />
            Please change the password for security reason. 
          </DialogContentText>

          <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ChangePasswordDialog pwdChangeSuccess = {handlePwdChangeSuccess} />
          </DialogContent>

          {/*
            <Grid item xs={6}>
              <div style={{ backgroundColor: 'white', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Link disabled ={false} component="button" onClick={handleClickPasswordChange} variant="body2">
                  {"Proceed to Change Password.."}
                </Link>
              </div>
            </Grid>
        */}
        </DialogContent>

      </Dialog>

    {/* openChangePassword dialog*/}
    <Dialog
      open={openChangePwd}
      onClose={handleChangePwdClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <IconButton
          aria-label="close"
          onClick={handleChangePwdClose}
          sx={{ position: 'absolute', top: 5, right: 5 }}
        >
          <CloseIcon />
        </IconButton>

      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Change Password
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          you are loging first time in system <br /> 
          or <br />
          User credentials get changed by Admin <br /> 
          or <br />
          passward get expired <br />
          
          Hence change the password for security reason.<br />
        </DialogContentText>
      </DialogContent>

      <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ChangePasswordDialog pwdChangeSuccess = {handlePwdChangeSuccess} />
      </DialogContent>

    </Dialog>

    </ThemeProvider>
   

    
    
  );
};