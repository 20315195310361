import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import {splitbutton1Reducer, splitButton1DescriptionReducer} from "./slices/splitbutton1";
import chartTimeReducer from "./slices/charttime";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  splitbutton1:splitbutton1Reducer,
  splitButton1Description:splitButton1DescriptionReducer,
  charttime: chartTimeReducer
}

export const store = configureStore({
  reducer: reducer,
  devTools: true,
});
