import React, { useEffect } from 'react';

const IdleTimeout = ({ timeout, onLogout }) => {
  let timeoutId;

  const resetTimeout = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      onLogout();
    }, timeout);
  };

  const handleUserActivity = () => {
    resetTimeout();
  };

  useEffect(() => {
    resetTimeout();

    // Listen for mouse move and key down events to reset the timeout
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  return null;
};

export default IdleTimeout;
