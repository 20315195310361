import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import {Toolbar, Container, Button } from '@mui/material'
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { mainListItems, secondaryListItems } from './drawerListItems';

import ProjectDescription from './ProjectDescription'
import ProjectList from './ProjectList'
import ProfileMenu from './ProfileMenu'

import Copyright from './Copyright'
import Welcome from './Welcome'
import DashboardView from './DashboardView'
import TrendView from './TrendView'
import TableView from './TableView'
import AlarmView from './AlarmView'
import ReportView from './ReportView'
import AuditTrailView from './AuditTrailView'
import UserManagement from './UserManagement'
import ConfigurationView from './ConfigurationView'
import FTPSettings from './FTPSettings'

import StickyFooter from './Footer'
import Draggable from "react-draggable";
import {Resizable} from "re-resizable";
import { useDispatch, useSelector } from "react-redux";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import TableViewIcon from '@mui/icons-material/TableView';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Tooltip from '@mui/material/Tooltip';
import IdleTimeout from './IdleTimeout'; // Import the IdleTimeout component

import AuditTrail from "../assets/AuditTrail.png";
import FTPIcon from "../assets/ftp.png";
import ConfigurationViewService from '../services/configurationview.service.js';
import AlarmViewService from '../services/alarmview.service';
import { logout } from "../slices/auth";
import EventBus from "../common/EventBus";

import './NotificationIcon.css'; // Import CSS file with animation class
//import { downloadProfilePhoto, uploadProfilePhoto, changeuserinfo, changepassword } from "../slices/auth";
import AuthService from "../services/auth.service";




const drawerWidth = 240;
//const minimizedWidth = 60;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, hidden }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, hidden}) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Layout() {
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = useState(false); // Example width
  const handleToggleMinimize = () => {
    
      if(open === false){
        setHidden(false)
        setOpen(true)
      }else{
        setOpen(false)
      }
    
    //setOpen(!open);
    
  };


  const toggleDrawer = () => {
    //console.log(open)
    if(open ===  true){
      setOpen(false)
      
    }else{
      if(hidden === false){
        setHidden(true)
      }else{
        setHidden(false)
      }
    }
  };


  // get projname from ProjectList component ans set it to the setSelecetdProject
 //Hence we can pass it to the ProjectDescription component
  const { user } = useSelector((state) => state.auth);
  const [selecetdProject, setSelecetdProject] = useState(user.selectedproject);
  const projName = (newData) => {setSelecetdProject(newData);};
  //console.log(user.selectedproject, selecetdProject);

  // Define your list of components
  const componentList = [
    <Welcome key="A" />,
    <DashboardView key="B" />,
    <TableView key="C" />,
    <TrendView key="D" />,
    <AlarmView key="E" />,
    <ReportView key="F" />,
    <AuditTrailView key="G" />,
    <UserManagement key="H" />,
    <ConfigurationView key="I" />,
    <FTPSettings key="J" />,
  ];

  // State to store the selected component
  const [selectedComponent, setSelectedComponent] = useState(componentList[0]);
  // Function to handle component selection
  const handleComponentSelection = (component) => {
  setSelectedComponent(component);
  };
  

  function ComponentA() {
    return <p>Component A Content</p>;
  }

  const [liveAlarmCount, setLiveAlarmCount] = React.useState(0)
  // Get the Live Alarm count
  useEffect(() => {
    const intervalId = setInterval(() => {
    async function getLiveAlarmCount()  {
      try {
          let dashboardName = "AlarmViewData";
          let columnToFilter ="AlarmDuration";
          let valueToMatch = 0

          const response = await AlarmViewService.getDocCount(dashboardName, columnToFilter, valueToMatch );
          //console.log(response.data, typeof(response.data))
          if (response){
            
            let LiveAlarmCount = response.data;
            if (typeof LiveAlarmCount === 'number'){
              setLiveAlarmCount(response.data);
            }else{
              setLiveAlarmCount("Er");
            }
            
          }
        }catch(error){
          setLiveAlarmCount("Err");
          //console.log(error);

      }
    };
    getLiveAlarmCount();

  }, 1000);
  // Clear the interval when the component unmounts
  return () => clearInterval(intervalId);
    

  }, []);

///////////////////////////
  //Handle Autologout
  const timeOutSP = parseInt(user.UserAccess[13].Value) * 60000
  //console.log(timeOutSP)
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    
    dispatch(logout());
    //console.log("Logout button was pressed")
    
  }, [dispatch]);
  useEffect(() => {
    

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [logOut]);
  const handleLogout = () => {
    // Perform logout actions, such as clearing authentication tokens, etc.
    //console.log("Logout here")
    navigate("/login");
    window.location.reload();
    logOut();
    //navigate("/login");
  };
///////////////////////////

  let shaking = true;
  const iconClassName = shaking ? 'shake-animation' : '';

  const isDashboardViewAccess = user.UserAccess[6].Options.includes(user.role);
  const isTableViewAccess = user.UserAccess[7].Options.includes(user.role);
  const isTrendViewAccess = user.UserAccess[8].Options.includes(user.role);
  const isAlarmViewAccess = user.UserAccess[9].Options.includes(user.role);
  const isReportViewAccess = user.UserAccess[10].Options.includes(user.role);
  const isAuditTrailViewAccess = user.UserAccess[11].Options.includes(user.role);
  const isUserMgmtViewAccess = user.UserAccess[12].Options.includes(user.role);
  //console.log(isUserMgmtViewAccess, user.role)
  // Get the logo from backend 7 stored as Logo
  const [logo, setLogo] = useState(null);
  const handleLogoImageDownload= async (Logo) => {
    try {

      const response = await AuthService.downloadProfilePhoto(Logo);

      //console.log(response.data);
      const base64 = btoa(new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte),
         ''));
      setLogo('data:image/jpeg; base64,' + base64);
     
    } catch (error) {
      console.log(error);
      
    }
  }

  useEffect(() => {handleLogoImageDownload('Logo');}, []);

  const initialLayoutConfigData = [{

    SrNo:'1',
    ComponentName:'TopBar',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'TopBar',
    backgroundColor: '#00aeef',
    fontWeight: 'normal',
    fontSize: '16 px',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '0vh',
    minWidth: '0vw',
    maxHeight: '1vh',
    maxWidth: '1vw',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'2',
    ComponentName:'Logo',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'Logo',
    backgroundColor: '#00aeef',
    fontWeight: 'normal',
    fontSize: '16 px',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '50',
    minWidth: '156',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'3',
    ComponentName:'ProjectDescription',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'ProjectDescription',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'4',
    ComponentName:'ComFailIcon',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'ComFailIcon',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'5',
    ComponentName:'AlarmIcon',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'AlarmIcon',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'6',
    ComponentName:'UserIcon',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'UserIcon',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'7',
    ComponentName:'CompanyName',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'CompanyName',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'8',
    ComponentName:'BackgroundImage',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'BackgroundImage',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  }];
  const [layoutConfigData, setLayoutConfigData] = useState(initialLayoutConfigData);
  const [projectDescrprops, setProjectDescrprops] = useState(initialLayoutConfigData[2]);
  //Get configuration of the Layout
  useEffect(() =>{
    async function getColumnData()  {
    try {

      let dashboardName = "LayoutConfig";
      let columnName = "WidgetName";
      let columnValue = "LayoutConfig";
      const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue );

      //console.log(response.data[0].backgroundColor);

      setLayoutConfigData(response.data);
      setProjectDescrprops(response.data[2]);
      
    } catch (error) {
      console.log(error);

    }
  }
  getColumnData();



  }, []);

  
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex',}} >
        <CssBaseline />

          {/* Top Bar with background color #4a90e2 */}
          <Box>
          <AppBar position="fixed" open={open} sx={{  backgroundColor: layoutConfigData[0].backgroundColor}}>
            <Toolbar
              sx={{
                pr: '20px', // keep right padding when drawer closed
                
              }}
              style={{minHeight: `${layoutConfigData[0].minHeight}vh`, maxHeight: `${layoutConfigData[0].minHeight}vh`}}
              
            >
              {/* Toggle Drawer */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                onContextMenu={(e) => {
                  e.preventDefault();
                  handleToggleMinimize();
                }}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>


              {/* Logo Image */}
              <Box
                sx={{
                  width: '10vw', // Outer Box width as a percentage of viewport width
                  minHeight: `${layoutConfigData[0].minHeight}vh`, // Outer Box minimum height
                  maxHeight: `${layoutConfigData[0].minHeight}vh`, // Outer Box maximum height
                  overflow: 'hidden', // Hide overflow content
                  //bgcolor: 'lightblue', // Background color of the Outer Box
                  mr: '1vw', // Margin-right of the Outer Box
                  display: 'flex', // Flexbox layout for centering
                  justifyContent: 'center', // Center content horizontally
                  alignItems: 'center', // Center content vertically
                }}
              >
                <Box
                  sx={{
                    width: `${layoutConfigData[1].minWidth}vw`, // Inner Box width as a percentage of viewport width
                    minHeight: `${layoutConfigData[1].minHeight}vh`, // Outer Box minimum height
                    maxHeight: `${layoutConfigData[1].minHeight}vh`, // Outer Box maximum height
                    ml: parseInt(layoutConfigData[1].ml), // Margin-left of the Inner Box
                    mr: parseInt(layoutConfigData[1].mr), // Margin-right of the Inner Box
                    mt: parseInt(layoutConfigData[1].mt), // Margin-top of the Inner Box
                    mb: parseInt(layoutConfigData[1].mb), // Margin-bottom of the Inner Box
                    display: 'flex', // Ensure flexbox layout for centering image
                    alignItems: 'center', // Center image vertically
                    justifyContent: 'center', // Center image horizontally
                    //bgcolor: 'black', // Background color of the Outer Box
                    overflow: 'hidden', // Hide any overflow content
                  }}
                >
                  {layoutConfigData[1].ComponentStatus === 'Enabled' && (
                    <Tooltip title="Logo">
                      <img
                        src={logo}
                        alt="Logo"
                        style={{
                          width: '100%', // Image width to fill the Inner Box
                          height: '100%', // Image height to fill the Inner Box
                          objectFit: 'contain', // Maintain aspect ratio and fit within the Inner Box
                          //maxWidth: '100%', // Ensure the image does not exceed Inner Box width
                          //maxHeight: '100%', // Ensure the image does not exceed Inner Box height
                          display: 'block', // Ensure no extra space around the image
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              </Box>

              {/* Project Description */}
              <Box
                sx={{
                  width: '70vw', // Outer Box width as a percentage of viewport width
                  minHeight: `${layoutConfigData[0].minHeight}vh`, // Outer Box minimum height
                  maxHeight: `${layoutConfigData[0].minHeight}vh`, // Outer Box maximum height
                  overflow: 'hidden', // Hide overflow content
                  //bgcolor: 'lightblue', // Background color of the Outer Box
                  mr: '1vw', // Margin-right of the Outer Box
                  display: 'flex', // Flexbox layout for centering
                  justifyContent: 'center', // Center content horizontally
                  alignItems: 'center', // Center content vertically
                }}
              >
                <Box
                  sx={{
                    width: `${layoutConfigData[2].minWidth}vw`, // Inner Box width as a percentage of viewport width
                    minHeight: `${layoutConfigData[2].minHeight}vh`, // Outer Box minimum height
                    maxHeight: `${layoutConfigData[2].minHeight}vh`, // Outer Box maximum height
                    ml: parseInt(layoutConfigData[2].ml), // Margin-left of the Inner Box
                    mr: parseInt(layoutConfigData[2].mr), // Margin-right of the Inner Box
                    mt: parseInt(layoutConfigData[2].mt), // Margin-top of the Inner Box
                    mb: parseInt(layoutConfigData[2].mb), // Margin-bottom of the Inner Box
                    display: 'flex', // Ensure flexbox layout for centering image
                    alignItems: 'center', // Center image vertically
                    justifyContent: 'center', // Center image horizontally
                    //bgcolor: 'black', // Background color of the Outer Box
                    overflow: 'hidden', // Hide any overflow content
                  }}
                >
                  {layoutConfigData[2].ComponentStatus === 'Enabled' &&(<ProjectDescription projectName = {selecetdProject} projectDescrprops = {projectDescrprops}/>)}

                </Box>
              </Box>

              {/* Communication fail icon */}
              <Box
                sx={{
                  width: '3vw', // Outer Box width as a percentage of viewport width
                  minHeight: `${layoutConfigData[0].minHeight}vh`, // Outer Box minimum height
                  maxHeight: `${layoutConfigData[0].minHeight}vh`, // Outer Box maximum height
                  overflow: 'hidden', // Hide overflow content
                  //bgcolor: 'lightblue', // Background color of the Outer Box
                  mr: '1vw', // Margin-right of the Outer Box
                  display: 'flex', // Flexbox layout for centering
                  justifyContent: 'center', // Center content horizontally
                  alignItems: 'center', // Center content vertically
                }}
              >
                <Box
                  sx={{
                    width: `${layoutConfigData[3].minWidth}vw`, // Inner Box width as a percentage of viewport width
                    minHeight: `${layoutConfigData[3].minHeight}vh`, // Outer Box minimum height
                    maxHeight: `${layoutConfigData[3].minHeight}vh`, // Outer Box maximum height
                    ml: parseInt(layoutConfigData[3].ml), // Margin-left of the Inner Box
                    mr: parseInt(layoutConfigData[3].mr), // Margin-right of the Inner Box
                    mt: parseInt(layoutConfigData[3].mt), // Margin-top of the Inner Box
                    mb: parseInt(layoutConfigData[3].mb), // Margin-bottom of the Inner Box
                    display: 'flex', // Ensure flexbox layout for centering image
                    alignItems: 'center', // Center image vertically
                    justifyContent: 'center', // Center image horizontally
                    //bgcolor: 'black', // Background color of the Outer Box
                    overflow: 'hidden', // Hide any overflow content
                  }}
                >
                  {layoutConfigData[3].ComponentStatus === 'Enabled' &&(<Tooltip title="Communication Fail">
                    <IconButton  
                      color="inherit" 
                      onClick={() => handleComponentSelection(componentList[4])}
                      sx={{ ml: parseInt(layoutConfigData[3].ml), mr: parseInt(layoutConfigData[3].mr), mt: parseInt(layoutConfigData[3].mt), mb: parseInt(layoutConfigData[3].mb),}}
                    >
                      <Badge badgeContent={liveAlarmCount} color="error"> {/* Avaialabe options are primary, secondary, success, error or for custom color use sx={{color: "red"}} etc*/}
                        <LinkOffIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip >)}

                </Box>
              </Box>

              {/* Alarm Icon */}
              <Box
                sx={{
                  width: '3vw', // Outer Box width as a percentage of viewport width
                  minHeight: `${layoutConfigData[0].minHeight}vh`, // Outer Box minimum height
                  maxHeight: `${layoutConfigData[0].minHeight}vh`, // Outer Box maximum height
                  overflow: 'hidden', // Hide overflow content
                  //bgcolor: 'lightblue', // Background color of the Outer Box
                  mr: '1vw', // Margin-right of the Outer Box
                  display: 'flex', // Flexbox layout for centering
                  justifyContent: 'center', // Center content horizontally
                  alignItems: 'center', // Center content vertically
                }}
              >
                <Box
                  sx={{
                    width: `${layoutConfigData[4].minWidth}vw`, // Inner Box width as a percentage of viewport width
                    minHeight: `${layoutConfigData[4].minHeight}vh`, // Outer Box minimum height
                    maxHeight: `${layoutConfigData[4].minHeight}vh`, // Outer Box maximum height
                    ml: parseInt(layoutConfigData[4].ml), // Margin-left of the Inner Box
                    mr: parseInt(layoutConfigData[4].mr), // Margin-right of the Inner Box
                    mt: parseInt(layoutConfigData[4].mt), // Margin-top of the Inner Box
                    mb: parseInt(layoutConfigData[4].mb), // Margin-bottom of the Inner Box
                    display: 'flex', // Ensure flexbox layout for centering image
                    alignItems: 'center', // Center image vertically
                    justifyContent: 'center', // Center image horizontally
                    //bgcolor: 'black', // Background color of the Outer Box
                    overflow: 'hidden', // Hide any overflow content
                  }}
                >
                  {(layoutConfigData[4].ComponentStatus === 'Enabled' && liveAlarmCount === 0) && (
                
                    <IconButton 
                    color="inherit" 
                    onClick={() => handleComponentSelection(componentList[4])} 
                    sx={{ ml: parseInt(layoutConfigData[4].ml), mr: parseInt(layoutConfigData[4].mr), mt: parseInt(layoutConfigData[4].mt), mb: parseInt(layoutConfigData[4].mb),}}
                    >
                      <Badge badgeContent={liveAlarmCount} color="secondary">
                        <NotificationsIcon color="primary"/>
                      </Badge>
                    </IconButton>
                  
                  )}

                  {(layoutConfigData[4].ComponentStatus === 'Enabled' && liveAlarmCount >= 1) && (
                    <Tooltip title="Live Alarms">
                      <IconButton 
                      color="inherit" 
                      onClick={() => handleComponentSelection(componentList[4])} 
                      sx={{ ml: parseInt(layoutConfigData[4].ml), mr: parseInt(layoutConfigData[4].mr), mt: parseInt(layoutConfigData[4].mt), mb: parseInt(layoutConfigData[4].mb),}}
                      >
                        <Badge badgeContent={liveAlarmCount} color="error"> {/* Avaialabe options are primary, secondary, success, error or for custom color use sx={{color: "red"}} etc*/}
                          <NotificationsActiveIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip >
                  )}
                </Box>
              </Box>

              {/* Profile Icon */}
              <Box
                sx={{
                  width: '5vw', // Outer Box width as a percentage of viewport width
                  minHeight: `${layoutConfigData[0].minHeight}vh`, // Outer Box minimum height
                  maxHeight: `${layoutConfigData[0].minHeight}vh`, // Outer Box maximum height
                  overflow: 'hidden', // Hide overflow content
                  //bgcolor: 'lightblue', // Background color of the Outer Box
                  mr: '1vw', // Margin-right of the Outer Box
                  display: 'flex', // Flexbox layout for centering
                  justifyContent: 'center', // Center content horizontally
                  alignItems: 'center', // Center content vertically
                }}
              >
                <Box
                  sx={{
                    width: `${layoutConfigData[5].minWidth}vw`, // Inner Box width as a percentage of viewport width
                    minHeight: `${layoutConfigData[5].minHeight}vh`, // Outer Box minimum height
                    maxHeight: `${layoutConfigData[5].minHeight}vh`, // Outer Box maximum height
                    ml: parseInt(layoutConfigData[5].ml), // Margin-left of the Inner Box
                    mr: parseInt(layoutConfigData[5].mr), // Margin-right of the Inner Box
                    mt: parseInt(layoutConfigData[5].mt), // Margin-top of the Inner Box
                    mb: parseInt(layoutConfigData[5].mb), // Margin-bottom of the Inner Box
                    display: 'flex', // Ensure flexbox layout for centering image
                    alignItems: 'center', // Center image vertically
                    justifyContent: 'center', // Center image horizontally
                    //bgcolor: 'black', // Background color of the Outer Box
                    overflow: 'hidden', // Hide any overflow content
                  }}
                >
                  {layoutConfigData[5].ComponentStatus === 'Enabled' && (<ProfileMenu/>)}
                </Box>
              </Box>

            </Toolbar>
          </AppBar>
          </Box>

          {/* Drawer for menu items*/}
          <Drawer 
          variant="permanent" 
          open={open} 
          hidden={hidden} // Pass the drawerWidth prop here
          >
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
                
                
              }}
              style={{minHeight: `${layoutConfigData[0].minHeight}vh`,}}
            >
              <IconButton 
              onClick={toggleDrawer}
              onContextMenu={(e) => {
                e.preventDefault();
                handleToggleMinimize();
              }}
              >
              {layoutConfigData[6].ComponentStatus === 'Enabled' &&(
                <a style={{ backgroundColor: layoutConfigData[6].backgroundColor, fontSize: parseInt(layoutConfigData[6].fontSize), fontWeight: parseInt(layoutConfigData[6].fontWeight), color: layoutConfigData[6].textColor}}> {user.companyname}  </a>
              )}
                <ChevronLeftIcon/>
              </IconButton>

            </Toolbar>
            <Divider />
            <List component="nav" >
              {(isDashboardViewAccess || user.role === "superadmin" || user.role === "engineer" || user.role === "admin") && (
                <Tooltip title="Mimic View" placement="right">
                <ListItemButton onClick={() => handleComponentSelection(componentList[1])}>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary= "Mimic View" />
                </ListItemButton>
                </Tooltip>
              )}

              {(isTableViewAccess || user.role === "superadmin" || user.role === "engineer") && (
                <Tooltip title="Table View" placement="right">
                <ListItemButton onClick={() => handleComponentSelection(componentList[2])}>
                  <ListItemIcon>
                    <TableViewIcon />
                  </ListItemIcon>
                  <ListItemText primary= "Table View" />
                </ListItemButton>
                </Tooltip>
              )}
              
              {(isAlarmViewAccess || user.role === "superadmin" || user.role === "engineer") && (
                <Tooltip title="Alarms" placement="right">
                <ListItemButton onClick={() => handleComponentSelection(componentList[4])}>
                  <ListItemIcon>
                  <NotificationsActiveIcon />
                  </ListItemIcon>
                  <ListItemText primary="Alarms" />
                </ListItemButton>
                </Tooltip>
              )}


              {(isTrendViewAccess || user.role === "superadmin" || user.role === "engineer") && (
                <Tooltip title="Trend View" placement="right">
                <ListItemButton onClick={() => handleComponentSelection(componentList[3])}>
                  <ListItemIcon>
                  <TimelineIcon />
                  </ListItemIcon>
                  <ListItemText primary= "Trend View"/>
                </ListItemButton>
                </Tooltip>
              )}

              

              {(isReportViewAccess || user.role === "superadmin" || user.role === "engineer") && (
                <Tooltip title="Reports" placement="right">
                <ListItemButton onClick={() => handleComponentSelection(componentList[5])}>
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItemButton>
                </Tooltip>
              )}

              {(isAuditTrailViewAccess || user.role === "superadmin" || user.role === "engineer") && (
                <Tooltip title="AuditTrail" placement="right">
                <ListItemButton onClick={() => handleComponentSelection(componentList[6])}>
                  <ListItemIcon>
                  <img src={AuditTrail} alt="Custom Icon" style={{ width: 24, height: 24 }} />
                  </ListItemIcon>
                  <ListItemText primary="AuditTrail" />
                </ListItemButton>
                </Tooltip>
              )}
              
              {((isUserMgmtViewAccess || user.role === "superadmin" || user.role === "engineer") && (user.role !== "visitor" && user.role !== "operator") ) && (
                <Tooltip title="User Management" placement="right">
                <ListItemButton onClick={() => handleComponentSelection(componentList[7])}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary="User Management" />
                </ListItemButton>
                </Tooltip>
              )}

              {(user.role === "superadmin" || user.role === "engineer") && (
                <Tooltip title="System Configurations" placement="right">
                <ListItemButton onClick={() => handleComponentSelection(componentList[8])}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="System Configurations" />
                </ListItemButton>
                </Tooltip>
              )}

              {(user.role === "superadmin" || user.role === "engineer") && (
                <Tooltip title="FTP Settings" placement="right">
                <ListItemButton onClick={() => handleComponentSelection(componentList[9])}>
                  <ListItemIcon>
                  <img src={FTPIcon} alt="Custom Icon" style={{ width: 24, height: 24 }} />
                  </ListItemIcon>
                  <ListItemText primary="FTP Settings" />
                </ListItemButton>
                </Tooltip>
              )}


            {/* {mainListItems} */}
              <Divider sx={{ my: 1 }} />
              {/*  {secondaryListItems}  */}
            </List> 
          </Drawer>

          {/* Main Container to display the data and widgets */}
          <Box
            component="main"
            sx={{ backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: `${100 - parseInt(layoutConfigData[0].minHeight)}vh`,
              overflow: 'auto',
              mt:`${layoutConfigData[0].minHeight}vh`,           
            }}

            
          >
            {/* Display welocme screen or  menu item of drawer*/}
              {selectedComponent} 

            {/* Bottom bar/ Copy right bar*/}
            <Copyright sx={{ pt: 1}} />         

          </Box>


      </Box>


      <IdleTimeout timeout={timeOutSP} onLogout={handleLogout} /> {/* 30 seconds timeout */}
    </ThemeProvider>
    
  );
}