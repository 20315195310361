import * as React from 'react';
import { useState, useEffect, useRef} from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ConfigurationViewService from '../services/configurationview.service';

const options = ['Dashboard1', 'Dashboard2', 'Dashboard3'];

export default function SplitButton2({projectName, dashboardType, selectedDashboard} ) {
  
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dashboardList, setDashboardList] = useState([]);
  const [dashboard, setDashboard] = useState();


  useEffect(() =>{
    async function Test()  {
    try {
      //console.log(projectName, dashboardType);

      const response = await ConfigurationViewService.getDashboardList(projectName, dashboardType);
      
      //setDashboardList(response.data);

      let sortedDasboardList = response.data.slice().sort();  // Sort Data alphabitically to get dashboardlist in alphabitical manner
      setDashboardList(sortedDasboardList);
      //setDashboardList(data.slice().sort()); // Sort Data alphabitically to get dashboardlist in alphabitical manner
      //console.log(data);
      //let test = response.data;
      //const data = test.map((item) => (item.dashboardname));

      
      // Send back default selecetd dasboardName to the 
      selectedDashboard(sortedDasboardList[selectedIndex]);

      return response.data;
      // if returning only return response then serialisation error can occured
      //hence return as response.data
      
    } catch (error) {
      console.log(error);

    }
  }
  Test();

}, []);

  const handleClick = () => {
    //console.log("button Clicked")
    //console.info(`You clicked ${dashboardList[selectedIndex]}`);
    setDashboard(dashboardList[selectedIndex]);
    
    
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    selectedDashboard(dashboardList[index]);
    handleClick();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <Button onClick={handleToggle}>{dashboardList[selectedIndex]}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 10,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {dashboardList.map((option, index) => (
                    <MenuItem
                      key={option}
                      //disabled={index === 3}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
