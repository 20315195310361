import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { TextField, Button, FormControl, makeStyles } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import AuditTrailViewService from '../../services/audittrailview.service.js';
import ConfigurationViewService from '../../services/configurationview.service';
import TableViewService from '../../services/tableview.service';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

//function to remove keys from the each json object of the json Array.
function removeKeyValuesFromObjects(jsonArray, keysToRemove) {
  return jsonArray.map((item) => {
    const updatedObject = { ...item };

    keysToRemove.forEach((keyToRemove) => {
      delete updatedObject[keyToRemove];
    });

    return updatedObject;
  });
}

// Function to check json object is present in json array or not
function isObjectInArray(jsonArray, targetObject) {
  return jsonArray.some((item) => {
    for (const key in targetObject) {
      if (targetObject.hasOwnProperty(key) && item[key] !== targetObject[key]) {
        return false;
      }
    }
    return true;
  });
}


// Check blank values inside the row data
function hasBlankOrNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === '') {
      return true; // Found a blank or null value
    }

    if (typeof value === 'object' && hasBlankOrNullValues(value)) {
      return true; // Recursively check nested objects
    }
  }

  return false; // No blank or null values found
}

// Function to create auditTrailData
const createAuditTrailData = (oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus,widgetName) => {
  
  let auditTrailData = [];
  // Get the keys of the oldValues
  const keys1 = Object.keys(oldValues);
  //console.log(keys1)
  //console.log(Object.keys(newValues))
  //console.log(keys1.length, Object.keys(newValues).length)
  // Check if the number of keys is the same
  if (keys1.length !== Object.keys(newValues).length) {
    return auditTrailData;
  }else{
    // Iterate over the keys of the oldValues
    for (let key of keys1) {
      //console.log(key, oldValues[key], newValues[key]);
      
      let activityStatus = activity;
      // modify Activity if event is enabled else activity will be deafualt value received form audittrail config collection
      if(eventStatus === "Enabled"){
        if(key === "TagName"){
          activityStatus = `Added new parameter - '${tagName}' to the table - '${widgetName}'`;
          auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
        }
        
      } else{
        if(key === "TagName"){
          auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
        }
      }  
      
    }
    return auditTrailData;
  }

  

};


function SaveChangesDialog(props) {
  const [open, setOpen] = useState(false);

  const [auditTrailData, setAuditTrailData] = React.useState([]);  // AuditTrail Data

  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);
  const [password, setPassword] = useState('');
  const[pwdinterlock, setPwdInterlock] = useState("Disabled");

  const handleClickOpen = () => {

    if(((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === "--")) || ((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === ""))){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
    setOpen(true);
    }
    
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRemarkBlnkMsg(false);
  };

  const handleSuccessClose = () => {
    setOpenSuccessDialog(false);
    props.success(true);
  };

  const handleFildClose = () => {
    setOpenFaildialog(false);
  };
  

  const [message, setMessage] = useState();

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFaildialog, setOpenFaildialog] = useState(false);

  const [saveData, setSaveData] = useState(false);
  const handleClickSaveChanges = () => { 
    let oldValues = props.oldValues;
    let newValues = props.newValues;
    let tagName = props.tagName;
    let activity = props.activity;
    let activityType = 'Add Parameter To Table';
    let userName = props.email;
    let role = props.role;
    let remark = props.auditTrailRemark;
    let eventStatus = props.rowData.EventStatus;

    let widgetName = props.rowData.WidgetName;

    let auditTrailData = createAuditTrailData(oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus, widgetName )
    //console.log(auditTrailData)
    setAuditTrailData(auditTrailData)
    setSaveData(true);
     };

  const[tableViewData, setTableViewData] = React.useState([]);

  //Get Application Configuration data 
  useEffect(() =>{
    async function getColumnData()  {
      try {

        let dashboardName = "ApplicationConfig";
        const response = await ConfigurationViewService.getAllRowsData(dashboardName);

        if(response.data.length > 0){
          //setPwdInterlock(response.data[9].TaskStatus);
          //console.log(response.data[9].TaskStatus); 
        }

        
        
      } catch (error) {
        console.log(error);

      }
    }
    getColumnData();



  }, []);

  // Get  All data from TableView
  useEffect(() =>{
    async function getAllRowsData()  {
    try {

      let dashboardName = "TableView"

      const response = await TableViewService.getAllRowsData(dashboardName);
      if (response){
        setTableViewData(response.data);
        //setTagNames(response.data.map((item) => item.TagName)); 
        
      }
      
      
    } catch (error) {
      console.log(error);

    }
  };
  getAllRowsData();

  }, []);


  //  Add new row to collection 
  useEffect(() =>{
    async function saveRowData()  {
    try {

        let dashboardName = "TableView"
        //let data = {...props.rowData, "ProjectName": projectName, "DashboardType": dashboardType, "WidgetName": widgetName,}
        let data = {ProjectName: props.rowData.ProjectName, DashboardType: props.rowData.DashboardType, WidgetName: props.rowData.WidgetName,
                      TagType:props.rowData.TagType, BlockName:props.rowData.BlockName, TagName:props.rowData.TagName,
                   }
        let rowData = {...props.rowData, PWDInterlock: props.rowData.PWDInterlock, PWD: password}; // It should include all three field ie ProjectName, DashboardType, WidgetName

        // Remove _id field from tableView data
        const updatedArray = removeKeyValuesFromObjects(tableViewData, ['_id', '__v']); // remove _id and __v keys from tableviewdata

        //if tagtype is system tag thhen proceed with blockname value as blank or null
        if(rowData.TagType === "SystemTag" && rowData.BlockName === "" && rowData.TagName !== ""){
        
        // Tag name Duplcation check for new tag when TagType is selected as Systemtag
        if(isObjectInArray(updatedArray, rowData)) {
          setOpenFaildialog(true);
          setMessage("Tag Name already exist. Choose different Tag name to avoid duplication.");
          setSaveData(false);
          setOpen(false); // Close "Save changes" window

        }else{
          const response = await TableViewService.createRow(dashboardName, rowData, auditTrailData);  // create new record if id is not present in array data ie existing data
            if(response){
              setOpenSuccessDialog(true);
              setMessage("Record Added Successfully");
              setSaveData(false);
              setOpen(false); // Close "Save changes" window
      
            }

        }
          
        }else{
          //console.log(updatedArray);
          //console.log(data);
          // Tag name Duplcation check for new tag when TagType is selected as device or alarm tag
          if(isObjectInArray(updatedArray, data)) {
            setOpenFaildialog(true);
            setMessage("Tag Name already exist. Choose different Tag name to avoid duplication.");
            setSaveData(false);
            setOpen(false); // Close "Save changes" window

          }else{
            // Blank value check for the  one of the form field
            //let data= {TagType:props.rowData.TagType, BlockName:props.rowData.BlockName, TagName:props.rowData.TagName,}

            if (hasBlankOrNullValues(data)) {
              setOpenFaildialog(true);
              setMessage("One of the field is blank inside the form, please correct it.");
              setSaveData(false);
              setOpen(false); // Close "Save changes" window

            } else {
              //console.log(rowData);
              //console.log(auditTrailData);
                const response = await TableViewService.createRow(dashboardName, rowData, auditTrailData);  // create new record if id is not present in array data ie existing data
                if(response){
                  setOpenSuccessDialog(true);
                  setMessage("Record Added Successfully");
                  setSaveData(false);
                  setOpen(false); // Close "Save changes" window
          
                }
            }
          }

        }
        

      

    } catch (error) {
      setOpenFaildialog(true);
      setMessage("Error While Adding Record, Please very all parameters entered in form");
      setOpen(false); // Close "Save changes" window
      console.log(error);


    }
  };
  if(saveData){
    saveRowData();
    setSaveData(false);
  };


  }, [saveData]);
  

  return (
    
    <div>
      <Button variant="outlined" startIcon ={<SaveIcon/>} sx={{mt:1}} onClick={handleClickOpen}>SAVE CHANGES </Button>

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Open Dialog to ask surity to edit user's data, with or withoout password */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>SAVE CHANGES</DialogTitle>
        <Box sx={{width: 400 }}>
        <DialogContent dividers>
          <DialogContentText>
            Are you sure to add the new row?.
          </DialogContentText>
          
        </DialogContent>

        {props.rowData.PWDInterlock === 'Enabled' && (<DialogContent dividers>
          <DialogContentText>
            Enter the Password to add new table.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          </DialogContent>
        )}

        </Box>
        <DialogActions>
          <Button onClick={handleClickSaveChanges}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Success dialog after upgradtion of teh user info. */}
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessClose}
       
      >
        <DialogTitle > success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Save Changes Failed dialog /Wrong Password */}
      <Dialog
        open={openFaildialog}
        onClose={handleFildClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFildClose}>Close</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}


export default function DatalogConfigAdd ({open, close}) {
  const { user } = useSelector((state) => state.auth);
  const projectName = user.selectedproject;
  const dashboardType = 'TableView';
  const widgetName = useSelector((state) => state.splitbutton1.widgetName);
  //const [widgetName, setWidgetname] = useState("")

  const initialTagConfigData = {
    //id: null,
    ProjectName:projectName,
    WidgetName:widgetName,
    DashboardType:dashboardType,

    TagType:"DeviceTag",
    BlockName:"",
    TagName:"",
    email: user.email,
    role: user.role, 
    RemarkStatus : "Disabled",
    EventStatus:"Disabled",
    PWDInterlock:"Disabled",
      
  };

  const [eventDescription, setEventDescription] = useState("--");
  const [auditTrailRemark, setAuditTrailRemark] = useState("--");
  // parameter list for audit trail log
  const oldValues = {TagType:"DeviceTag", BlockName:"", TagName:"",};
  const [newValues, setNewValues] = useState(oldValues);

  const[blockNames, setBlockNames] = React.useState([]);
  const[fetchBlockNames, setFetchBlockNames] = React.useState(true); 
  const[fetchDeviceTagList, setFetchDeviceTagList] = React.useState(false); 
  const[fetchSystemTagList, setFetchSystemTagList] = React.useState(false); 

  const[tagNames, setTagNames] = React.useState([]);
  const [configData, setConfigData] = React.useState(initialTagConfigData);

  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Add Parameter To Table';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setConfigData({...configData, EventStatus: response.data[0].EnableEvent, PWDInterlock: response.data[0].EnablePwdInterlock, RemarkStatus: response.data[0].EnableRemark});

        setEventDescription(response.data[0].DefaultEventDescription);
        // If remark field is disabled then set default remark vlaue to currrent Remark value
        if(response.data[0].EnableRemark === "Disabled"){
          setAuditTrailRemark(response.data[0].DefaultEventRemark);
        }
        
      }
  
    } catch (error) {
      console.log(error);

    }
    }

  auditTrailConfigData();

  }, []);

  // Get BlockName list
  useEffect(() =>{
    async function getAllRowsData()  {

      try {
        let dashboardName = "ModbusConfig"
        let columnName = "Status"
        let columnValue = "Enabled";

        const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue);
        if (response){
        let BlockNames = response.data.map((item) => item.BlockName);
        setBlockNames([...new Set(BlockNames)]); 
        }

      } catch (error) {
        console.log(error);

      }
    };
    if(fetchBlockNames){
      getAllRowsData();
      setFetchBlockNames(false);

    }


  }, [fetchBlockNames]);


  // Get  device taglist from the modbustaglist for specific BlockName.
  useEffect(() =>{
    async function getAllRowsData()  {
    try {

      let dashboardName = "ModbusTagList"
      let columnName = "BlockName"
      let columnValue = configData.BlockName;

      const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue);
      if (response){
        setTagNames(response.data.map((item) => item.TagName)); 
      }
      
      
    } catch (error) {
      console.log(error);

    }
  }
  if(fetchDeviceTagList){
    getAllRowsData();
    setFetchDeviceTagList(false);
    
  };


  }, [fetchDeviceTagList]);

  // Get  system taglist from the modbustaglist.
  useEffect(() =>{
    async function getAllRowsData()  {
    try {

      let dashboardName = "ModbusTagList"
      let columnName = "TagStatus"
      let columnValue = "SystemTag";

      const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue);
      if (response){
        setTagNames(response.data.map((item) => item.TagName)); 
      }
      
      
    } catch (error) {
      console.log(error);

    }
  }
  if(fetchSystemTagList){
    getAllRowsData();
    setFetchSystemTagList(false);
    
  };


  }, [fetchSystemTagList]);

  const handleClose = () => {
    //console.log("Close Buton Pressed");
    close() // Close the alarm add main window
  };

  const handleRecordSaveSuccess = (data) =>{
    close(data)
  }

  const handleTagTypeChange = (event) => {
    const {name, value} = event.target;
    setConfigData({...configData, [name]:value});
    if(value === "DeviceTag"){
      setFetchBlockNames(true);
      setFetchDeviceTagList(true);
    }

    if(value === "SystemTag"){
      setFetchSystemTagList(true);
      setBlockNames([]);
    }

  };

  const handleBlockNameChange = (event) => {
    const {name, value} = event.target;
    setConfigData({...configData, [name]:value});

    if(configData.TagType === "DeviceTag"){
      setFetchDeviceTagList(true);  // set true to Get Taglist for the selected BlockNmae
    }

  };

  const handleTagNameChange = (event) => {
    const {name, value} = event.target;
    setConfigData({...configData, [name]:value});
    setNewValues ({...newValues, [name] :value});


  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    if(configData.RemarkStatus === "Enabled"){
      setAuditTrailRemark(value);

    }
  };



  return (
    <div>
      
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            maxWidth: '1000px', // Change this value as needed
          },
        }}
      >
        {/* Hnadle multiple functions on single click ie edit switch set to false and close the MyAccount Dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose();  }}>
          Add Tag to the Widget.
        </BootstrapDialogTitle>

        {/*Tag Selection for the datalog */}
        <DialogContent dividers>
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 200 }}>
          Tag Selection. 
          </Typography>

          {/* TagType */}
          <FormControl sx={{ m: 0, width: 200, mt: 1 }} >
              <InputLabel shrink={false} sx={{ mt: -5,ml: "-14px" }}>Type of Tag</InputLabel>
              <Select
              autoFocus
              name="TagType"
              value={configData.TagType}
              onChange={handleTagTypeChange}
              sx={{height: '40px',}}  // Adjust the height as needed
              //input={<BootstrapInput />}
              >
              <MenuItem value={'DeviceTag'}>Device</MenuItem>
              <MenuItem value={'SystemTag'}>System</MenuItem>
              </Select>
          </FormControl>

          {/* blockName */}
          <FormControl sx={{ m: 2, width: 200, mt: 1 }} >
          <InputLabel shrink={false} sx={{ mt: -5,ml: "-14px" }}>Block Name</InputLabel>
              <Select
                name="BlockName"
                //value={configData.BlockName}
                value={(blockNames.includes(configData.BlockName)) ? configData.BlockName : ''}
                disabled={configData.TagType === "SystemTag"}
                onChange={handleBlockNameChange }
                sx={{height: '40px',}}  // Adjust the height as needed
                //input={<BootstrapInput />}
              >

                {blockNames.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>

          {/* TagName */}
          <FormControl sx={{ m: 0, width: 200, mt: 1 }} >
              <InputLabel shrink={false} sx={{ mt: -5,ml: "-14px" }}>Tag Name</InputLabel>
              <Select
                name="TagName"
                //value={configData.TagName}
                value={(tagNames.includes(configData.TagName)) ? configData.TagName : ''}
                onChange={handleTagNameChange}
                //input={<BootstrapInput />}
               sx={{height: '40px',}}  // Adjust the height as needed
              >

                {tagNames.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>


        </DialogContent>

        {/*Remark */}
        {configData.RemarkStatus === 'Enabled' && (<DialogContent 
        dividers
        >
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 400 }}>
          Enter remark/reason 
          </Typography>

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            name="Remark"
            placeholder="Enter Remark"
            multiline
            rows={2}
            style={{ width: 550 }}
            onChange={handleRemarkChange}
            //defaultValue="Default Value"
          />

        </DialogContent>
        )}

        <DialogActions>
          <SaveChangesDialog
          projectName = {projectName}
          dashboardType = {dashboardType}
          widgetName = {widgetName}
          rowData = {configData}

          email = {user.email}
          role = {user.role}
          oldValues = {oldValues}
          newValues = {newValues}
          activity = {eventDescription}
          tagName = {configData.TagName}
          auditTrailRemark = {auditTrailRemark}

          success = {handleRecordSaveSuccess}
          />
        </DialogActions>
        
      </BootstrapDialog>
    </div>
  );
}



