import { createSlice } from '@reduxjs/toolkit';

const splitButton1= createSlice({
  name: 'widgetName',
  initialState: { widgetName: '' },
  reducers: {
    setWidgetName: (state, action) => {
      state.widgetName = action.payload;
    },
  },
});

export const { setWidgetName} = splitButton1.actions;
//export default splitButton1.reducer;
export const splitbutton1Reducer = splitButton1.reducer;



const splitButton1Description= createSlice({
  name: 'widgetDescription',
  initialState: { widgetDescription: '' },
  reducers: {
    setWidgetDescription: (state, action) => {
      state.widgetDescription = action.payload;
    },
  },
});

export const { setWidgetDescription} = splitButton1Description.actions;
//export default splitButton1Description.reducer;
export const splitButton1DescriptionReducer = splitButton1Description.reducer;