import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ConfigurationViewService from '../services/configurationview.service.js';

export default function Copyright(props) {
  const[bannerStatus, setBannerStatus] = useState("Enabled");

  //Get configuration of the selected widget
  useEffect(() =>{
    async function getColumnData()  {
    try {

      let dashboardName = "ApplicationConfig";
      let columnName = "WidgetName";
      let columnValue = "ApplicationConfig";

      const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue );
      if (response){
        //console.log(response.data[4].TaskStatus);
        setBannerStatus(response.data[4].TaskStatus);
      }
      

      
      
    } catch (error) {
      console.log(error);

    }
  }
  getColumnData();



  }, []);

  return (
    <React.Fragment>
    {bannerStatus === "Enabled" && (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        
        <Link color="inherit" href="http://radix.co.in" target="_blank">
          Radix Electrosystems 
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>

    )}
    
    </React.Fragment>
  );
}