import * as React from 'react';
import {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, GridEditInputCell} from '@mui/x-data-grid';
import { createFakeServer } from '@mui/x-data-grid-generator';
import TableViewService from '../../services/tableview.service';
import { useDispatch, useSelector } from "react-redux";
import DashboardAdd from './DashboardAdd.js';

import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Alert from '@mui/material/Alert';

const PAGE_SIZE = 10;
const SERVER_OPTIONS = {
  useCursorPagination: true,
};
const { useQuery, ...data } = createFakeServer({}, SERVER_OPTIONS);

// Add form open for tad addition in selected widget/dashboard.
function EditToolbar(props) {
  const { setAddRecordForm } = props;

  const handleClick = () => {
    setAddRecordForm(true);
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Tag
      </Button>
    </GridToolbarContainer>
  );
}

export default function Dasboard() {
  const { user } = useSelector((state) => state.auth);
  const projectName = user.selectedproject;
  const dashboardType = 'TableView';
  const widgetName = useSelector((state) => state.splitbutton1.widgetName);

  const initWidgetConfigData = [{
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "1",
    "ParameterName": "Column1",
    "Enable": "Enabled",
    "Description": "Sr. no.",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "2",
    "ParameterName": "Column2",
    "Enable": "Enabled",
    "Description": "Device Name",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "3",
    "ParameterName": "Column3 ",
    "Enable": "Enabled",
    "Description": "Tag Name",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "4",
    "ParameterName": "Column4",
    "Enable": "Enabled",
    "Description": "Tag Description",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "5",
    "ParameterName": "Column5 ",
    "Enable": "Enabled",
    "Description": "Block Name",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "6",
    "ParameterName": "Column6 ",
    "Enable": "Enabled",
    "Description": "Block ID",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "7",
    "ParameterName": "Column7 ",
    "Enable": "Enabled",
    "Description": "Process Value",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "8",
    "ParameterName": "Column8",
    "Enable": "Enabled",
    "Description": "Unit",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "9",
    "ParameterName": "Column9",
    "Enable": "Enabled",
    "Description": "LoLo SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
     "SrNo": "10",
    "ParameterName": "Column10",
    "Enable": "Enabled",
    "Description": "Low SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "11",
    "ParameterName": "Column11",
    "Enable": "Enabled",
    "Description": "High SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
     "SrNo": "12",
    "ParameterName": "Column12",
    "Enable": "Enabled",
    "Description": "HiHi SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "13",
    "ParameterName": "Column13 ",
    "Enable": "Enabled",
    "Description": "Alarm Status",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "14",
    "ParameterName": "Header Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "#A9BBD3",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "15",
    "ParameterName": "Header Font Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "16",
    "ParameterName": "Header Font Size",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "21",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "17",
    "ParameterName": "Header font Weight",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "bold",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "18",
    "ParameterName": "Header Text Align",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "19",
    "ParameterName": "Rows Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "white",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "20",
    "ParameterName": "Rows Font Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
     "SrNo": "21",
    "ParameterName": "Rows Font Size",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "14",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "22",
    "ParameterName": "Rows font Weight",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "normal",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "23",
    "ParameterName": "Rows Text Align",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "24",
    "ParameterName": "LoLo Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "#A2121D",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "25",
    "ParameterName": "Low Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "blue",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "26",
    "ParameterName": "High Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "purple",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "27",
    "ParameterName": "HiHi Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "red",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "28",
    "ParameterName": "No Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na"
  },
  {
    "ProjectName": projectName ,
    "DashboardType": dashboardType,
    "WidgetName": widgetName,
    "SrNo": "29",
    "ParameterName": "Mouse Hover Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "skyblue",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  }];
  
  const[totalRows, setTotalRows] = useState(0);
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [fetchData, setFetchData] = useState(true);
  const[tableViewData, setTableViewData] = useState([]);
  const[tableViewFetchDone, setTableViewFetchDone] = useState(false);

  const[modbustaglistData, setModbustaglistData] = useState([]);
  const[modbustaglistFetchDone, setModbustaglistFetchDone] = useState(false);

  const[alarmconfigData, setAlarmconfigData] = useState([]);
  const[alarmconfigFetchDone, setAlarmconfigFetchDone] = useState(false);

  const[rowsData, setRowsData] = useState([]);
  const[configData, setConfigData] = useState(initWidgetConfigData);
  const [addRecordForm, setAddRecordForm] = React.useState(false)

  const[deleteDialog, setDeleteDialog] = React.useState(false); // Delete Dialog bit
  const noButtonRef = React.useRef(null); 
  const [rowTagName, setRowTagName] = React.useState(); // Row Id refernace to delete the record
  const[deleteData, setDeleteData] = React.useState(false); //Delete row bit
  const [snackbar, setSnackbar] = React.useState(null); // if delete Success then set to success otherwise will set to fail
  const handleCloseSnackbar = () => setSnackbar(null);

    
/*
    // Get Total Row Count
    useEffect(() =>{
        async function getRowsCount()  {
        try {
    
          let dashboardName = "TableView"
          const response = await TableViewService.getRowsCount(dashboardName);
          //console.log(response.data);
          setTotalRows(response.data);
          
        } catch (error) {
          console.log(error);
    
        }
      }
      getRowsCount();
    
    }, []);


    // Get  Specific row data with offset = page number * page size  and rowPerpage = pagesize
    useEffect(() =>{
        async function getRowsData()  {
        try {
      
          let dashboardName = "TableView"
          //const offset = page * rowsPerPage;
          //console.log(page);
          //console.log(offset);
          //console.log(rowsPerPage);
          
          const response = await TableViewService.getRowsData(dashboardName, offset, rowsPerPage);
          //console.log(response.data);
          setRowsData(response.data);

          //console.log(headerFontSize);
          //console.log(headerFontWeight);
          
        } catch (error) {
          console.log(error);
      
        }
      }
      getRowsData();
      
      }, [fetchData]);

    
*/
    //Get configuration of the selected widget
    useEffect(() =>{
      async function getColumnData()  {
      try {

        let dashboardName = "TableConfig";
        let columnName = "WidgetName";
        let columnValue = widgetName;
        
        const response = await TableViewService.getColumnData(dashboardName, columnName, columnValue );
        //console.log(response.data[16].FontWeight);
        if(response){
          setConfigData(response.data);
        }
        
        
      } catch (error) {
        console.log(error);

      }
    }
    getColumnData();



    }, [widgetName]);

    //Get Tableview data for selected widget
    useEffect(() =>{
      async function getColumnData()  {
      try {

        let dashboardName = "TableView";
        let columnName = "WidgetName";
        let columnValue = widgetName;
        
        const response = await TableViewService.getColumnData(dashboardName, columnName, columnValue );
        //console.log(response.data[16].FontWeight);
        if(response){
          //console.log(response.data);
          //console.log(response.data[0].TagName);
          setTableViewData(response.data);
          setTableViewFetchDone(true);
        }
        
        
      } catch (error) {
        console.log(error);

      }
    }
    if(fetchData){
      getColumnData();
      setFetchData(false);

    }
    
    }, [fetchData]);

    // Get data of specific columns from ModbusTagList table for specific tagnames
    useEffect(() =>{
      async function getModbusTagListData()  {
      try {
    
        // get list of values of TagName field from tableViewData
        const keyName = 'TagName'; // The key name you want to extract
        const valuesArray = tableViewData.map((item) => item[keyName]);

        let dashboardName = "ModbusTagList";
        let columnsToRetrieve = ["TagName", "TagDescription1", "TagDescription2", "TagDescription3", "TagDescription4", "ProcessValue", "TagUnit" ];
        let columnToFilter ="TagName";
        let valueToMatch = valuesArray

        
        const response = await TableViewService.getMultiColumnsRows(dashboardName, columnsToRetrieve, columnToFilter, valueToMatch );
        //console.log(response.data);
        if(response){
          setModbustaglistData(response.data);
          setModbustaglistFetchDone(true);
        }
     
      } catch (error) {
        console.log(error);
    
      }
    }
    if(tableViewFetchDone){
      getModbusTagListData();
      setTableViewFetchDone(false);
    }
    
    
    }, [tableViewFetchDone]);

    // Get data of specific columns from AlarmConfig table for specific tagnames
    useEffect(() =>{
      async function getAlarmConfigData()  {
      try {
    
        // get list of values of TagName field from tableViewData
        const keyName = 'TagName'; // The key name you want to extract
        const valuesArray = tableViewData.map((item) => item[keyName]);

        let dashboardName = "AlarmConfig";
        let columnsToRetrieve = ["TagName", "LoLoSP", "LowSP", "HighSP", "HiHiSP", "PresentAlarmStatus"];
        let columnToFilter ="TagName";
        let valueToMatch = valuesArray;
        //console.log(tableViewData[0].TagName);
        
        const response = await TableViewService.getMultiColumnsRows(dashboardName, columnsToRetrieve, columnToFilter, valueToMatch );
        if(response){
          setAlarmconfigData(response.data);
          setAlarmconfigFetchDone(true);
        }
        

        
      } catch (error) {
        console.log(error);
    
      }
    }
    if(modbustaglistFetchDone){
      getAlarmConfigData();
      setModbustaglistFetchDone(false);
    }
    
    
    }, [modbustaglistFetchDone]);


    // Merge two array data received from ModbusTagList and AlarmConfig tables and add SrNo to array.
    function mergeArrays(arr1, arr2) {
      // Create an array to store the merged data
      const mergedArray = [];
    
      // Merge data from the first array
      arr1.forEach((item) => {
        const existingItem = mergedArray.find((mergedItem) => mergedItem.TagName === item.TagName);
        if (existingItem) {
          Object.assign(existingItem, item);
        } else {
          mergedArray.push(item);
        }
      });
    
      // Merge data from the second array
      arr2.forEach((item) => {
        const existingItem = mergedArray.find((mergedItem) => mergedItem.TagName === item.TagName);
        if (existingItem) {
          Object.assign(existingItem, item);
        } else {
          mergedArray.push(item);
        }
      });
    
      return mergedArray;
    }    
    
    if(alarmconfigFetchDone){
      //console.log(modbustaglistData);
      //console.log(alarmconfigData);
      const mergedData = mergeArrays(modbustaglistData, alarmconfigData);

      let idCounter = 1;
      const mergedDataWithSrNo = mergedData.map((item) => {
        const newItem = { ...item, SrNo: idCounter };
        idCounter++; // Increment the counter for the next item
        return newItem;
      });
      setRowsData(mergedDataWithSrNo);
      //console.log(mergedDataWithSrNo);
      setAlarmconfigFetchDone(false);
    }
    
    // Delete  Specific Row row data 
    useEffect(() =>{
      async function deleteRowData()  {
      try {

        let dashboardName = "TableView"
        //console.log(tableViewData);
        //console.log(rowTagName);
        const searchValue = rowTagName; // The value you're searching in tableView collection to get record to retrive _id field from it.
        const foundObject = tableViewData.find((item) => item.TagName === searchValue);

        if (foundObject) {
          //console.log('Object found:', foundObject._id);
          let rowId = foundObject._id;
          if (rowId === null){
            setSnackbar({ children: 'TagName value is null', severity: 'error' });
          }else{
            const response = await TableViewService.deleteRow(dashboardName, rowId);  // update when id is present in data array
            if(response){
              setSnackbar({ children: 'Tag Deleted successfully', severity: 'success' });
              setFetchData(true);
            
            }
          }

        } else {
          setSnackbar({ children: 'TagName is not present', severity: 'error' });
        }
      

      } catch (error) {
        setSnackbar({ children: "Tag deletion Failed", severity: 'error' });
        console.log(error);

      }
    };
    if(deleteData){
      deleteRowData();
      setDeleteData(false);
    };


    }, [deleteData]);
    

  const mapPageToNextCursor = React.useRef({});

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const queryOptions = React.useMemo(
    () => ({
      cursor: mapPageToNextCursor.current[paginationModel.page - 1],
      pageSize: paginationModel.pageSize,
    }),
    [paginationModel],
  );
  const { isLoading, rows, pageInfo } = useQuery(queryOptions);

  const handlePaginationModelChange = (newPaginationModel) => {
    //console.log(newPaginationModel.page);
    setOffset(newPaginationModel.page * newPaginationModel.pageSize) // Page Number
    setRowsPerPage(newPaginationModel.pageSize); //Page Size
    // Change the state to call useeffect at once to fetch data from backend
    if(fetchData === true){
        setFetchData(false);
      }else{
        setFetchData(true);
      }

    
    // We have the cursor, we can allow the page transition.
    if (
      newPaginationModel.page === 0 ||
      mapPageToNextCursor.current[newPaginationModel.page - 1]
    ) {
      setPaginationModel(newPaginationModel);
    }

  };

  React.useEffect(() => {
    if (!isLoading && pageInfo?.nextCursor) {
      // We add nextCursor when available
      mapPageToNextCursor.current[paginationModel.page] = pageInfo?.nextCursor;
    }
  }, [paginationModel.page, isLoading, pageInfo?.nextCursor]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = React.useState(
    pageInfo?.totalRowCount || 0,
  );
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState,
    );
  }, [pageInfo?.totalRowCount, setRowCountState]);


  const handleRecordAdded = (data) => {
    
    setAddRecordForm(false);
    setFetchData(true);  // reload the data again from backend
    //console.log("Record Added", data);
    
  };

  const handleDeleteYes = () => {
    setDeleteData(true);
    setDeleteDialog(false);
  };

  const handleDeleteNo = () => {
    
    setDeleteDialog(false);
  };

  const renderDeleteConfirmDialog = () => {
    if (!deleteDialog) {
      return null;
    }

    //const { newRow, oldRow } = promiseArguments;
    //const mutation = computeMutation(newRow, oldRow);
    return (
      <Dialog
        maxWidth="xs"
        //TransitionProps={{ onEntered: handleEntered }}
        open={!!deleteDialog}
      >
        <DialogTitle>Are you sure to delete selected Row?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will delete the selecetd row.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleDeleteNo}>
            No
          </Button>
          <Button onClick={handleDeleteYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };


  const handleDeleteClick = (tagName) => () => {
    setRowTagName(tagName);
    setDeleteDialog(true);
  };


  const columns = [
    { field: 'SrNo', headerName: configData[0].Description, width: configData[0].Width, headerAlign: configData[0].TextAlignment, align: configData[0].TextAlignment, headerClassName: 'theme--header', },
    { field: 'TagDescription1', headerName: configData[1].Description, width: configData[1].Width, headerAlign: configData[1].TextAlignment, align: configData[1].TextAlignment, headerClassName: 'theme--header',},
    { field: 'TagName', headerName: configData[2].Description, width: configData[2].Width, headerAlign: configData[2].TextAlignment, align: configData[2].TextAlignment, headerClassName: 'theme--header',},
    { field: 'TagDescription2', headerName: configData[3].Description, width: configData[3].Width, headerAlign: configData[3].TextAlignment, align: configData[3].TextAlignment, headerClassName: 'theme--header', },
    { field: 'TagDescription3', headerName: configData[4].Description, width: configData[4].Width, headerAlign: configData[4].TextAlignment, align: configData[4].TextAlignment, headerClassName: 'theme--header',},
    { field: 'TagDescription4', headerName: configData[5].Description, width: configData[5].Width, headerAlign: configData[5].TextAlignment, align: configData[5].TextAlignment, headerClassName: 'theme--header', },
    { field: 'ProcessValue', headerName: configData[6].Description, width: configData[6].Width, headerAlign: configData[6].TextAlignment, align: configData[6].TextAlignment, headerClassName: 'theme--header', },
    { field: 'TagUnit', headerName: configData[7].Description, width: configData[7].Width, headerAlign: configData[7].TextAlignment, align: configData[7].TextAlignment, headerClassName: 'theme--header', },
    { field: 'LoLoSP', headerName: configData[8].Description, width: configData[8].Width, headerAlign: configData[8].TextAlignment, align: configData[8].TextAlignment, headerClassName: 'theme--header', },
    { field: 'LowSP', headerName: configData[9].Description, width: configData[9].Width, headerAlign: configData[9].TextAlignment, align: configData[9].TextAlignment, headerClassName: 'theme--header', },
    { field: 'HighSP', headerName: configData[10].Description, width: configData[10].Width, headerAlign: configData[10].TextAlignment, align: configData[10].TextAlignment, headerClassName: 'theme--header', },
    { field: 'HiHiSP', headerName: configData[11].Description, width: configData[11].Width, headerAlign: configData[11].TextAlignment, align: configData[11].TextAlignment, headerClassName: 'theme--header', },
    { field: 'PresentAlarmStatus', headerName: configData[12].Description, width: configData[12].Width, headerAlign: configData[12].TextAlignment, align: configData[12].TextAlignment, headerClassName: 'theme--header', },
  
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        const tagName = params.row.TagName;


        return [
          
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(tagName)}
            color="inherit"
          />,
        ];
      },
    },
  ];

 // const [columnStates, setColumnStates]= useState([]);
 
  const columnStates = columns.map(() => true); // Initialize all columns as enabled
  if(configData[0].Enable === "Enabled"){
    columnStates[0] = true;
  }else{
    columnStates[0] = false;
  }
  if(configData[1].Enable === "Enabled"){
    columnStates[1] = true;
  }else{
    columnStates[1] = false;
  }
  if(configData[2].Enable === "Enabled"){
    columnStates[2] = true;
  }else{
    columnStates[2] = false;
  }
  if(configData[3].Enable === "Enabled"){
    columnStates[3] = true;
  }else{
    columnStates[3] = false;
  }
  if(configData[4].Enable === "Enabled"){
    columnStates[4] = true;
  }else{
    columnStates[4] = false;
  }
  if(configData[5].Enable === "Enabled"){
    columnStates[5] = true;
  }else{
    columnStates[5] = false;
  }
  if(configData[6].Enable === "Enabled"){
    columnStates[6] = true;
  }else{
    columnStates[6] = false;
  }
  if(configData[7].Enable === "Enabled"){
    columnStates[7] = true;
  }else{
    columnStates[7] = false;
  }
  if(configData[8].Enable === "Enabled"){
    columnStates[8] = true;
  }else{
    columnStates[8] = false;
  }
  if(configData[9].Enable === "Enabled"){
    columnStates[9] = true;
  }else{
    columnStates[9] = false;
  }
  if(configData[10].Enable === "Enabled"){
    columnStates[10] = true;
  }else{
    columnStates[10] = false;
  }
  if(configData[11].Enable === "Enabled"){
    columnStates[11] = true;
  }else{
    columnStates[11] = false;
  }
  if(configData[12].Enable === "Enabled"){
    columnStates[12] = true;
  }else{
    columnStates[12] = false;
  }
 
  return (
    <div >

      {renderDeleteConfirmDialog()}
      <Paper
        sx={{
          //height: 400,
          width: '100%',
          overflow: 'hidden',
          '& .theme--header':{backgroundColor: configData[13].Color, color: configData[14].Color, 
            fontSize: parseInt(configData[15].FontSize), fontWeight: configData[16].FontWeight, 
            border: configData[17].BorderStyle, whiteSpace: 'normal', wordWrap: 'break-word',},
          '& .theme--row': { 
            backgroundColor: configData[18].Color,
            color: configData[19].Color,
            fontSize:parseInt(configData[20].FontSize),
            fontWeight: configData[21].FontWeight,
            border: configData[22].BorderStyle,
            //border: '1px solid #d4d4d4',
            //borderRight: 1,
            overflowWrap: 'break-word',
          },
          '& .theme--verborder': {
            border: configData[17].BorderStyle,
          },
          '& .theme--lolo': {
            color: configData[23].Color,
            //fontSize:15,
            //fontWeight: 'bold',
            //border: '1px solid #d4d4d4',
          },
          '& .theme--lo': {
            color: configData[24].Color,
            //fontSize:15,
            //fontWeight: 'bold',
            //border: '1px solid #d4d4d4',
          },
          '& .theme--hi': {
            color: configData[25].Color,
            //fontSize:15,
            //fontWeight: 'bold',
            //border: '1px solid #d4d4d4',
          },
          '& .theme--hihi': {
            color: configData[26].Color,
            //fontSize:15,
            //fontWeight: 'bold',
            //border: '1px solid #d4d4d4',
            

          },
          '& .theme--noalarm': {
            color: configData[27].Color,
            //fontSize:15,
            //fontWeight: 'bold',
            //border: '1px solid #d4d4d4',
            

          },
        }}
        elevation={24}
      >
        <Box sx={{ height: 600 }}>
          <DataGrid
            pageSizeOptions={[5, 10, 15, 20, 25]}
            //rowCount={rowCountState}
            rowCount={totalRows}
            paginationMode="server"
            onPaginationModelChange={handlePaginationModelChange}
            paginationModel={paginationModel}
            loading={isLoading}

            rows={rowsData}
            getRowId={(rowsData) => rowsData._id} // This line will set id of the datagrid row = _id of the mongodb database, otherwisw error can occured.
            //columns={columns}
            columns={columns.filter((column, index) => columnStates[index])}
            disableRowSelectionOnClick  // disable row selection

            getCellClassName={(params) => {
                if (params.field === 'TagName' || params.value == null) {
                  return '';
                }
                if (params.field === 'ProcessValue' && params.value <= params.row.LowLowSP){
                  return 'theme--lolo';
                }
                if (params.field === 'ProcessValue' && params.value <= params.row.LowSP && params.value > params.row.LowLowSP ){
                  return 'theme--lo';
                }
                if (params.field === 'ProcessValue' && params.value >= params.row.HiSP && params.value < params.row.HiHiSP){
                  return 'theme--hi';
                }
                if (params.field === 'ProcessValue' && params.value >= params.row.HiHiSP){
                  return 'theme--hihi';
                }
                if (params.field === 'ProcessValue' && params.value > params.row.LowSP && params.value < params.row.HiSP ){
                  return 'theme--noalarm';
                }
                if (params.field !== 'verborder' ){
                  return 'theme--verborder';
                }
                
      
              }}

            //getHeaderClassName={(params) =>{return 'theme--header';}}
            getRowClassName={(params) => {return 'theme--row';}}
            //{...data}
            sx={{
              "& .MuiDataGrid-row:hover": {
                backgroundColor: configData[28].Color
                // on hover, background color of thr row
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
                // wrap the column header text
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: 'normal !important', /* Important to override other styles */
                lineHeight: "normal",
                wordWrap: 'break-word',
                // wrap the  cell text
              },
            }}

            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setAddRecordForm},
            }}
          />
        </Box>

        {addRecordForm && (
        <DashboardAdd open= {addRecordForm} close = {handleRecordAdded} />
        )}

        {!!snackbar && (
          <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }}  open onClose={handleCloseSnackbar} autoHideDuration={4000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}

      </Paper>
    </div>
  );
}