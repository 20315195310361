import * as React from 'react';
import {useState, useEffect} from "react";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import WelcomeScreen from "../assets/Welcome.gif";
import WelcomeMedia from "../assets/Automation1.gif";
import AuthService from "../services/auth.service";
import ConfigurationViewService from '../services/configurationview.service.js';

export default function BackgroundImage() {


//Refresh Page every seconds
const [paginationFlag, setPaginationFlag] = useState(false);

useEffect(() => {
  const interval = setInterval(() => {

    if(paginationFlag === false){
      setPaginationFlag(true);
    }else{
      setPaginationFlag(true);
    }
    },1*500);
    return () => clearInterval(interval);
    
  }, [paginationFlag]);

  // Get the welocome screen from backend 
  const [welcomeImage, setwelcomeImage] = useState(null);
  const handleWelcomeImageDownload= async (Welcome) => {
    try {

      const response = await AuthService.downloadProfilePhoto(Welcome);

      //console.log(response.data);
      const base64 = btoa(new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte),
         ''));
         setwelcomeImage('data:image/jpeg/gif; base64,' + base64);
     
    } catch (error) {
      console.log(error);
      
    }
  }

  useEffect(() => {handleWelcomeImageDownload('Welcome');}, []);

  const initialLayoutConfigData = [{

    SrNo:'1',
    ComponentName:'TopBar',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'TopBar',
    backgroundColor: '#00aeef',
    fontWeight: 'normal',
    fontSize: '16 px',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '0vh',
    minWidth: '0vw',
    maxHeight: '1vh',
    maxWidth: '1vw',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'2',
    ComponentName:'Logo',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'Logo',
    backgroundColor: '#00aeef',
    fontWeight: 'normal',
    fontSize: '16 px',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '50',
    minWidth: '156',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'3',
    ComponentName:'ProjectDescription',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'ProjectDescription',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'4',
    ComponentName:'ComFailIcon',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'ComFailIcon',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'5',
    ComponentName:'AlarmIcon',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'AlarmIcon',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'6',
    ComponentName:'UserIcon',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'UserIcon',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'7',
    ComponentName:'CompanyName',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'CompanyName',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  },
  {

    SrNo:'8',
    ComponentName:'BackgroundImage',
    ComponentStatus: 'Enabled',
    ComponentDescription: 'BackgroundImage',
    backgroundColor: '1976d2',
    fontWeight: '700',
    fontSize: '22',
    textAlign: 'center',
    textColor: 'white',
    overflow: 'auto',
    minHeight: '5vh',
    minWidth: '50vw',
    maxHeight: '100',
    maxWidth: '256',
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
         
  }];
  const [layoutConfigData, setLayoutConfigData] = useState(initialLayoutConfigData);
  
  //Get configuration of the Layout
  useEffect(() =>{
    async function getColumnData()  {
    try {

      let dashboardName = "LayoutConfig";
      let columnName = "WidgetName";
      let columnValue = "LayoutConfig";
      const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue );

      //console.log(response.data[0].backgroundColor);

      setLayoutConfigData(response.data);
      
    } catch (error) {
      console.log(error);

    }
  }
  getColumnData();



  }, []);


  return (
    <React.Fragment>
      
        {/* Project Welcome Screen */}
        <Paper
          sx={{
            ml:parseInt(layoutConfigData[7].ml),
            mr:parseInt(layoutConfigData[7].mr),
            mt:parseInt(layoutConfigData[7].mt),
            mb:parseInt(layoutConfigData[7].mb),
            p: 0,
            //margin: 'auto',
            minWidth: `${parseInt(layoutConfigData[7].minWidth)}%`,
            maxWidth: `${parseInt(layoutConfigData[7].minWidth)}%`,
            minHeight: `${parseInt(layoutConfigData[7].minHeight)}%`,
            maxHeight: `${parseInt(layoutConfigData[7].minHeight)}%`,
            overflow: 'hidden',

            //flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
          elevation={24}
        >
              <CardMedia
                component={paginationFlag? 'img':'img'}
                //className={classes.media}
                image={paginationFlag? welcomeImage: WelcomeMedia}
                //image={WelcomeScreen}
                autoPlay
                muted
                //loop
                //playsinline
                //controls
              />

        </Paper>

    </React.Fragment>
  );
}


