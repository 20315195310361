import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, GridEditInputCell} from '@mui/x-data-grid';
import ConfigurationViewService from '../../services/configurationview.service';
import ParameterEdit from './ParameterEdit';

import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Alert from '@mui/material/Alert';


// Add row to the existing rows
function EditToolbar(props) {
  const { setAddRecordForm } = props;

 
  const handleClick = () => {
    //setAddRecordForm(true);
  };


  return (
    <GridToolbarContainer>
        <Button color="primary"   onClick={handleClick} >
        Parameter List
        </Button>

    </GridToolbarContainer>
  );
}

// Check blank values inside the row data
function hasBlankOrNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === '') {
      return true; // Found a blank or null value
    }

    if (typeof value === 'object' && hasBlankOrNullValues(value)) {
      return true; // Recursively check nested objects
    }
  }

  return false; // No blank or null values found
}

// Check for the values are within min max range or not
function isWithinMinMaxRanges(data, ranges, exclude) {
  for (const key in data) {
    // Skip checking for values in the exclude array
    if (exclude.includes(key)) {
      continue;
    }

    if (key in ranges) {
      const { min, max } = ranges[key];
      if (data[key] < min || data[key] > max) {
        return false; // Value is outside the specified range
      }
    }
  }
  return true; // All values are within their respective ranges
}


export default function ModbusTagList() {

  
    const [fetchData, setFetchData] = React.useState(true); // Fetch all row data enable bit
    const [rowsData, setRowsData] = React.useState([]); // All Row Data
    const [blockNameList, setBlockNameList] = React.useState([]) // retrived BlockName lsit From received data

    const [rowModesModel, setRowModesModel] = React.useState({}); 

    const [saveData, setSaveData] = React.useState(false);  // Save specific Row Data
    const [rowData, setRowData] = React.useState([]); // Specific Row Data

    const[deleteDialog, setDeleteDialog] = React.useState(false); // Delete Dialog bit
    const noButtonRef = React.useRef(null); 
    const [rowId, setRowId] = React.useState(); // Row Id refernace to delete the record
    const[deleteData, setDeleteData] = React.useState(false); //Delete row bit
    const [snackbar, setSnackbar] = React.useState(null); // if delete Success then set to success otherwise will set to fail

    const handleCloseSnackbar = () => setSnackbar(null);

    const [addRecordForm, setAddRecordForm] = React.useState(false)
    const [editRecordForm, setEditRecordForm] = React.useState(false)
    const [editRowData, setEditRowData] = React.useState(false)
    
    // Get  All row data from modbus queries
    useEffect(() =>{
    async function getAllRowsData()  {
    try {

        let dashboardName = "ModbusConfig"
        //const offset = page * rowsPerPage;
        //console.log(page);
        //console.log(offset);
        //console.log(rowsPerPage);
        setRowsData([]);
        const response = await ConfigurationViewService.getAllRowsData(dashboardName);
        if (response){

        setBlockNameList(response.data.map((item) => item.BlockName)); 
        }
        
        
    } catch (error) {
        console.log(error);

    }
    }
    if(fetchData){
    getAllRowsData();
    setFetchData(false);

    };


    }, [fetchData]);

    // Get  All row data from modbustaglist
    useEffect(() =>{
    async function getAllRowsData()  {
    try {

        let dashboardName = "ModbusTagList"
        //const offset = page * rowsPerPage;
        //console.log(page);
        //console.log(offset);
        //console.log(rowsPerPage);
        setRowsData([]);
        const response = await ConfigurationViewService.getAllRowsData(dashboardName);
        //console.log(response.data);
        if (response){
        setRowsData(response.data);
        
        }
        
        
    } catch (error) {
        console.log(error);

    }
    }
    if(fetchData){
    getAllRowsData();
    setFetchData(false);

    };


    }, [fetchData]);

    // Get min max data address for selected BlockName as well as taglist curresponds to selected BlockName
    useEffect(() => {
    async function tagListWithMinMaxDataAdd(){
        try{
        //This need to implement in future

        }catch (error) {
        console.log(error)

        }
    };
    if(saveData){
        tagListWithMinMaxDataAdd();

    }
    }, [saveData])

    // Save  Specific Row row data or Add new row to collection if record not found
    useEffect(() =>{
    async function saveRowData()  {
    try {

        let dashboardName = "ModbusTagList"
        //console.log(rowsData);
        //console.log(rowData);


        // Check for value is within range or not
        const minMaxRanges = {
        DataAdd: { min: 1, max: 10000 },
        DisplayWidth: { min: 1, max: 8 },
        ExponentDigit: { min: 0, max: 5 },
        Offset: { min: 0, max: 99 },
        ScaleFactor: { min: 0.1, max: 9.9 },
        StringTagLength: { min: 1, max: 20 }, 
        };
        const valuesToExclude = ['TagStatus', 'BlockName', 'TagName', 'DataType', 'Scaling', 'Padding', 'TagDescription1', 'TagDescription2', 'TagDescription3', 'TagDescription4',
        'TagUnit',  'RawValue', 'ProcessValue', 'TagType', 'OnLabel', 'OffLabel', 'IsNew', '_id'];

        if (isWithinMinMaxRanges(rowData, minMaxRanges, valuesToExclude)) {
        //console.log('All values are within their respective ranges.');
        

        // If TagType is Systemtag then no need to check for blank values. Incase of System tag, block name can be blank  
        if (rowData.TagStatus === "SystemTag") {
        const response = await ConfigurationViewService.updateRowData(dashboardName, rowData);  // update when id is present in data array
        if(response){
            setSaveData(false);
            setFetchData(true);  // reload the data again from backend

            setSnackbar({ children: 'Updated Modbus Query successfully', severity: 'success' });
        }


        }else{
        // Validate record for blank values
        if (hasBlankOrNullValues(rowData)) {
            setSnackbar({ children: 'Modbus Query contains blank value, please coreect it.', severity: 'error' });
            //setSaveData(false);
            //setFetchData(true);  // reload the data again from backend
        } else { 
            const response = await ConfigurationViewService.updateRowData(dashboardName, rowData);  // update when id is present in data array
            if(response){
            setSaveData(false);
            setFetchData(true);  // reload the data again from backend

            setSnackbar({ children: 'Updated Modbus Query successfully', severity: 'success' });
            }

            //console.log('JSON object does not contain blank or null values.');
        }

        }

        }else {
        setSnackbar({ children: 'One or more values are outside their respective ranges.', severity: 'error' });
        //console.log('One or more values are outside their respective ranges.');
        }


    } catch (error) {
        console.log(error);
        setSnackbar({ children: 'eror occured while updating or adding modbus query', severity: 'error' });

    }
    };
    if(saveData){
    saveRowData();
    setSaveData(false);
    };


    }, [saveData]);


    // Delete  Specific Row row data 
    useEffect(() =>{
    async function deleteRowData()  {
    try {

        let dashboardName = "ModbusTagList"
        //console.log(rowsData);
        //console.log(rowData);
        if (rowId === null){
        console.log("Provide Proper Id to delete record")
        }else{

        const response = await ConfigurationViewService.deleteRow(dashboardName, rowId);  // update when id is present in data array
        if(response){
            setFetchData(true);  // reload the data again from backend
            //console.log(snackbar);
            setSnackbar({ children: 'Deleted modbus query successfully', severity: 'success' });
        }

        }


    } catch (error) {
        setSnackbar({ children: "Modbus query deletion Failed", severity: 'error' });
        console.log(error);

    }
    };
    if(deleteData){
    deleteRowData();
    setDeleteData(false);
    };


    }, [deleteData]);


    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
        }
    };

    const handleRecordEdited = (data) => {
        
        setEditRecordForm(false);
        setFetchData(true);  // reload the data again from backend
        //console.log("Record Added", data);
        
    };

    const handleEditClick = (params) => () => {
        //setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });

    };

    const handleRowDoubleClick = (params, event) => {
        //console.log(params);
        setEditRowData(params);
        setEditRecordForm(true);
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        
    };

    const handleDeleteYes = () => {
        setDeleteData(true);
        setDeleteDialog(false);
    };

    const handleDeleteNo = () => {
        
        setDeleteDialog(false);
    };

    const renderDeleteConfirmDialog = () => {
        if (!deleteDialog) {
        return null;
        }

        //const { newRow, oldRow } = promiseArguments;
        //const mutation = computeMutation(newRow, oldRow);
        return (
        <Dialog
            maxWidth="xs"
            //TransitionProps={{ onEntered: handleEntered }}
            open={!!deleteDialog}
        >
            <DialogTitle>Are you sure to delete selected Row?</DialogTitle>
            <DialogContent dividers>
            {`Pressing 'Yes' will delete the selecetd row.`}
            </DialogContent>
            <DialogActions>
            <Button ref={noButtonRef} onClick={handleDeleteNo}>
                No
            </Button>
            <Button onClick={handleDeleteYes}>Yes</Button>
            </DialogActions>
        </Dialog>
        );
    };


    const handleDeleteClick = (id) => () => {
        setRowId(id);
        setDeleteDialog(true);
        
        //setRowsData(rowsData.filter((row) => row._id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rowsData.find((row) => row._id === id);
        if (editedRow.isNew) {
        setRowsData(rowsData.filter((row) => row._id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRowsData(rowsData.map((row) => (row._id === newRow._id ? updatedRow : row)));
        setRowData(newRow);
        setSaveData(true);

        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRecordAdded = (data) => {
        
        setAddRecordForm(false);
        setFetchData(true);  // reload the data again from backend
        //console.log("Record Added", data);
        
    };

    

  
    const columns = [

        { field: 'TagStatus', headerName: 'Tag Status', type:'singleSelect', valueOptions: ['SystemTag', 'DeviceTag'], width: 120, align: 'center', headerAlign: 'center', },
        { field: 'BlockName', headerName: 'Block Name', type:'singleSelect', valueOptions:blockNameList, width: 200, align: 'center', headerAlign: 'center', },
        //{ field: 'ProcessValue', headerName: 'Process Value',  type: 'number',  width: 120, align: 'center', headerAlign: 'center', },
        { field: 'TagName', headerName: 'Tag Name',  type: 'string',  width: 200, align: 'center', headerAlign: 'center', },
        //{ field: 'DataAdd', headerName: 'Data Address',  type: 'number',  width: 120, align: 'center', headerAlign: 'center', 
        //renderEditCell: (params) => (<GridEditInputCell{...params}inputProps={{min: 1, max: 10000,}}/>), },
        //{ field: 'DataType', headerName: 'Data Type',  type: 'singleSelect', valueOptions: ['Integer', 'INTx10', 'Float', 'SwapFloat'],  width: 120, align: 'center', headerAlign: 'center', },
        //{ field: 'DisplayWidth', headerName: 'Display Width',  type: 'number',  width: 120, align: 'center', headerAlign: 'center', editable: true,
        //renderEditCell: (params) => (<GridEditInputCell{...params}inputProps={{min: 1, max: 8,}}/>), },
        //{ field: 'ExponentDigit', headerName: 'Exponent Digit',  type: 'number',  width: 120, align: 'center', headerAlign: 'center', editable: true,
        //renderEditCell: (params) => (<GridEditInputCell{...params}inputProps={{min: 0, max: 5,}}/>), },
        //{ field: 'Padding', headerName: 'Zero Padding',  type: 'singleSelect', valueOptions: ['Enabled', 'Disabled'],  width: 120, align: 'center', headerAlign: 'center', editable: true, },
        
        //{ field: 'Scaling', headerName: 'Scaling',  type: 'singleSelect', valueOptions: ['Enabled', 'Disabled'], width: 120, align: 'center', headerAlign: 'center', editable: true, },
        //{ field: 'ScaleFactor', headerName: 'Scale Factor',  type: 'number',  width: 120, align: 'center', headerAlign: 'center', editable: true, 
        //renderEditCell: (params) => (<GridEditInputCell{...params}inputProps={{min: 0.1, max: 9.9,}}/>),},
        //{ field: 'Offset', headerName: 'Offset',  type: 'number',  width: 120, align: 'center', headerAlign: 'center', editable: true,
        //renderEditCell: (params) => (<GridEditInputCell{...params}inputProps={{min: 0, max: 999,}}/>), },
    
        { field: 'TagDescription1', headerName: 'Tag Description1',  type: 'string',  width: 200, align: 'center', headerAlign: 'center', editable: true,
        renderCell: (params) => (
            <div style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>
            {params.value}
            </div>
        ),},
        { field: 'TagDescription2', headerName: 'Tag Description2',  type: 'string',  width: 200, align: 'center', headerAlign: 'center', editable: true, },
        { field: 'TagDescription3', headerName: 'Tag Description3',  type: 'string',  width: 200, align: 'center', headerAlign: 'center', editable: true, },
        { field: 'TagDescription4', headerName: 'Tag Description4',  type: 'string',  width: 200, align: 'center', headerAlign: 'center', editable: true, },
        { field: 'TagUnit', headerName: 'Tag Unit',  type: 'string',  width: 120, align: 'center', headerAlign: 'center', editable: true, },
        //{ field: 'RawValue', headerName: 'Raw Value',  type: 'number',  width: 120, align: 'center', headerAlign: 'center', },
        
        //{ field: 'TagType', headerName: 'Tag Type', type:'singleSelect', valueOptions: ['Analog', 'Digital', 'String'], width: 120, align: 'center', headerAlign: 'center',  editable: true, },
        //{ field: 'OnLabel', headerName: 'ON Label',  type: 'string',  width: 120, align: 'center', headerAlign: 'center',  editable: true, },
        //{ field: 'OffLabel', headerName: 'OFF Label',  type: 'string',  width: 120, align: 'center', headerAlign: 'center',  editable: true, },
        //{ field: 'StringTagLength', headerName: 'String Tag Length',  type: 'number',  width: 120, align: 'center', headerAlign: 'center',  editable: true,
        //renderEditCell: (params) => (<GridEditInputCell{...params}inputProps={{min: 1, max: 20,}}/>), },

        {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ params }) => {
            
            return [
            <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(params)}
                color="inherit"
            />,
            ];
        },
        },
    ];

    const getRowHeight = (params) => {

        if (!params || !params.model || !params.model.Options) {
        return 40; // Default height if params or row or Options is undefined
        }
        const lineHeight = 10; // Adjust this value as needed
        const padding = 50; // Adjust this value as needed
        const numItems = params.model.Options ? params.model.Options.length : 0; // Check if 'Options' property exists
        //console.log(lineHeight * numItems + padding)
        return lineHeight * numItems + padding;
    };
  

    return (
        <div>
        {renderDeleteConfirmDialog()}
        
        
        <Paper
        sx={{
            p: 1,
            margin: 'auto',
            width: '100%',
            overflow: 'hidden',
            //flexGrow: 1,
            backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        }}
        elevation={24}
        >
        
        <Box sx={{ height: 600 }}>
        
        <DataGrid
            rows={rowsData}
            getRowHeight={getRowHeight} // Dynamically calculate row height
            getRowId={(rowsData) => rowsData._id} // This line will set id of the datagrid row = _id of the mongodb database, otherwisw error can occured.
            columns={columns}
            disableRowSelectionOnClick  // disable row selection
            hideFooterPagination = {true}
            onRowDoubleClick={handleRowDoubleClick} // Double click event handler
            //pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
            //editMode="row"
            //rowModesModel={rowModesModel}
            //onRowModesModelChange={handleRowModesModelChange}
            //onRowEditStop={handleRowEditStop}
            //processRowUpdate={processRowUpdate}
            slots={{
            toolbar: EditToolbar,
            }}
            slotProps={{
            toolbar: {setAddRecordForm},
            }}
        />
        </Box>

        {editRecordForm && (
        <ParameterEdit params = {editRowData} open= {editRecordForm} close = {handleRecordEdited} />
        )}

        {!!snackbar && (
            <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }}  open onClose={handleCloseSnackbar} autoHideDuration={4000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
        )}
        
        
        </Paper>
        
        </div>
        
    );
}