import * as React from 'react';
import {useState, useEffect, useCallback} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../slices/message";
import { selectedProject } from "../slices/auth";

export default function ProjectList({selctedProjectName}) {
  const { user } = useSelector((state) => state.auth);
  
  const [projectList, setProjectList] = React.useState(user.projectlist);
  const [projectname, setProjectname] = React.useState("");
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);


  //setProjectList(user.projectlist);
  const handleChange = (event) => {
    
    setProjectname("");
    let email = user.email;
    let selectedproject = event.target.value ;
    dispatch(selectedProject({ email, selectedproject }))
      .unwrap()
      .then((res) => {
        //console.log(res.selectedproject);
        setProjectname(res.selectedproject);
        selctedProjectName(res.selectedproject);
        window.location.reload();
                
      })
      .catch(() => {
        setProjectname("");
        
      });
  };

  const handleOpen = (event) => {
    
    setProjectList(user.projectlist);
    //console.log(projectList[3])
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
        <InputLabel id="autowidth-label">Project Name</InputLabel>
        <Select
          labelId="autowidth-label"
          id="demo-simple-select-autowidth"
          defaultValue={user.selectedproject}
          onOpen ={handleOpen}
          onChange={handleChange}
          autoWidth
          label="Project Name"
          sx={{ color: "inherit", fontSize: '22px' }}
          
        >
           {projectList?.map((value, index) => {
          return <MenuItem key = {index} value={value}>{value}</MenuItem>;
        })}

          {/*<MenuItem value={1}> Radix </MenuItem>
          <MenuItem value={10}> SBD Oven control System</MenuItem>
          <MenuItem value={21}> Conveyor System </MenuItem>
          <MenuItem value={22}>Tub control  & Monitoring System</MenuItem>
          */}
        </Select>
      </FormControl> 
    </div>

    

  );
}


