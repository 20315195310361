import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { TextField, Button, FormControl, makeStyles } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import DashboardViewService from '../../services/dashboardview.service.js';
import AuditTrailViewService from '../../services/audittrailview.service.js';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// Function to create auditTrailData
const createAuditTrailData = (oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus) => {
  
  let auditTrailData = [];
  // Get the keys of the oldValues
  const keys1 = Object.keys(oldValues);
  //console.log(keys1)
  //console.log(Object.keys(newValues))
  //console.log(keys1.length, Object.keys(newValues).length)
  // Check if the number of keys is the same
  if (keys1.length !== Object.keys(newValues).length) {
    return auditTrailData;
  }else{
    // Iterate over the keys of the oldValues
    for (let key of keys1) {
      //console.log(key, oldValues[key], newValues[key]);
      
      let activityStatus = activity;
      // modify Activity if event is enabled else activity will be deafualt value received form audittrail config collection
      if(eventStatus === "Enabled"){
        if(key === "GroupName"){
          activityStatus = `Created new user group - ${tagName}.`;
          auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
        }
      } else{
        auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
      }  
      
    }
    return auditTrailData;
  }

  

};

function SaveChangesDialog(props) {
  const [open, setOpen] = useState(false);
  
  const [auditTrailData, setAuditTrailData] = React.useState([]);  // AuditTrail Data

  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);
  const [password, setPassword] = useState('');
  const[pwdinterlock, setPwdInterlock] = useState("Disabled");

  const handleClickOpen = () => {
    if(((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === "--")) || ((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === ""))){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
    setOpen(true);
    }
    
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRemarkBlnkMsg(false);
  };

  const handleSuccessClose = () => {
    setOpenSuccessDialog(false);
    props.success(true);
  };

  const handleFildClose = () => {
    setOpenFaildialog(false);
  };
  

  const [message, setMessage] = useState();

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFaildialog, setOpenFaildialog] = useState(false);

  const [saveData, setSaveData] = useState(false);
  const handleClickSaveChanges = () => { 
    let oldValues = props.oldValues;
    let newValues = props.newValues;
    let tagName = props.tagName;
    let activity = props.activity;
    let activityType = 'Add User Group';
    let userName = props.email;
    let role = props.role;
    let remark = props.auditTrailRemark;
    let eventStatus = props.rowData.EventStatus;
    //console.log(oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus)
    let auditTrailData = createAuditTrailData(oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus )
    //console.log(auditTrailData)
    setAuditTrailData(auditTrailData)
    setSaveData(true);
  };



const [groupNameList, setGroupNameList] = React.useState([]);
// Get GroupName list
 useEffect(() =>{
  async function getAllColumnData()  {
  try {
    let dashboardName = "DashboardGroupList"
    let columnName = "DashboardType"
    let columnValue = "DashboardView";

    const response = await DashboardViewService.getColumnData(dashboardName, columnName, columnValue);
    if (response){
      let GroupNames = response.data.map((item) => item.GroupName);
      setGroupNameList([...new Set(GroupNames)]); 
    }

  } catch (error) {
    console.log(error);

  }
};
  getAllColumnData();

}, []);


  //  Add new row to GrouptList
  useEffect(() =>{
    async function saveRowData()  {
    try {

      let dashboardName = "DashboardGroupList"
      //let rowData = props.rowData; // It should inclue all three field ie ProjectName, DashboardType, GroupName
      let rowData = {...props.rowData, PWDInterlock: props.rowData.PWDInterlock, PWD: password};
      // Tag name & DataField Duplcation check for new tag
      if (groupNameList.includes(rowData.GroupName)) {
        setOpenFaildialog(true);
        setMessage("Group Name already exist. Choose different Group name to avoid duplication.");
        setSaveData(false);
        setOpen(false); // Close "Save changes" window

      }else{
        // Blank value check for the  one of the form field
        if (rowData.GroupName === '') {
          setOpenFaildialog(true);
          setMessage("Group Name field is blank, please correct it.");
          setSaveData(false);
          setOpen(false); // Close "Save changes" window

        } else {
            const response = await DashboardViewService.createRow(dashboardName, rowData, auditTrailData);  // create new record if id is not present in array data ie existing data
            if(response){
              setOpenSuccessDialog(true);
              setMessage("Record Added Successfully, Reload the application to get new widget name list.");
              setSaveData(false);
              setOpen(false); // Close "Save changes" window
            }
        }
      }

    } catch (error) {
      setOpenFaildialog(true);
      setMessage("Error While Adding Record, Please very all parameters entered in form");
      setOpen(false); // Close "Save changes" window
      console.log(error);


    }
  };
  if(saveData){
    saveRowData();
    setSaveData(false);
  };


  }, [saveData]);

 
  

  return (
    
    <div>
      <Button variant="outlined" startIcon ={<SaveIcon/>} sx={{mt:1}} onClick={handleClickOpen}>SAVE CHANGES </Button>

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Open Dialog to ask surity to edit user's data, with or withoout password */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>SAVE CHANGES</DialogTitle>
        <Box sx={{width: 400 }}>
        <DialogContent dividers>
          <DialogContentText>
            Are you sure to add new group.
          </DialogContentText>
          
        </DialogContent>

        {props.rowData.PWDInterlock === 'Enabled' && (<DialogContent dividers>
          <DialogContentText>
            Enter the Password to add new table.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          </DialogContent>
        )}

        </Box>
        <DialogActions>
          <Button onClick={handleClickSaveChanges}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Success dialog after upgradtion of teh user info. */}
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessClose}
       
      >
        <DialogTitle > success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Save Changes Failed dialog /Wrong Password */}
      <Dialog
        open={openFaildialog}
        onClose={handleFildClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFildClose}>Close</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}


export default function AlarmGroupListAdd ({open, close}) {
  const { user } = useSelector((state) => state.auth);
  const projectName = user.selectedproject;
  const dashboardType = 'DashboardView';
  const [widgetName, setWidgetname] = useState("")
  //console.log(ProjectName, DashboardType);


  const initialAlarmConfigData = {
    //id: null,
    ProjectName: projectName ,
    DashboardType: dashboardType,
    GroupName: "",
    GroupDescription: "",

    email: user.email,
    role: user.role,  
    RemarkStatus : "Disabled",
    EventStatus:"Disabled",
    PWDInterlock:"Disabled",
            
  };

  const [eventDescription, setEventDescription] = useState("--");
  const [auditTrailRemark, setAuditTrailRemark] = useState("--");
  // parameter list for audit trail log
  const oldValues = {GroupName: "", GroupDescription:"",};

  const [newValues, setNewValues] = useState(oldValues);

  const [tableListData, setTableListData] = React.useState(initialAlarmConfigData);

  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Add Dashboard Group';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setTableListData({...tableListData, EventStatus: response.data[0].EnableEvent, PWDInterlock: response.data[0].EnablePwdInterlock, RemarkStatus: response.data[0].EnableRemark});

        setEventDescription(response.data[0].DefaultEventDescription);
        // If remark field is disabled then set default remark vlaue to currrent Remark value
        if(response.data[0].EnableRemark === "Disabled"){
          setAuditTrailRemark(response.data[0].DefaultEventRemark);
        }
        
      }
  
    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);

 
  const handleClose = () => {
    //console.log("Close Buton Pressed");
    close() // Close the alarm add main window
  };

  const handleRecordSaveSuccess = (data) =>{
    close(data)
  }

  const handleTextFieldChange = (event) => {

    const {name, value} = event.target;
    setTableListData({...tableListData, [name]:value});
    setNewValues ({...newValues, [name] :value});

    setWidgetname(event.target.value);
  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    if(tableListData.RemarkStatus === "Enabled"){
      setAuditTrailRemark(value);

    }
  };

  return (
    <div>
      
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            maxWidth: '1000px', // Change this value as needed
          },
        }}
      >
        {/* Hnadle multiple functions on single click ie edit switch set to false and close the MyAccount Dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose();  }}>
          Add new group to the List.
        </BootstrapDialogTitle>

        {/*Group Name setting */}
        <DialogContent dividers>
          

          {/* ProjectName */}
          <TextField
            autoFocus
            margin="dense"
            label="ProjectName"
            type="text"
            fullWidth
            variant="standard"
            name="ProjectName"
            value={projectName}
            onChange={handleTextFieldChange}
            disabled={true}
          />

          {/* DashboardType */}
          <TextField
            autoFocus
            margin="dense"
            label="DashboardType"
            type="text"
            fullWidth
            variant="standard"
            name="DashboardType"
            value={dashboardType}
            onChange={handleTextFieldChange}
            disabled={true}
          />

          {/* GroupName */}
          <TextField
            autoFocus
            margin="dense"
            label="Group Name"
            type="text"
            fullWidth
            variant="standard"
            name="GroupName"
            value={tableListData.GroupName}
            onChange={handleTextFieldChange}
            disabled={false}
          />


          {/* GroupDescription */}
          <TextField
            autoFocus
            margin="dense"
            label="Group Description"
            type="text"
            fullWidth
            variant="standard"
            name="GroupDescription"
            value={tableListData.GroupDescription}
            onChange={handleTextFieldChange}
            disabled={false}
          />

        </DialogContent>

         {/*Remark */}
         {tableListData.RemarkStatus === 'Enabled' && (<DialogContent 
        dividers
        >
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 400 }}>
          Enter remark/reason 
          </Typography>

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            name="Remark"
            placeholder="Enter Remark"
            multiline
            rows={2}
            style={{ width: 550 }}
            onChange={handleRemarkChange}
            //defaultValue="Default Value"
          />

        </DialogContent>
        )}


        <DialogActions>
          <SaveChangesDialog
          projectName = {projectName}
          dashboardType = {dashboardType}
          widgetName = {widgetName}
          rowData = {tableListData}

          email = {user.email}
          role = {user.role}
          oldValues = {oldValues}
          newValues = {newValues}
          activity = {eventDescription}
          tagName = {tableListData.GroupName}
          auditTrailRemark = {auditTrailRemark}

          success = {handleRecordSaveSuccess}
          />
        </DialogActions>
        
      </BootstrapDialog>
    </div>
  );
}



