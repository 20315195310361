import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { TextField, Button, FormControl, makeStyles } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import UserManagementService from '../../services/usermanagement.service.js';


import AuditTrailViewService from '../../services/audittrailview.service.js';
import ConfigurationViewService from '../../services/configurationview.service.js';
import TableViewService from '../../services/tableview.service';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

// Check blank values inside the row data
function hasBlankOrNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === '') {
      return true; // Found a blank or null value
    }

    if (typeof value === 'object' && hasBlankOrNullValues(value)) {
      return true; // Recursively check nested objects
    }
  }

  return false; // No blank or null values found
}

// Function to create auditTrailData
const createAuditTrailData = (oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus) => {
  
  let auditTrailData = [];
  // Get the keys of the oldValues
  const keys1 = Object.keys(oldValues);
  //console.log(keys1)
  //console.log(Object.keys(newValues))
  //console.log(keys1.length, Object.keys(newValues).length)
  // Check if the number of keys is the same
  if (keys1.length !== Object.keys(newValues).length) {
    return auditTrailData;
  }else{
    // Iterate over the keys of the oldValues
    for (let key of keys1) {
      //console.log(key, oldValues[key], newValues[key]);
      
      let activityStatus = activity;
      // modify Activity if event is enabled else activity will be deafualt value received form audittrail config collection
      if(eventStatus === "Enabled"){
        if(key === "groupnamelist"){
          if(newValues[key].length === 0){
            activityStatus = `Within tablelist, created new table - ${tagName} and no user group assigned to it`;
            auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
          }
          if(newValues[key].length > 0){
            activityStatus = `Within tablelist, created new table - ${tagName} and assigned user groups are  '${newValues[key]}'`;
            auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
          }
          
        }
        
      } else{
        auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
      }  
      
    }
    return auditTrailData;
  }

  

};

function SaveChangesDialog(props) {
  const [open, setOpen] = useState(false);

  const widgetConfigData = [{
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "1",
    "ParameterName": "Column1",
    "Enable": "Enabled",
    "Description": "Sr. no.",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "2",
    "ParameterName": "Column2",
    "Enable": "Enabled",
    "Description": "Device Name",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "3",
    "ParameterName": "Column3 ",
    "Enable": "Enabled",
    "Description": "Tag Name",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "4",
    "ParameterName": "Column4",
    "Enable": "Enabled",
    "Description": "Tag Description",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "5",
    "ParameterName": "Column5 ",
    "Enable": "Enabled",
    "Description": "Block Name",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "6",
    "ParameterName": "Column6 ",
    "Enable": "Enabled",
    "Description": "Block ID",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "7",
    "ParameterName": "Column7 ",
    "Enable": "Enabled",
    "Description": "Process Value",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "8",
    "ParameterName": "Column8",
    "Enable": "Enabled",
    "Description": "Unit",
    "Width": "100",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "9",
    "ParameterName": "Column9",
    "Enable": "Enabled",
    "Description": "LoLo SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
     "SrNo": "10",
    "ParameterName": "Column10",
    "Enable": "Enabled",
    "Description": "Low SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "11",
    "ParameterName": "Column11",
    "Enable": "Enabled",
    "Description": "High SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
     "SrNo": "12",
    "ParameterName": "Column12",
    "Enable": "Enabled",
    "Description": "HiHi SP",
    "Width": "120",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "13",
    "ParameterName": "Column13 ",
    "Enable": "Enabled",
    "Description": "Alarm Status",
    "Width": "170",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "center",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "14",
    "ParameterName": "Header Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "#A9BBD3",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "15",
    "ParameterName": "Header Font Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "16",
    "ParameterName": "Header Font Size",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "16",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "17",
    "ParameterName": "Header font Weight",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "bold",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "18",
    "ParameterName": "Vertical Border Style",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "1px solid #d4d4d4"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "19",
    "ParameterName": "Rows Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "white",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "20",
    "ParameterName": "Rows Font Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
     "SrNo": "21",
    "ParameterName": "Rows Font Size",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "14",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "22",
    "ParameterName": "Rows font Weight",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "normal",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "23",
    "ParameterName": "Rows Border Style",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "1px solid #d4d4d4"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "24",
    "ParameterName": "LoLo Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "#A2121D",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "25",
    "ParameterName": "Low Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "blue",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "26",
    "ParameterName": "High Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "purple",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "27",
    "ParameterName": "HiHi Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "red",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "28",
    "ParameterName": "No Alarm Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "29",
    "ParameterName": "Mouse Hover Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "skyblue",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "30",
    "ParameterName": "Enable - Row Add",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "31",
    "ParameterName": "Enable - Row Edit",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "32",
    "ParameterName": "Enable - Row Delete",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "33",
    "ParameterName": "Table Title Background Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "#A9BBD3",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "34",
    "ParameterName": "Table Title Font Color",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "black",
    "FontWeight": "na",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "35",
    "ParameterName": "Table Title Font Size",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "na",
    "FontSize": "18",
    "TextAlignment": "na",
    "BorderStyle": "na"
  },
  {
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "WidgetName": props.widgetName,
    "SrNo": "36",
    "ParameterName": "Table Title font Weight",
    "Enable": "Enabled",
    "Description": "na",
    "Width": "0",
    "Color": "na",
    "FontWeight": "bold",
    "FontSize": "0",
    "TextAlignment": "na",
    "BorderStyle": "na"
  }];

  const [auditTrailData, setAuditTrailData] = React.useState([]);  // AuditTrail Data

  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);
  const [password, setPassword] = useState('');
  const[pwdinterlock, setPwdInterlock] = useState("Disabled");

  const handleClickOpen = () => {

    if(((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === "--")) || ((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === ""))){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
    setOpen(true);
    }
    
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRemarkBlnkMsg(false);
  };

  const handleSuccessClose = () => {
    setOpenSuccessDialog(false);
    props.success(true);
  };

  const handleFildClose = () => {
    setOpenFaildialog(false);
  };
  

  const [message, setMessage] = useState();

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFaildialog, setOpenFaildialog] = useState(false);

  const [saveData, setSaveData] = useState(false);
  const handleClickSaveChanges = () => { 
    let oldValues = props.oldValues;
    let newValues = props.newValues;
    let tagName = props.tagName;
    let activity = props.activity;
    let activityType = 'Add Table';
    let userName = props.email;
    let role = props.role;
    let remark = props.auditTrailRemark;
    let eventStatus = props.rowData.EventStatus;

    let auditTrailData = createAuditTrailData(oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus )
    //console.log(auditTrailData)
    setAuditTrailData(auditTrailData)
    setSaveData(true);
  };

  //Get Application Configuration data 
  useEffect(() =>{
    async function getColumnData()  {
      try {

        let dashboardName = "ApplicationConfig";
        const response = await ConfigurationViewService.getAllRowsData(dashboardName);

        if(response.data.length > 0){
          //setPwdInterlock(response.data[7].TaskStatus);
          //console.log(response.data[7].TaskStatus); 
        }

        
        
      } catch (error) {
        console.log(error);

      }
    }
    getColumnData();



  }, []);

  const [widgetList, setWidgetList] = React.useState([]);
  // Get WidgetName list
  useEffect(() =>{
    async function getAllColumnData()  {
    try {
      let dashboardName = "TableList"
      let columnName = "DashboardType"
      let columnValue = "TableView";

      const response = await TableViewService.getColumnData(dashboardName, columnName, columnValue);
      if (response){
        let WidgetNames = response.data.map((item) => item.WidgetName);
        setWidgetList([...new Set(WidgetNames)]); 
      }

    } catch (error) {
      console.log(error);

    }
  };
    getAllColumnData();

  }, []);


  //  Add new row to WidgetList
  useEffect(() =>{
    async function saveRowData()  {
    try {

      let dashboardName = "TableList"
      let rowData = {...props.rowData, PWDInterlock: props.rowData.PWDInterlock, PWD: password}; // It should include all three field ie ProjectName, DashboardType, WidgetName
      //console.log(rowData)
      // Tag name & DataField Duplcation check for new tag
      if (widgetList.includes(rowData.WidgetName)) {
        setOpenFaildialog(true);
        setMessage("Widget Name already exist. Choose different Widget to avoid duplication.");
        setSaveData(false);
        setOpen(false); // Close "Save changes" window

      }else{
        // Blank value check for the  one of the form field
        if (rowData.WidgetName === '') {
          setOpenFaildialog(true);
          setMessage("Widget Name field is blank, please correct it.");
          setSaveData(false);
          setOpen(false); // Close "Save changes" window

        } else {
            const response = await TableViewService.createRow(dashboardName, rowData, auditTrailData);  // create new record if id is not present in array data ie existing data
            if(response){
              // Add widget config Data for newly created widget
              let dashboardName= "TableConfig"
              const response1 = await TableViewService.insertMultipleRow(dashboardName, widgetConfigData);  // Insert multiple records
              if(response1){
                setOpenSuccessDialog(true);
              setMessage("Record Added Successfully, Reload the application to get new widget name list.");
              setSaveData(false);
              setOpen(false); // Close "Save changes" window

              }
              
      
            }
        }
      }

    } catch (error) {
      setOpenFaildialog(true);
      setMessage("Error While Adding Record, Please very all parameters entered in form");
      setOpen(false); // Close "Save changes" window
      console.log(error);


    }
  };
  if(saveData){
    saveRowData();
    setSaveData(false);
  };


  }, [saveData]);

 
  

  return (
    
    <div>
      <Button variant="outlined" startIcon ={<SaveIcon/>} sx={{mt:1}} onClick={handleClickOpen}>SAVE CHANGES </Button>

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Open Dialog to ask surity to edit user's data, with or withoout password */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>SAVE CHANGES</DialogTitle>
        <Box sx={{width: 400 }}>

        <DialogContent dividers>
          <DialogContentText>
            Are you sure to add new widget.
          </DialogContentText>
          
        </DialogContent>

        {props.rowData.PWDInterlock === 'Enabled' && (<DialogContent dividers>
          <DialogContentText>
            Enter the Password to add new table.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          </DialogContent>
        )}


        </Box>
        <DialogActions>
          <Button onClick={handleClickSaveChanges}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Success dialog after upgradtion of teh user info. */}
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessClose}
       
      >
        <DialogTitle > success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Save Changes Failed dialog /Wrong Password */}
      <Dialog
        open={openFaildialog}
        onClose={handleFildClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFildClose}>Close</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function WidgetListAdd ({open, close}) {
  const { user } = useSelector((state) => state.auth);
  const projectName = user.selectedproject;
  const dashboardType = 'TableView';
  const [widgetName, setWidgetname] = useState("")
  //console.log(ProjectName, DashboardType);


  const initialAlarmConfigData = {
    //id: null,
    ProjectName: projectName ,
    DashboardType: dashboardType,
    WidgetName: "",
    WidgetDescription:"",
    groupnamelist:"Common",
    
    email: user.email,
    role: user.role,  
    RemarkStatus : "Disabled",
    EventStatus:"Disabled",
    PWDInterlock:"Disabled",          
  };

  const [eventDescription, setEventDescription] = useState("--");
  const [auditTrailRemark, setAuditTrailRemark] = useState("--");
  // parameter list for audit trail log
  const oldValues = {WidgetName: "", WidgetDescription:"", groupnamelist:"Common",};

  const [newValues, setNewValues] = useState(oldValues);

  const [tableListData, setTableListData] = React.useState(initialAlarmConfigData);
  const [groupNameList, setGroupNameList] = React.useState([]);
  const [selecetdGroupNameList, setSelecetdGroupNameList] = React.useState(["Common"]);
  
  // Get GroupName list
  useEffect(() =>{
    async function getAllColumnData()  {
      try {
      let dashboardName = "UserGroupList"
      let columnName = "DashboardType"
      let columnValue = "UserManagement";

      const response = await UserManagementService.getColumnData(dashboardName, columnName, columnValue);
      if (response){
          let GroupNames = response.data.map((item) => item.GroupName);
          setGroupNameList([...new Set(GroupNames)]); 
      }

      } catch (error) {
      console.log(error);

      }
    };
    getAllColumnData();

  }, []);

  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Add Table';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setTableListData({...tableListData, EventStatus: response.data[0].EnableEvent, PWDInterlock: response.data[0].EnablePwdInterlock, RemarkStatus: response.data[0].EnableRemark});

        setEventDescription(response.data[0].DefaultEventDescription);
        // If remark field is disabled then set default remark vlaue to currrent Remark value
        if(response.data[0].EnableRemark === "Disabled"){
          setAuditTrailRemark(response.data[0].DefaultEventRemark);
        }
        
      }
  
    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);

 
  const handleClose = () => {
    //console.log("Close Buton Pressed");
    close() // Close the alarm add main window
  };

  const handleRecordSaveSuccess = (data) =>{
    close(data)
  }


  const handleTextFieldChange = (event) => {

    const {name, value} = event.target;
    setTableListData({...tableListData, [name]:value});
    setNewValues ({...newValues, [name] :value});

    setWidgetname(event.target.value);
  };

  const handleWidgetDescrChange = (event) => {

    const {name, value} = event.target;
    setTableListData({...tableListData, [name]:value});
    setNewValues ({...newValues, [name] :value});
  };

  const handlemultipleSelect = (event) => {
    setSelecetdGroupNameList(event.target.value); 
    const {name, value} = event.target;
    setTableListData({...tableListData, [name]:value});
    setNewValues ({...newValues, [name] :value});
  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    if(tableListData.RemarkStatus === "Enabled"){
      setAuditTrailRemark(value);

    }
  };


  return (
    <div>
      
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            maxWidth: '1000px', // Change this value as needed
          },
        }}
      >
        {/* Hnadle multiple functions on single click ie edit switch set to false and close the MyAccount Dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose();  }}>
          Add New Widget to the List.
        </BootstrapDialogTitle>

        {/*Widget Name setting */}
        <DialogContent dividers>
          

          {/* ProjectName */}
          <TextField
            autoFocus
            margin="dense"
            label="ProjectName"
            type="text"
            fullWidth
            variant="standard"
            name="ProjectName"
            value={projectName}
            onChange={handleTextFieldChange}
            disabled={true}
          />

          {/* DashboardType */}
          <TextField
            autoFocus
            margin="dense"
            label="DashboardType"
            type="text"
            fullWidth
            variant="standard"
            name="DashboardType"
            value={dashboardType}
            onChange={handleTextFieldChange}
            disabled={true}
          />

          {/* WidgetName */}
          <TextField
            autoFocus
            margin="dense"
            label="Widget Name"
            type="text"
            fullWidth
            variant="standard"
            name="WidgetName"
            value={tableListData.WidgetName}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* Widget Description */}
          <TextField
            margin="dense"
            label="Widget Description"
            type="text"
            fullWidth
            variant="standard"
            name="WidgetDescription"
            value={tableListData.WidgetDescription}
            onChange={handleWidgetDescrChange}
            disabled={false}
          />

          {/* User Group name */}
          <FormControl sx={{ m: 0, width: 200, ml:2, mt: 2 }} variant="standard">
              <InputLabel id="demo-customized-select-label">User Group Name</InputLabel> 
              <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              autoFocus
              multiple
              name="groupnamelist"
              value={selecetdGroupNameList}
              //value={(groupNameList.includes(tableListData.groupnamelist)) ? tableListData.groupnamelist : ''}
              disabled={false}
              onChange={handlemultipleSelect}
              //input={<BootstrapInput />}
              input={<Input />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              
              >
              {groupNameList.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    //disabled={name === 'Common'}
                  >
                    <Checkbox checked={selecetdGroupNameList.indexOf(name) > -1 }/>
                    <ListItemText primary={name} />
                    
                  </MenuItem>
                ))}
              </Select>
          </FormControl>


        </DialogContent>

        {/*Remark */}
        {tableListData.RemarkStatus === 'Enabled' && (<DialogContent 
        dividers
        >
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 400 }}>
          Enter remark/reason 
          </Typography>

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            name="Remark"
            placeholder="Enter Remark"
            multiline
            rows={2}
            style={{ width: 550 }}
            onChange={handleRemarkChange}
            //defaultValue="Default Value"
          />

        </DialogContent>
        )}

        <DialogActions>
          <SaveChangesDialog
          projectName = {projectName}
          dashboardType = {dashboardType}
          widgetName = {widgetName}
          rowData = {tableListData}

          email = {user.email}
          role = {user.role}
          oldValues = {oldValues}
          newValues = {newValues}
          activity = {eventDescription}
          tagName = {tableListData.WidgetName}
          auditTrailRemark = {auditTrailRemark}

          success = {handleRecordSaveSuccess}
          />
        </DialogActions>
        
      </BootstrapDialog>
    </div>
  );
}



