import React, { useState, useEffect, lazy } from 'react';
import {useSelector} from 'react-redux';
import BackgroundImage from './BackgroundImage.js';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box'
import CustomizedBreadcrumbs from './Breadcrumbs.js';
import DateTimeDisplay from './DateTimeDisplay.js';
import SplitButton2 from './splitButton2.js';



export default function TableView({}){

  const { user } = useSelector((state) => state.auth);
  const[dashboardName, setDashboardname] = useState();
  const[widgetName, setWidgetName] = useState();
  //Import() to call function or component with dynamic path value
  let projectname = user.selectedproject;
  let dashboardType = "ConfigurationView";




  const ImportCompo = lazy(() => import(`./../pages/ConfigurationView/${dashboardName}`).catch((err) => {  
  return import('./BackgroundImage.js');
  }),
  );


  const handleSelectedDashboard = (value) => {
    //console.log(value);
    setDashboardname(value);
  };

  const handleSelectedWidget = (value) => {
    //console.log(value);
    setWidgetName(value);
  };

  // setIsVisible when user login is Engineer
  const [isVisible, setIsVisible] = React.useState(true);

  return (
    
    <div >

        <Box sx={{mt:1, height: 50}} >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{backgroundColor: 'transparent'}}>
          <Grid item xs={10}>


            
            <CustomizedBreadcrumbs
            projectName={projectname}
            dashboardType={dashboardType}
            selectedDashboard = {handleSelectedWidget}
            />

          </Grid>
          <Grid item xs={2} justifyContent="center">
          {(user.role === "engineer" || user.role === "superadmin") && (
          <SplitButton2
          projectName={projectname}
          dashboardType={dashboardType}
          selectedDashboard = {handleSelectedDashboard}
          />
          )}
          </Grid>
          {/* <Grid item xs={4}> <DateTimeDisplay/>  </Grid> */}
          </Grid>
        </Box>

      
      
    {/* Selected page or dashboard content */}

    {dashboardName ? (
      <React.Suspense fallback={<>...</>}>
      <ImportCompo/>
    </React.Suspense>
    ):(
      <BackgroundImage/>
    )}
        
    </div>


  );
}


