import axios from "axios";
import {API} from './api.js';
const API_URL = API + '/reportview/'
//const API_URL = "http://localhost:3000/api/reportview/";
//const API_URL = "http://192.168.3.108:3000/api/reportview/";

//get the total number of the documents present in collection
//Get the n records = per page record, init record number will be the page number-1
// map the received data value dynamically as row lsit
// Map the key names of received data dynamically as column list


//Get count of records present in table
const getRowsCount = (dashboardName) => {

  return axios.post(API_URL + "count", {
    dashboardName,
    })
    
};


// get specific rows data, init record = page * rowsPerPage and number of record to fetch = rowsPerPage
const getRowsData = (dashboardName, offset, rowsPerPage) => {

  return axios.post(API_URL + "data", {
      dashboardName,
      offset,
      rowsPerPage
    })
};

// Get all rows data present inside the table
const getAllRowsData = (dashboardName) => {
  return axios.post(API_URL + "all", {
    dashboardName,
  });
 
};

// Get all rows data present inside the table
const getColumnData = (dashboardName, columnName, columnValue) => {
  return axios.post(API_URL + "columnData", {
    dashboardName,
    columnName,
    columnValue,
  });
 
};

// Get all field names of the specific table/collection
const getSchemaKeys = (dashboardName) => {
  return axios.post(API_URL + "schemaKeys", {
    dashboardName,

  });
 
};


// Save specific row data present inside the table
const updateRowData = (dashboardName, rowData, auditTrailData) => {

  return axios.post(API_URL + "update", {
    dashboardName,
    rowData,
    auditTrailData,
  });
   
};


// Create or Add document/record to existing collection/table
const createRow= (dashboardName, rowData, auditTrailData) => {
    return axios.post(API_URL + "create", {
      dashboardName,
      rowData,
      auditTrailData,
      })
};

//get data from table/document(dashboardName), retrieve specific column's(columnsToRetrieve) data based on specific values(valueToMatch ) of one of the column(columnToFilter)
const getMultiColumnsRows=(dashboardName, columnsToRetrieve, columnToFilter, valueToMatch ) => {
  return axios.post(API_URL + "multiColumnsRows", {
    dashboardName, 
    columnsToRetrieve,
    columnToFilter,
    valueToMatch
  })

};

// insert multiple document/record to existing collection/table
const insertMultipleRow=(dashboardName, widgetConfigData ) => {
  return axios.post(API_URL + "multiInsert", {
    dashboardName, 
    widgetConfigData
  })

};

// delete multiple document/record of existing collection/table for specific condition ie delete data if values matches for given fields.
const deleteMultipleRows=(dashboardName, rowData ) => {
  return axios.post(API_URL + "deleteMany", {
    dashboardName, 
    rowData 
  })

};

// Delete specific document/record from existing collection/table
const deleteRow = (dashboardName, rowData, auditTrailData) => {
    return axios.post(API_URL + "delete", {
      dashboardName,
      rowData,
      auditTrailData,
      })
  };

const ReportViewService = {
  getRowsCount,
  getRowsData,
  getAllRowsData,
  updateRowData,
  getColumnData,
  createRow,
  insertMultipleRow,
  deleteRow,
  deleteMultipleRows,
  getMultiColumnsRows,
  getSchemaKeys,

}

export default ReportViewService;