import React, { useState, useEffect, lazy } from 'react';
//const fs = require('fs');

import {useSelector} from 'react-redux';
import SplitButton from './splitButton1.js';
import BackgroundImage from './BackgroundImage.js';

//import "./styles.css";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DashboardCustomizeSharpIcon from '@mui/icons-material/DashboardCustomizeSharp';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import CustomizedBreadcrumbs from './Breadcrumbs.js';
import SplitButton2 from './splitButton2.js';


import DateTimeDisplay from './DateTimeDisplay.js';



export default function TableView({}){

/*
  const folderPath = "../pages/DashboardView/"
  const[files, setFiles] = useState([]);

  useEffect (()=>{
    //Read teh files in specified Folder
    fs.readdir(folderPath, (err, files)=>{
      if(err){
        console.log(err);
      }else {
        setFiles(files);
      }
    });
  }, [folderPath]);
  */


  const { user } = useSelector((state) => state.auth); 
  const[dashboardName, setDashboardName] = useState("Dashboard"); // This "Dashboard" Vlaue will open the dashboard bydefault with name "Dashboard"
  const[widgetName, setWidgetName] = useState();
  //Import() to call function or component with dynamic path value
  let projectname = user.selectedproject;
  let dashboardType = "TableView";
  let DashboardName = "Widget1"
  const widgetPath = projectname + "/" + dashboardType + "/" + DashboardName

  //console.log(widgetPath);

  const tryRequire = () => {
    try {
     return require(`./../pages/TableView/${dashboardName}`);
    } catch (err) {
     return null;
    }
  };

 
  //only accepting last name of file path as variable, otherwise giving error
  //const MyComponent = lazy(() => import(`./../pages/DashboardView/${dashboardName}`));

  
//Here.catch used, if dasboardName file does not exist then will display the BackgroundImage.js content to avoid the error.
//only accepting last name of file path as variable, otherwise giving error
  const ImportCompo = lazy(() => import(`./../pages/TableView/${dashboardName}`).catch((err) => {  
  return import('./BackgroundImage.js');
  }),
  );


  const handleSelectedDashboard = (value) => {
    //console.log(value);
    setDashboardName(value);
  };

  const handleSelectedWidget = (value) => {
    //console.log(value);
    setWidgetName(value);
  };

  // setIsVisible when user login is Engineer
  const [isVisible, setIsVisible] = React.useState(true);
 
  return (
    
    <div >
      {/* <Box sx={{height: 85 }}>  </Box> */}

        <Box sx={{mt:1, height: 50}} >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{backgroundColor: 'transparent'}}>
          <Grid item xs={10}>           
            <CustomizedBreadcrumbs
            projectName={projectname}
            dashboardType={dashboardType}
            selectedDashboard = {handleSelectedWidget}
            />

          </Grid>
          <Grid item xs={2} justifyContent="center">
          {(user.role === "admin" || user.role === "engineer"  || user.role === "superadmin") && (
          <SplitButton2
          projectName={projectname}
          dashboardType={dashboardType}
          selectedDashboard = {handleSelectedDashboard}
          />
          )}
          </Grid>
          
          
          {/* <Grid item xs={4}> <DateTimeDisplay/>  </Grid> */}
        </Grid>
        </Box>

      
      
    {/* Selected page or dashboard content */}

    {dashboardName ? (
      <React.Suspense fallback={<>...</>}>
      <ImportCompo/>
    </React.Suspense>
    ):(
      <BackgroundImage/>
    )}
        
    </div>


  );
}