import React, { useState, useEffect } from 'react';

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const unhandledError = (event) => {
      console.error('Unhandled error:', event.error);
      setHasError(true);
    };

    const unhandledPromiseRejection = (event) => {
      console.error('Unhandled promise rejection:', event.reason);
      setHasError(true);
    };

    window.addEventListener('error', unhandledError);
    window.addEventListener('unhandledrejection', unhandledPromiseRejection);

    return () => {
      window.removeEventListener('error', unhandledError);
      window.removeEventListener('unhandledrejection', unhandledPromiseRejection);
    };
  }, []);

  if (hasError) {
    return <h1>Something went wrong. Please try again later.</h1>;
  }

  return children;
}

export default ErrorBoundary;

