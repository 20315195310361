
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { logout } from '../slices/auth';

function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  
  const SignOut = ({open, close, logout}) => {

  
    return (
      <div>

        <Dialog
          open={open}
          onClose={close}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Logout Option
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure to logout from current session ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button  onClick={logout} href="/login" >
              yes
            </Button>
            <Button autoFocus onClick={close}>No</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  export default SignOut;