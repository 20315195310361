import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Navigate, useNavigate } from "react-router-dom";

import { register, uploadProfilePhoto } from "../slices/auth";
import { clearMessage } from "../slices/message";
import Raosaheb from "../assets/Raosaheb.jpg";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      
      <Link color="inherit" href="http://radix.co.in" target="_blank">
        Radix Electrosystems
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function SignUp() {
  let navigate = useNavigate();
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFaildialog, setOpenFaildialog] = useState(false);
  const handleClose = () => {
    setOpenSuccessDialog(false); 
    setOpenFaildialog(false);
    if(successful){
      navigate("/login");
      window.location.reload();
    }
    
  };
 
  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

 
  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .test(
        "len",
        "The username must be between 3 and 20 characters.",
        (val) =>
          val && val.toString().length >= 3 && val.toString().length <= 20
      )
      .required("This field is required!"),
    email: Yup.string()
      .email("This is not a valid email.")
      .required("This field is required!"),
    password: Yup.string()
      .test(
        "len",
        "The password must be between 6 and 40 characters.",
        (val) =>
          val && val.toString().length >= 6 && val.toString().length <= 40
      )
      .required("This field is required!"),
  });

  const formik = useFormik({
    
    initialValues: {
      userName: '',
      lastName: '',
      email: '',
      password: '',
    },
    
    validationSchema: validationSchema,
    onSubmit: (values) => {
      
      const username = values.userName
      const lastname = values.lastName
      const email = values.email
      const password = values.password

    setSuccessful(false);

    //convert static image to blob
    fetch(Raosaheb)
    .then((response) => response.blob())
    .then((blob)=>{
      //console.log(blob);
      let singleImage = blob;
      dispatch(uploadProfilePhoto({email, singleImage}))
      .unwrap()
    })
      .then(() => {
        dispatch(register({ username, lastname, email, password }))
        .unwrap()
        .then(()=> {
          setSuccessful(true);
          setOpenSuccessDialog(true);
        })
        
      })
      .catch(() => {
        setSuccessful(false);
        setOpenFaildialog(true);
      });
    },
  });

  

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
          >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box sx={{ mt: 3 }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {/*usertName*/}
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="userName"
                  required
                  fullWidth
                  id="userName"
                  label="First Name"
                  autoFocus

                  margin="normal"
                  value={formik.values.userName}
                  onChange={formik.handleChange}

                  error={formik.touched.userName && Boolean(formik.errors.userName)}
                  helperText={formik.touched.userName && formik.errors.userName}
                />
              </Grid>
              {/*LastName*/}
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"

                  margin="normal"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />
              </Grid>
              {/*Email*/}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"

                  margin="normal"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              {/*Password*/}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"

                  margin="normal"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}

                />
              </Grid>
              {/*TermsCondition*/}
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I am agree with terms and condition of the Radix IoT Cloud Platform."
                />
              </Grid>
            </Grid>
            
            <Button 
              sx={{ mt: 3, mb: 2 }}
              color="primary" 
              variant="contained" 
              fullWidth
              type="submit"
            >
              Sign Up
            </Button>
          </form>
          </Box>
          <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>

      <Dialog
        open={openSuccessDialog}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          User Registration success..
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
            ...Please contact your system administrator and request to asign role.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openFaildialog}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          User Registration Failed..
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}