import * as React from 'react';
import BackgroundImage from './BackgroundImage';

export default function Welcome() {


  return (
    <BackgroundImage/>
  );
}


