import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function DateTimeDisplay() {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the current date and time every second
      setCurrentDateTime(new Date());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
    <Box sx={{mt:0}}>
      <Typography variant="h6" display="block" style={{ fontWeight: 'bold', fontSize:16, textAlign: 'right' }}>
        Date and Time: {currentDateTime.toLocaleString()} 
      </Typography>

    </Box>
    </div>
  );
}

export default DateTimeDisplay;
