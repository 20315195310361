import React, {useState, useEffect, useCallback} from "react";
import { BrowserRouter as Router, Routes, Route, Link,} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "./App.css";

import Layout from "./components/Layout";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";

import { useNavigate } from "react-router-dom";

import { logout } from "./slices/auth";
import EventBus from "./common/EventBus";

import ErrorBoundary from './ErrorBoundary'; // To avoid any errors appearing on browser

function App() {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const[logoutDN, setLogoutDN] = useState(false);

  // below function will get executed when user token get expired.
  const logOut = useCallback(() => {
    // Here with imported { logout } from "./slices/auth"; will get dispatched.
    dispatch(logout());
  }, [dispatch]);


  // when token get expire, currentUser will become null and below useEffect will get executed and then user will get logout automatically from application
  useEffect(() => {
    
    if (currentUser) {
      if(currentUser.role === "supervisor"){
        setShowModeratorBoard(true);
      }
      if(currentUser.role === "Admin"){
        setShowAdminBoard(true);
      }
      
      
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);

    }

    EventBus.on("logout", () => {
      // Execute below function to perform logout action
      setLogoutDN(true);
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  /////////////////////////////
  // Prevent openng dupicate tab
  useEffect(() => {
    const hasOpenedApp = sessionStorage.getItem('hasOpenedApp');
    if (hasOpenedApp) {
      // App is already opened in another tab
      sessionStorage.removeItem('hasOpenedApp'); // Clear the flag
      alert('You have already opened the application in another tab. Close this tab to avoid autologout from application,  even if you are performing operations on first tab.');
      window.close(); // Close the tab
      //window.location.href = window.location.origin; // Redirect to the first tab
    } else {
      // Set flag indicating the app is opened
      sessionStorage.setItem('hasOpenedApp', true);
    }

    // Cleanup on component unmount
    return () => {
      sessionStorage.removeItem('hasOpenedApp');
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Remove the flag on tab close
      sessionStorage.removeItem('hasOpenedApp');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  ///////////////////////////////


  return (
    <Router>
      
    <div>
      <div >
      <ErrorBoundary>
        <Routes>
          {currentUser && <Route path="/layout" element={<Layout />} />}
          <Route path="/" element={<SignIn />} />
          <Route path="/login" element={<SignIn />} />
          {logoutDN && <Route path="/login" element={<SignIn />} />}
          {/*<Route path="/register" element={<SignUp />} /> */}
        </Routes>
      </ErrorBoundary>
      </div>
    </div>

    </Router>
    
  );
}

export default App;