import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialFromDate = JSON.stringify(dayjs().subtract(1, 'hour'));
const initialToDate = JSON.stringify(dayjs());
const initialState = {
 
  fromDateTime: initialFromDate, 
  toDateTime: initialToDate,
  buttonStatus : 1,
  updateInterval: 1,
  
};

const chartTime= createSlice({
  name: 'charttime',
  initialState,
  reducers: {
    setButtonStatus: (state, action) => {
      state.buttonStatus = action.payload;
    },
    setFromDateTime: (state, action) => {
      state.fromDateTime = action.payload;
    },
    setToDateTime: (state, action) => {
      state.toDateTime = action.payload;
    },
    setUpdateInterval: (state, action) => {
      state.updateInterval = action.payload;
    },
  },
});

export const { setButtonStatus, setFromDateTime, setToDateTime, setUpdateInterval} = chartTime.actions;
export default chartTime.reducer;