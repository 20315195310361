import * as React from 'react';
import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TextField, Button, FormControl, makeStyles } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, GridEditInputCell} from '@mui/x-data-grid';
import {randomId} from '@mui/x-data-grid-generator';

import DashboardViewService from '../../services/dashboardview.service';
import AuditTrailViewService from '../../services/audittrailview.service.js';
import ConfigurationViewService from '../../services/configurationview.service.js';
import DashboardListAdd from './DashboardListAdd';
import DashboardListEdit from './DashboardListEdit';

import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { styled } from '@mui/material/styles';

import Alert from '@mui/material/Alert';


// Add row to the existing rows
function EditToolbar(props) {
  const { setAddRecordForm } = props;

  const handleClick = () => {
    setAddRecordForm(true);
  };


  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add New Dashboard
      </Button>
    </GridToolbarContainer>
  );
}

// Check blank values inside the row data
function hasBlankOrNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === '') {
      return true; // Found a blank or null value
    }

    if (typeof value === 'object' && hasBlankOrNullValues(value)) {
      return true; // Recursively check nested objects
    }
  }

  return false; // No blank or null values found
}

// Check for Valid IP
function hasValidIP(obj) {
  // Regular expression pattern for IPv4 addresses
  const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  //const ipv4Pattern = /^(\d{1,3}\.){3}\d{1,3}$/;
  // Regular expression pattern for IPv6 addresses
  const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;

  if(ipv4Pattern.test(obj.IPAdd) || ipv6Pattern.test(obj.IPAdd)){

    return true; // Valid IP Address
  }

  return false; // Invalid IP Address
}

// Check for the values are within min max range or not
function isWithinMinMaxRanges(data, ranges, exclude) {
  for (const key in data) {
    // Skip checking for values in the exclude array
    if (exclude.includes(key)) {
      continue;
    }

    if (key in ranges) {
      const { min, max } = ranges[key];
      if (data[key] < min || data[key] > max) {
        return false; // Value is outside the specified range
      }
    }
  }
  return true; // All values are within their respective ranges
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


export default function WidgetList() {
  const { user } = useSelector((state) => state.auth);

  const [fetchData, setFetchData] = React.useState(true); // Fetch all row data enable bit
  const [rowsData, setRowsData] = React.useState([]); // All Row Data fetched 
  const [blockNameList, setBlockNameList] = React.useState([]) // retrived BlockName lsit From received data
  const [isAddingNewRecord, setIsAddingNewRecord] = React.useState(false);

  const [rowModesModel, setRowModesModel] = React.useState({}); 

  const [saveData, setSaveData] = React.useState(false);  // Save specific Row Data
  const [rowData, setRowData] = React.useState([]); // Specific Row Data


  
  const[deleteDialog, setDeleteDialog] = React.useState(false); // Delete Dialog bit
  const noButtonRef = React.useRef(null); 
  const[deleteData, setDeleteData] = React.useState(false); //Delete row bit
  const [snackbar, setSnackbar] = React.useState(null); // if delete Success then set to success otherwise will set to fail

  const handleCloseSnackbar = () => setSnackbar(null);

  const [addRecordForm, setAddRecordForm] = React.useState(false)
  const [editRecordForm, setEditRecordForm] = React.useState(false)
  const [editRowData, setEditRowData] = React.useState(false)

  const[remarkDialog, setRemarkDialog] = React.useState(false); // Remark Dialog bit
  const [eventDescription, setEventDescription] = useState("--");
  const [auditTrailRemark, setAuditTrailRemark] = useState("--");
  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);
  const [password, setPassword] = useState('');
  const[pwdinterlock, setPwdInterlock] = useState("Disabled");

 
  // Get  All row data 
  useEffect(() =>{
    async function getAllRowsData()  {
    try {

      let dashboardName = "DashboardList"
      //const offset = page * rowsPerPage;
      //console.log(page);
      //console.log(offset);
      //console.log(rowsPerPage);
      setRowsData([]);
      const response = await DashboardViewService.getAllRowsData(dashboardName);
      if (response){
        setRowsData(response.data);
        //let BlockNames = response.data.map((item) => item.BlockName);
        setBlockNameList(response.data.map((item) => item.BlockName)); 
      }
      
      
    } catch (error) {
      console.log(error);

    }
  }
  if(fetchData){
    getAllRowsData();
    setFetchData(false);
    
  };


  }, [fetchData]);

  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Delete Dashboard';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setRowData({...rowData, EventStatus: response.data[0].EnableEvent, PWDInterlock: response.data[0].EnablePwdInterlock, RemarkStatus: response.data[0].EnableRemark});
        setPwdInterlock(response.data[0].EnablePwdInterlock);
        setEventDescription(response.data[0].DefaultEventDescription);
        // If remark field is disabled then set default remark vlaue to currrent Remark value
        if(response.data[0].EnableRemark === "Disabled"){
          setAuditTrailRemark(response.data[0].DefaultEventRemark);
        }
        
      }

    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);

  //Get Application Configuration data 
  useEffect(() =>{
    async function getColumnData()  {
      try {

        let dashboardName = "ApplicationConfig";
        const response = await ConfigurationViewService.getAllRowsData(dashboardName);

        if(response.data.length > 0){
          //setPwdInterlock(response.data[8].TaskStatus);
          //console.log(response.data[8].TaskStatus); 
        }

        
        
      } catch (error) {
        console.log(error);

      }
    }
    getColumnData();



  }, []);

  // Delete  Specific Row row data 
  useEffect(() =>{
    async function deleteRowData()  {
    try {

      let dashboardName = "DashboardList"
      let auditTrailData =[{
        TimeStamp:new Date(),
        Activity: `Within dashbaord list removed dashboard - '${rowData.DashboardName}'`,
        ActivityType: "Delete Dashboard",
        UserName: user.email,
        Role: user.role,
        Remark: auditTrailRemark,
        EventStatus: rowData.EventStatus,
      }]
 
      if (rowData === null){
        console.log("Provide Proper Id & data to delete record")
      }else{
        //console.log(rowData);
        let updatedRowData = {...rowData, email:user.email, role: user.role, PWDInterlock: pwdinterlock, PWD: password};
        //console.log(updatedRowData)
        // Delete row from DashboardList for selected rowId
        const response = await DashboardViewService.deleteRow(dashboardName, updatedRowData, auditTrailData);  
        if(response){
          setAuditTrailRemark("--"); // Clear remark field
          // Delete all mupltiple record for provided project number, dashboard name and widget name
          let dashboardName = "DashboardConfig";

          //Always Pass projectName, dashboardType, widgetName into rowData to delete multiple rows data
          const response1 = await DashboardViewService.deleteMultipleRows(dashboardName, rowData);
          if(response1){
            // Delete all field from DashboardView for selected dashboardType and widgetName
            let dashboardName = "DashboardView";
            //Always Pass projectName, dashboardType, widgetName into rowData to delete multiple rows data
            const response2 = await DashboardViewService.deleteMultipleRows(dashboardName, rowData);
            if(response2){
              setFetchData(true);  // reload the data again from backend
              setSnackbar({ children: 'Deleted modbus query successfully', severity: 'success' });
            }
            

          }
        
        }

      }
    

    } catch (error) {
      setSnackbar({ children: "Dashboard deletion Failed", severity: 'error' });
      console.log(error);
      setAuditTrailRemark("--");

    }
  };
  if(deleteData){
    deleteRowData();
    setDeleteData(false);
  };


  }, [deleteData]);


  const handleClose = () => {
    setOpenRemarkBlnkMsg(false);
  };

  const handleRecordEdited = (data) => {
    
    setEditRecordForm(false);
    setFetchData(true);  // reload the data again from backend
    
  };


  const handleEditClick = (params) => () => {
    //console.log(params);
    setEditRowData(params);
    setEditRecordForm(true);

  }

  const handleRowDoubleClick = (params, event) => {
    //console.log(params);
    setEditRowData(params);
    setEditRecordForm(true);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    
  };

  const handleDeleteYes = () => {
    setDeleteData(true);
    setDeleteDialog(false);
    setRemarkDialog(false);
  };

  const handleDeleteNo = () => {
    
    setDeleteDialog(false);
    setRemarkDialog(false);
    setAuditTrailRemark("--"); // Clear remark field
  };

  const renderDeleteConfirmDialog = () => {
    if (!deleteDialog) {
      return null;
    }

    //const { newRow, oldRow } = promiseArguments;
    //const mutation = computeMutation(newRow, oldRow);
    return (
      <div>

      <Dialog
        maxWidth="xs"
        //TransitionProps={{ onEntered: handleEntered }}
        open={!!deleteDialog}
      >
        <DialogTitle>Are you sure to delete selected Row?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will delete the selecetd row.`}
        </DialogContent>
        
        {pwdinterlock === 'Enabled' && (<DialogContent dividers>
          <DialogContentText>
            Enter the Password to delete the table.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          </DialogContent>
        )}

        <DialogActions>
          <Button ref={noButtonRef} onClick={handleDeleteNo}>
            No
          </Button>
          <Button onClick={handleDeleteYes}>Yes</Button>
        </DialogActions>
      </Dialog>

      </div>
    );
  };

  const handleDelete = () => ()=> {   
    if(((rowData.RemarkStatus === "Enabled") && (auditTrailRemark === "--")) || ((rowData.RemarkStatus === "Enabled") && (auditTrailRemark === ""))){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
      //console.log("test2");
      setDeleteDialog(true);
      setRemarkDialog(false);
      
    }
    
  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    if(rowData.RemarkStatus === "Enabled"){
      setAuditTrailRemark(value);

    }
  };

  const renderRemarkDialog = () => {
    if (!remarkDialog) {
      return null;
    }

    //console.log("test1");
    //const { newRow, oldRow } = promiseArguments;
    //const mutation = computeMutation(newRow, oldRow);
    return (
      <div>

      <Dialog
        maxWidth="xs"
        //TransitionProps={{ onEntered: handleEntered }}
        open={!!remarkDialog}
      >
        <DialogTitle>Enter Remark</DialogTitle>
        {/*Remark */}
        <DialogContent 
        dividers
        >
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 350 }}>
          Enter remark or reason for delete action
          </Typography>

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            name="Remark"
            placeholder="Enter Remark"
            multiline
            rows={2}
            style={{ width: 350 }}
            onChange={handleRemarkChange}
            //defaultValue="Default Value"
          />

        </DialogContent>


        <DialogActions>
          <Button ref={noButtonRef} onClick={handleDeleteNo}>
            Cancel
          </Button>
          <Button onClick={handleDelete()}>Delete</Button>
        </DialogActions>
      </Dialog>

      </div>
    );
  };


  // After onclick of delete icon, open remark window if remark is enabled otherwise open delete surity window.
  const handleDeleteClick = (params) => () => {
    //setRowData({...params.row, RemarkStatus: rowData.RemarkStatus, EventStatus:rowData.EventStatus, email:user.email, role: user.role, PWDInterlock: pwdinterlock, PWD: password});
    setRowData({...params.row, RemarkStatus: rowData.RemarkStatus, EventStatus:rowData.EventStatus});
    if(rowData.RemarkStatus === "Enabled"){
      //console.log("test0");
      setRemarkDialog(true);
    }else{
      //console.log("test0");
      const withoutRemark = handleDelete();
      withoutRemark();
    }

  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rowsData.find((row) => row._id === id);
    if (editedRow.isNew) {
      setRowsData(rowsData.filter((row) => row._id !== id));
    }
  };


  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRecordAdded = (data) => {
    
    setAddRecordForm(false);
    setFetchData(true);  // reload the data again from backend
    //console.log("Record Added", data);
    
  };

  const columns = [

    //{ field: 'ProjectName', headerName: 'Project Name', type:'singleSelect', valueOptions: ['Enabled', 'Disabled'], width: 200, align: 'center', headerAlign: 'center',},
    //{ field: 'DashboardType', headerName: 'Dashboard Type', type: 'string', width: 200, align: 'center', headerAlign: 'center',},
    { field: 'DashboardName', headerName: 'Dashboard Name', type: 'string', width: 200, align: 'center', headerAlign: 'center',},
    { field: 'DashboardDescription', headerName: 'Dashboard Description', type: 'string', width: 200, align: 'center', headerAlign: 'center',
    renderCell: (params) => ( 
      <div style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}> 
        {params.value}
      </div>
    ),}, // this renderCell will wrap the text inside the column
    { field: 'dashboardgroupnamelist', headerName: 'Assigned Dashboard Groups', type: 'string', width: 250, align: 'center', headerAlign: 'center',
      renderCell: (params) => (
        <ul style={{ margin: 0, padding: 1, listStyleType: 'square' }}>
          {params.value && params.value.length > 0 && params.value.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ),},
    { field: 'groupnamelist', headerName: 'Assigned User Groups', type: 'string', width: 250, align: 'center', headerAlign: 'center',
    renderCell: (params) => (
      <ul style={{ margin: 0, padding: 1, listStyleType: 'square' }}>
        {params.value && params.value.length > 0 && params.value.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    ),},

    
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        const id = params.row._id;
        const Projectname = params.row.ProjectName;
        const DashboardType = params.row.DashboardType;
        const WidgetName = params.row.WidgetName;
        
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params)}
            color="inherit"
          />,
          
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params)}
            color="inherit"
          />,
        ];
      },
    },
  ];

    

  return (
    <div>
    {renderDeleteConfirmDialog()}
    {renderRemarkDialog()}
    
    
    <Paper
      sx={{
        p: 1,
        margin: 'auto',
        maxWidth: 'fit-content',
        maxHeight:600,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
      elevation={24}
    >
     
     <Box sx={{ height: 600 }}>
    
      <DataGrid
        rows={rowsData}
        getRowId={(rowsData) => rowsData._id} // This line will set id of the datagrid row = _id of the mongodb database, otherwisw error can occured.
        columns={columns}
        hideFooterPagination = {true}
        //pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowDoubleClick={handleRowDoubleClick} // Double click event handler
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setAddRecordForm },
        }}
      />
      </Box>

      {addRecordForm && (
      <DashboardListAdd open= {addRecordForm} close = {handleRecordAdded} />
      )}

      {editRecordForm && (
        <DashboardListEdit params = {editRowData} open= {editRecordForm} close = {handleRecordEdited} />
      )}

    
      {!!snackbar && (
        <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }}  open onClose={handleCloseSnackbar} autoHideDuration={4000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      
    
    </Paper>
    
    </div>
    
  );
}