import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { TextField, Button, FormControl, makeStyles } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";


import ConfigurationViewService from '../../services/configurationview.service';
import TableViewService from '../../services/tableview.service';
import AuditTrailViewService from '../../services/audittrailview.service';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

//function to remove keys from the each json object of the json Array.
function removeKeyValuesFromObjects(jsonArray, keysToRemove) {
  return jsonArray.map((item) => {
    const updatedObject = { ...item };

    keysToRemove.forEach((keyToRemove) => {
      delete updatedObject[keyToRemove];
    });

    return updatedObject;
  });
}

// Function to check json object is present in json array or not
function isObjectInArray(jsonArray, targetObject) {
  return jsonArray.some((item) => {
    for (const key in targetObject) {
      if (targetObject.hasOwnProperty(key) && item[key] !== targetObject[key]) {
        return false;
      }
    }
    return true;
  });
}


// Check blank values inside the row data
function hasBlankOrNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === '') {
      return true; // Found a blank or null value
    }

    if (typeof value === 'object' && hasBlankOrNullValues(value)) {
      return true; // Recursively check nested objects
    }
  }

  return false; // No blank or null values found
}

// Check value is numeric string or other string type
function checkValueType(value){
  if (typeof value === 'number' || (!isNaN(value) && typeof value !== 'boolean')) {
    // Check if it's a valid number or a string representation of a number
    //console.log('The value is a number:', parseFloat(value));
    return 'Number';
  } else if (typeof value === 'string') {
    //console.log('The value is a string:', value);
    return 'String';
  } else {
    //console.log('The value is neither a number nor a string.');
    return 'String';
  }
};


function checkType(input) {
  try{
    // Remove leading and trailing whitespace
    const trimmedInput = input.trim();

    // Check if it's a valid number
    if (/^-?\d*\.?\d+$/.test(trimmedInput)) {
      const numberValue = parseFloat(trimmedInput);
      if (Number.isInteger(numberValue)) {
        if (numberValue === 0 || numberValue === 1) {
          //return 'Digital';
          return 'Analog';
        } else {
          return 'Analog';
        }
      } else {
        return 'Analog';
      }
    }

    // Check if it's a string
    if (typeof input === 'string') {
      return 'String';
    }

    // Default to 'Invalid' if it doesn't match any valid format
    return 'Invalid';

  }catch{
    return 'Invalid';
  }
 
}

function validateSPValues (HiHi1, High1, Low1, LoLo1) {
  //console.log(HiHi1, High1, Low1, LoLo1)
  let HiHi = parseFloat(HiHi1);
  let High = parseFloat(High1);
  let Low = parseFloat(Low1);
  let LoLo = parseFloat(LoLo1);
  let message1 = "HiHi setpoint should be greater than High setpoint";
  let message2 = "High setpoint should be smaller than HiHi setpoint";
  let message3 = "High setpoint should be greater than Low setpoint";
  let message4 = "Low setpoint should be smaller than High setpoint";
  let message5 = "Low setpoint should be greater than LoLo setpoint"; 
  let message6 = "LoLo setpoint should be greater than Low setpoint";

  // Validate HiHi
  if (HiHi !== '' && HiHi !== 'NA' && High !== '' && High !== 'NA' && HiHi <= High) {
    return message1; // Validation fails
  }

  // Validate High
  if (High !== '' && High !== 'NA' && HiHi !== '' && HiHi !== 'NA' && High >= HiHi) {
    return message2; // Validation fails
  }
  if (High !== '' && High !== 'NA' && Low !== '' && Low !== 'NA' && High <= Low) {
    return message3; // Validation fails
  }

  // Validate Low
  if (Low !== '' && Low !== 'NA' && High !== '' && High !== 'NA' && Low >= High) {
    return message4; // Validation fails
  }
  if (Low !== '' && Low !== 'NA' && LoLo !== '' && LoLo !== 'NA' && Low <= LoLo) {
    return message5; // Validation fails
  }

  // Validate LoLo
  if (LoLo !== '' && LoLo !== 'NA' && Low !== '' && Low !== 'NA' && LoLo >= Low) {
    return message6; // Validation fails
  }


  return true; // Validation passes
}


function SaveChangesDialog(props) {

  const [open, setOpen] = useState(false);
  const [wrongValue, setWrongValue] = useState(false);

  const [message, setMessage] = useState();

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openInProgressdialog, setOpenInProgressdialog] =useState(false);
  const [openFaildialog, setOpenFaildialog] = useState(false);
  

  const [saveData, setSaveData] = useState(false);
  
  const[getTagData, setGetTagData] = React.useState(); 
  const[deviceTagData, setDeviceTagData] = React.useState();   
  const[systemTagData, setSystemTagData] = React.useState();
  const[alarmTagData, setAlarmTagData] = React.useState();
  const[tagDataFetchDN, setTagDataFetchDN] = React.useState(false);

  const[tagNames, setTagNames] = React.useState([]);

  const dashboardType = 'TableView';
  const widgetName = useSelector((state) => state.splitbutton1.widgetName);

  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);
  const [password, setPassword] = useState('');
  const[pwdinterlock, setPwdInterlock] = useState("Disabled");

  //Get Application Configuration data 
  useEffect(() =>{
    async function getColumnData()  {
      try {

        let dashboardName = "ApplicationConfig";
        const response = await ConfigurationViewService.getAllRowsData(dashboardName);

        if(response.data.length > 0){
          //setPwdInterlock(response.data[1].TaskStatus);
          //console.log(response.data); 
        }

        
        
      } catch (error) {
        console.log(error);

      }
    }
    getColumnData();



  }, []);

  const handleClickSaveChanges = () => { 
    setSaveData(true);
  };

  
  const handleClose = () => {
    setOpen(false);
    setWrongValue(false);
    setOpenRemarkBlnkMsg(false);
  };

  const handleSuccessClose = () => {
    setOpenSuccessDialog(false);
    props.success(true);
  };

  const handleInProgressClose = (reason) => {
    if (reason !== 'backdropClick') {
      //setOpenInProgressdialog(false);
    }
    
  };

  const handleFailedClose = () => {
    setOpenFaildialog(false);
  };

  
  // get all data from writeconfig collection
  useEffect(() =>{

    async function getAllRowsData()  {
      try {

        let dashboardName = "WriteConfig"

        const response = await ConfigurationViewService.getAllRowsData(dashboardName);
        if (response){
          setTagNames(response.data.map((item) => item.TagName)); 
          //console.log(response.data);
        }
        
        
      } catch (error) {
        console.log(error);

      }
    };
    getAllRowsData();


  }, []);

  

  //After getting Tag data from backend checek entere data in text field is valid or not, if valid tehn only proceed for save changes
  useEffect(() =>{
    const validateDataEntry = () => { 
        //console.log(props.rowData.ProcessValue, props.rowData.HiHiSP, props.rowData.HighSP, props.rowData.LowSP, props.rowData.LoLoSP)
        // For system and device tag check enter value is number or string
        const TagTypeOfProcessValue = checkType(props.rowData.ProcessValue)
        const TagTypeOfHiHiSP = checkType(props.rowData.HiHiSP)
        const TagTypeOfHighSP = checkType(props.rowData.HighSP)
        const TagTypeOfLowSP = checkType(props.rowData.LowSP)
        const TagTypeOfLoLoSP = checkType(props.rowData.LoLoSP)
        //console.log(TagTypeOfProcessValue, TagTypeOfHiHiSP, TagTypeOfHighSP, TagTypeOfLowSP, TagTypeOfLoLoSP)
        //console.log(props.tagStatus)
        //validate all SP are in range with respect to each other
        let validateSP = validateSPValues (props.rowData.HiHiSP, props.rowData.HighSP, props.rowData.LowSP, props.rowData.LoLoSP);
        if (validateSP === true){
          if(props.tagStatus === 'SystemTag'){
            //console.log(systemTagData.TagType)
            // if tag type is digital then write directly
            if(systemTagData.TagType === 'Digital'){  
              setOpen(true);
            }else{
              // check tag type and vlaidate it either string or number
              if(systemTagData.TagType === TagTypeOfProcessValue){  
                setOpen(true);
              }else{
                setWrongValue(true);
              }
            }
            
          }
  
          if(props.tagStatus === 'DeviceTag' ){
            //console.log(deviceTagData.TagType)
            // if tag type is digital then write directly
            if(deviceTagData.TagType === 'Digital'){ 
              setOpen(true);
            }else{
               // check tag type and vlaidate it either string or number
              if(deviceTagData.TagType === TagTypeOfProcessValue){
                setOpen(true);
              }else{
                setWrongValue(true);
              }
            }
            
          }
          if(props.tagStatus === 'AlarmTag' ){
            //console.log(alarmTagData)
            // For alram setpoint acept only numbers
            if((TagTypeOfHiHiSP === 'Analog') && (TagTypeOfHighSP === 'Analog') && (TagTypeOfLowSP === 'Analog') && (TagTypeOfLoLoSP === 'Analog')){
              setOpen(true);
              
            }else{
              setWrongValue(true);
            }
           
  
          }


        }else{
          setOpenFaildialog(true);
          setMessage(validateSP);
          setOpen(false); // Close "Save changes" window

        }
        
           
    };

    if(tagDataFetchDN === true){
      validateDataEntry();
      setTagDataFetchDN(false);
    }
  }, [tagDataFetchDN]);

  // As per tagStatus, get AlarmTagdata or SystemTag Data for specific TagName 
  useEffect(() =>{
    async function getRowData()  {
    try {
      //console.log(props.tagStatus)
      let data = props.rowData
      // Get row data by providing specific field name
      if(props.tagStatus === 'SystemTag'){
        let dashboardName = "ModbusTagList"
        let columnName = 'TagName'
        let columnValue = data.TagName
        const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue);
        if(response){
          //console.log(response.data[0]);
          setSystemTagData(response.data[0]);
          setTagDataFetchDN(true);
          
        }
      }

      if(props.tagStatus === 'DeviceTag'){
        let dashboardName = "ModbusTagList"
        let columnName = 'TagName'
        let columnValue = data.TagName
        const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue);
        if(response){
          //console.log(response.data[0]);
          setDeviceTagData(response.data[0]);
          setTagDataFetchDN(true);
          
        }
      }

      if(props.tagStatus === 'AlarmTag'){
        let dashboardName = "AlarmConfig"
        let columnName = 'TagName'
        let columnValue = data.TagName
        const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue);
        if(response){
          //console.log(response.data[0]);
          setAlarmTagData(response.data[0]);
          setTagDataFetchDN(true);
          
        }
      }
     
    } catch (error) {
      setOpenFaildialog(true);
      setMessage("Error While fetching parameter data, check server connection");
      setOpen(false); // Close "Save changes" window
      setTagDataFetchDN(false);
      console.log(error);
    }
  };
  if(getTagData === true){
    getRowData();
    setGetTagData(false);
  }
  
 
  }, [getTagData]);

  const handleClickOpen = () => {
    if(((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === "--")) || ((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === ""))){
      setOpenRemarkBlnkMsg(true);
    }else{

    setGetTagData(true);
    }

  }

  

  const [writeInProgress, setWriteInProgress] = useState(false);
  const [writeHiHiInProgress, setWriteHiHiInProgress] = useState(false);
  const [writeHighInProgress, setWriteHighInProgress] = useState(false);
  const [writeLowInProgress, setWriteLowInProgress] = useState(false);
  const [writeLoLoInProgress, setWriteLoLoInProgress] = useState(false);

  const [ReadRequest, setReadRequest] = useState(false);

  // operation depend upon WriteStatus result from python code, if success or fail proceed accordingly
  useEffect(() =>{
    async function readWriteConfigCollection(){
      try {
        //console.log(writeInProgress, writeHiHiInProgress, writeHighInProgress, writeLowInProgress, writeLoLoInProgress)
        //Get Tag info from writeconfig collection to check WriteStatus
        if(writeInProgress === true){
          let dashboardName1 = "WriteConfig";
          let columnName = 'TagName'
          let columnValue = deviceTagData.TagName;
          let data = {"TagName":props.rowData.TagName}
          let response12 = await TableViewService.readTag(dashboardName1, columnName, columnValue);
          if(response12.data.length > 0){
            let responseData12 = response12.data[0];
            if(responseData12.WriteStatus==="Success"){
              //Write Audit trail event here with
              let auditTrailData = {'EventStatus':props.rowData.EventStatus, 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
                'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
                'Activity': `Value of the parameter - ${props.rowData.TagName} was modified from '${props.initData.ProcessValue}' to '${responseData12.NewValue}'`
              };
              //console.log(auditTrailData)
              const response13 = await TableViewService.deleteTag(dashboardName1, data, auditTrailData);
              if(response13){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(true);
                setMessage("Parameter value updated Successfully");
                setSaveData(false);
                setOpen(false); // Close "Save changes" window

                setWriteInProgress(false);

              }
            }
            if(responseData12.WriteStatus==="Fail"){
              
              //Disable EventStatus, hence audittrail will not happen, but tag delete from writeconfig can happen
              let auditTrailData = {'EventStatus':"Disabled", 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
                'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
                'Activity': `Value of the parameter - ${props.rowData.TagName} was modified from '${props.initData.ProcessValue}' to '${responseData12.NewValue}'`
              };
              //console.log(auditTrailData)
              const response13 = await TableViewService.deleteTag(dashboardName1, data, auditTrailData);
              if(response13){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(false);
                setOpenFaildialog(true);
                setMessage("Write to parameter failed. Either no change in value or device/server is down ");
                setOpen(false); // Close "Save changes" window

                setWriteInProgress(false);

              }

            }
            if(responseData12.WriteStatus==="InProgress"){
              setWriteInProgress(true);
            }

          }

        }
        if(writeHiHiInProgress === true){
          let dashboardName1 = "WriteConfig";
          let columnName = 'TagName'
          let columnValue = alarmTagData.TagNameHiHiSP;
          let data = {"TagName":alarmTagData.TagNameHiHiSP}
          let response12 = await TableViewService.readTag(dashboardName1, columnName, columnValue);
          if(response12.data.length > 0){
            let responseData12 = response12.data[0];
            if(responseData12.WriteStatus==="Success"){
              //Write Audit trail event here with
              let auditTrailData = {'EventStatus':props.rowData.EventStatus, 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
                'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
                'Activity': `For parameter - ${alarmTagData.TagName} HiHi Setpoint was modified from '${props.initData.HiHiSP}' to '${responseData12.NewValue}'`
                //'Activity': `Value of the parameter - ${alarmTagData.TagNameHiHiSP} was modified from '${props.initData.HiHiSP}' to '${responseData12.NewValue}'`
              };
              //console.log(auditTrailData)
              const response13 = await TableViewService.deleteTag(dashboardName1, data, auditTrailData);
              if(response13){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(true);
                setMessage("Parameter value updated Successfully");
                setSaveData(false);
                setOpen(false); // Close "Save changes" window

                setWriteHiHiInProgress(false);

              }
            }
            if(responseData12.WriteStatus==="Fail"){
              
              //Disable EventStatus, hence audittrail will not happen, but tag delete from writeconfig can happen
              let auditTrailData = {'EventStatus':"Disabled", 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
                'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
                'Activity': `For parameter - ${alarmTagData.TagName} HiHi Setpoint was modified from '${props.initData.HiHiSP}' to '${responseData12.NewValue}'`
                //'Activity': `Value of the parameter - ${alarmTagData.TagNameHiHiSP} was modified from '${props.initData.hiHiSP}' to '${responseData12.NewValue}'`
              };
              //console.log(auditTrailData)
              const response13 = await TableViewService.deleteTag(dashboardName1, data, auditTrailData);
              if(response13){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(false);
                setOpenFaildialog(true);
                setMessage("Write to parameter failed. Either no change in value or device/server is down ");
                setOpen(false); // Close "Save changes" window

                setWriteHiHiInProgress(false);

              }

            }
            if(responseData12.WriteStatus==="InProgress"){
              setWriteHiHiInProgress(true);
            }

          }

        }
        if(writeHighInProgress === true){
          let dashboardName1 = "WriteConfig";
          let columnName = 'TagName'
          let columnValue = alarmTagData.TagNameHighSP;
          let data = {"TagName":alarmTagData.TagNameHighSP}
          let response12 = await TableViewService.readTag(dashboardName1, columnName, columnValue);
          if(response12.data.length > 0){
            let responseData12 = response12.data[0];
            //console.log(responseData12.WriteStatus)
            if(responseData12.WriteStatus==="Success"){
              //Write Audit trail event here with
              let auditTrailData = {'EventStatus':props.rowData.EventStatus, 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
                'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
                'Activity': `For parameter - ${alarmTagData.TagName} High Setpoint was modified from '${props.initData.HighSP}' to '${responseData12.NewValue}'`
                //'Activity': `Value of the parameter - ${alarmTagData.TagNameHighSP} was modified from '${props.initData.HighSP}' to '${responseData12.NewValue}'`
              };
              const response13 = await TableViewService.deleteTag(dashboardName1, data, auditTrailData);
              if(response13){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(true);
                setMessage("Parameter value updated Successfully");
                setSaveData(false);
                setOpen(false); // Close "Save changes" window

                setWriteHighInProgress(false);

              }
            }
            if(responseData12.WriteStatus==="Fail"){
              
              //Disable EventStatus, hence audittrail will not happen, but tag delete from writeconfig can happen
              let auditTrailData = {'EventStatus':"Disabled", 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
                'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
                'Activity': `For parameter - ${alarmTagData.TagName} High Setpoint was modified from '${props.initData.HighSP}' to '${responseData12.NewValue}'`
                //'Activity': `Value of the parameter - ${alarmTagData.TagNameHighSP} was modified from '${props.initData.HighSP}' to '${responseData12.NewValue}'`
              };
              //console.log(auditTrailData)
              const response13 = await TableViewService.deleteTag(dashboardName1, data, auditTrailData);
              if(response13){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(false);
                setOpenFaildialog(true);
                setMessage("Write to parameter failed. Either no change in value or device/server is down ");
                setOpen(false); // Close "Save changes" window

                setWriteHighInProgress(false);

              }

            }
            if(responseData12.WriteStatus==="InProgress"){
              setWriteHighInProgress(true);
            }

          }

        }
        if(writeLowInProgress === true){
          let dashboardName1 = "WriteConfig";
          let columnName = 'TagName'
          let columnValue = alarmTagData.TagNameLowSP;
          let data = {"TagName":alarmTagData.TagNameLowSP}
          let response12 = await TableViewService.readTag(dashboardName1, columnName, columnValue);
          if(response12.data.length > 0){
            let responseData12 = response12.data[0];
            if(responseData12.WriteStatus==="Success"){
              //Write Audit trail event here with
              let auditTrailData = {'EventStatus':props.rowData.EventStatus, 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
                'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
                'Activity': `For parameter - ${alarmTagData.TagName} Low Setpoint was modified from '${props.initData.LowSP}' to '${responseData12.NewValue}'`
                //'Activity': `Value of the parameter - ${alarmTagData.TagNameLowSP} was modified from '${props.initData.LowSP}' to '${responseData12.NewValue}'`
              };
              //console.log(auditTrailData)
              const response13 = await TableViewService.deleteTag(dashboardName1, data, auditTrailData);
              if(response13){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(true);
                setMessage("Parameter value updated Successfully");
                setSaveData(false);
                setOpen(false); // Close "Save changes" window

                setWriteLowInProgress(false);

              }
            }
            if(responseData12.WriteStatus==="Fail"){
              
              //Disable EventStatus, hence audittrail will not happen, but tag delete from writeconfig can happen
              let auditTrailData = {'EventStatus':"Disabled", 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
                'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
                'Activity': `For parameter - ${alarmTagData.TagName} Low Setpoint was modified from '${props.initData.LowSP}' to '${responseData12.NewValue}'`
                //'Activity': `Value of the parameter - ${alarmTagData.TagNameLowSP} was modified from '${props.initData.LowSP}' to '${responseData12.NewValue}'`
              };
              //console.log(auditTrailData)
              const response13 = await TableViewService.deleteTag(dashboardName1, data, auditTrailData);
              if(response13){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(false);
                setOpenFaildialog(true);
                setMessage("Write to parameter failed. Either no change in value or device/server is down ");
                setOpen(false); // Close "Save changes" window

                setWriteLowInProgress(false);

              }

            }
            if(responseData12.WriteStatus==="InProgress"){
              setWriteLowInProgress(true);
            }

          }

        }
        if(writeLoLoInProgress === true){
          let dashboardName1 = "WriteConfig";
          let columnName = 'TagName'
          let columnValue = alarmTagData.TagNameLoLoSP;
          let data = {"TagName":alarmTagData.TagNameLoLoSP}
          let response12 = await TableViewService.readTag(dashboardName1, columnName, columnValue);
          if(response12.data.length > 0){
            let responseData12 = response12.data[0];
            if(responseData12.WriteStatus==="Success"){
              //Write Audit trail event here with
              let auditTrailData = {'EventStatus':props.rowData.EventStatus, 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
                'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
                'Activity': `For parameter - ${alarmTagData.TagName} LoLo Setpoint was modified from '${props.initData.LoLoSP}' to '${responseData12.NewValue}'`
                //'Activity': `Value of the parameter - ${alarmTagData.TagNameLoLoSP} was modified from '${props.initData.LoLoSP}' to '${responseData12.NewValue}'`
              };
              //console.log(auditTrailData)
              const response13 = await TableViewService.deleteTag(dashboardName1, data, auditTrailData);
              if(response13){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(true);
                setMessage("Parameter value updated Successfully");
                setSaveData(false);
                setOpen(false); // Close "Save changes" window

                setWriteLoLoInProgress(false);

              }
            }
            if(responseData12.WriteStatus==="Fail"){
              
              //Disable EventStatus, hence audittrail will not happen, but tag delete from writeconfig can happen
              let auditTrailData = {'EventStatus':"Disabled", 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
                'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
                'Activity': `For parameter - ${alarmTagData.TagName} LoLo Setpoint was modified from '${props.initData.LoLoSP}' to '${responseData12.NewValue}'`
                //'Activity': `Value of the parameter - ${alarmTagData.TagNameLoLoSP} was modified from '${props.initData.LoLoSP}' to '${responseData12.NewValue}'`
              };
              //console.log(auditTrailData)
              const response13 = await TableViewService.deleteTag(dashboardName1, data, auditTrailData);
              if(response13){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(false);
                setOpenFaildialog(true);
                setMessage("Write to parameter failed. Either no change in value or device/server is down ");
                setOpen(false); // Close "Save changes" window

                setWriteLoLoInProgress(false);

              }

            }
            if(responseData12.WriteStatus==="InProgress"){
              setWriteLoLoInProgress(true);
            }

          }

        }
      }catch(error){
        setWriteInProgress(false)
        setWriteHiHiInProgress(false)
        setWriteHighInProgress(false)
        setWriteLowInProgress(false)
        setWriteLoLoInProgress(false)
        setReadRequest(false)
        setOpenInProgressdialog(false)

        setOpenFaildialog(true);
        setMessage("Error occured while fetching write status of the parameter");
        setOpen(false); // Close "Save changes" window
        console.log(error);
      }
      

    };
    if(ReadRequest === true){
      readWriteConfigCollection();
      setReadRequest(false);
    };
  }, [ReadRequest]);

  //Check perodically
  useEffect(() =>{
    const intervalId = setInterval(() => {
      if((writeInProgress === true || writeHiHiInProgress === true || writeHighInProgress === true || writeLowInProgress === true || writeLoLoInProgress === true) && ReadRequest === false){
        //console.log("test")
        //setOpenInProgressdialog(true);
        //setMessage("Parameter value writing is in progress, please wait...");
        setReadRequest(true);

      }
    },1000);
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [writeInProgress, writeHiHiInProgress, writeHighInProgress, writeLowInProgress, writeLoLoInProgress]);

  // As per tagStatus, update AlarmConfig collection to set alarm SP values OR update system tag PV value 
  useEffect(() =>{
    async function saveRowData()  {
    try {

      if(props.tagStatus === 'SystemTag'){

        let dashboardName1 = "WriteConfig";
        let dashboardName2 = "ModbusTagList";
        let dashboardName3 = "AuditTrailLog";
        systemTagData['ProcessValue'] = props.rowData.ProcessValue;
        //systemTagData['WriteStatus'] = 'Success';
        //Update device tag data & Added email, role, widgetname, old & new value to update those in audittrail log
        let rowData = {...systemTagData, email: props.email, role: props.role, PWDInterlock: props.rowData.PWDInterlock, PWD:password};
        
        if(props.rowData.ProcessValue === props.initData.ProcessValue){
          setOpenInProgressdialog(false);
          setOpenSuccessDialog(false);
          setOpenFaildialog(true);
          setMessage("There is no cahnge observed in parameter value.");
          setOpen(false); // Close "Save changes" window

        }else{
          const response1 = await TableViewService.updateTag(dashboardName2, rowData);
          //let response = 1;
          if(response1){
            //console.log(response1.data);
            let auditTrailData = {'EventStatus':props.rowData.EventStatus, 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
              'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
              'Activity': `Value of the parameter - ${props.rowData.TagName} was modified from '${props.initData.ProcessValue}' to '${props.rowData.ProcessValue}'`
            };
            //console.log(auditTrailData)
            //Check audittrail log is enabled or not
            if(props.rowData.EventStatus === "Enabled"){
              const response2 = await AuditTrailViewService.createRow(dashboardName3, auditTrailData);
              if(response2){
                setOpenInProgressdialog(false);
                setOpenSuccessDialog(true);
                setMessage("Parameter value updated Successfully");
                setSaveData(false);
                setOpen(false); // Close "Save changes" window

              }

            }else{
              setOpenInProgressdialog(false);
              setOpenSuccessDialog(true);
              setMessage("Parameter value updated Successfully");
              setSaveData(false);
              setOpen(false); // Close "Save changes" window

            }

          }else{
            setOpenInProgressdialog(true);
            setMessage("Parameter value writing is in progress, please wait...");
          }

        }
        
      }

      if(props.tagStatus === 'DeviceTag'){

        let dashboardName1 = "WriteConfig";
        // If Tag value is not different than orignal then not to proceed
        if(props.rowData.ProcessValue === props.initData.ProcessValue){
          setOpenInProgressdialog(false);
          setOpenSuccessDialog(false);
          setOpenFaildialog(true);
          setMessage("There is no cahnge observed in parameter value.");
          setOpen(false); // Close "Save changes" window

        }else{
          //Check TagName is present in writeconfig collection or not, if present then update value else create new record.
          if(tagNames.includes(props.rowData.TagName)){
            let columnName = 'TagName'
            let columnValue = props.rowData.TagName;
            let response10 = await TableViewService.readTag(dashboardName1, columnName, columnValue);
            if (response10.data[0].length > 0){
              let responseData10 = response10.data[0];
              responseData10['OldValue'] = props.initData.ProcessValue;
              responseData10['NewValue'] = props.rowData.ProcessValue;
              responseData10['email'] = props.email;
              responseData10['role'] = props.role;
              responseData10['PWDInterlock'] = props.rowData.PWDInterlock;
              responseData10['PWD'] = password;
              responseData10['WriteStatus'] = 'InProgress';
              let response11 = await TableViewService.updateTag(dashboardName1, responseData10);
              if(response11){
                setWriteInProgress(true);
                setOpenInProgressdialog(true);
                setMessage("Parameter value writing is in progress, please wait...");
                  
              }
              
            }

          }else{
            let data = {};
            data['TagStatus'] = 'DeviceTag';
            data['TagName'] = props.rowData.TagName;
            data['OldValue'] = props.initData.ProcessValue;
            data['NewValue'] = props.rowData.ProcessValue;
            let response12 = await TableViewService.createTag(dashboardName1, data);
            if(response12){
              setWriteInProgress(true);
              setOpenInProgressdialog(true);
              setMessage("Parameter value writing is in progress, please wait...");
                
            }

          }
          


        }
                
      }

      if(props.tagStatus === 'AlarmTag'){

        let dashboardName1 = "WriteConfig";
  
        if((props.initData.HiHiSP === props.rowData.HiHiSP) && (props.initData.HighSP === props.rowData.HighSP) && (props.initData.LowSP === props.rowData.LowSP) && (props.initData.LoLoSP === props.rowData.LoLoSP)){
          setOpenInProgressdialog(false);
          setOpenSuccessDialog(false);
          setOpenFaildialog(true);
          setMessage("There is no cahnge observed in parameter value.");
          setOpen(false); // Close "Save changes" window

        }else{
          //If device tag is assigned for HiHi alarm tag
          if(alarmTagData.HiHiDeviceTag === 'true' && (props.initData.HiHiSP !== props.rowData.HiHiSP)){

            // Check TagName already present or not in writeconfig collection, if present update record else create new record.
            if(tagNames.includes(alarmTagData.TagNameHiHiSP)){ 
              let columnName = 'TagName'
              let columnValue = alarmTagData.TagNameHiHiSP;
              let response10 = await TableViewService.readTag(dashboardName1, columnName, columnValue);
              if(response10){
                let responseData10 = response10.data[0];
                responseData10['OldValue'] = props.initData.HiHiSP;
                responseData10['NewValue'] = props.rowData.HiHiSP;
                responseData10['email'] = props.email;
                responseData10['role'] = props.role;
                responseData10['PWDInterlock'] = props.rowData.PWDInterlock;
                responseData10['PWD'] = password;
                responseData10['WriteStatus'] = 'InProgress';
                let response11 = await TableViewService.updateTag(dashboardName1, responseData10);
                if(response11){
                  setWriteHiHiInProgress(true);
                  setOpenInProgressdialog(true);
                  setMessage("Parameter value writing is in progress, please wait...");
                    
                }
                
              }
              
            }else{
              let data = {};
              data['TagStatus'] = 'DeviceTag';
              data['TagName'] = alarmTagData.TagNameHiHiSP;
              data['OldValue'] = props.initData.HiHiSP;
              data['NewValue'] = props.rowData.HiHiSP;
              data['WriteStatus'] = 'InProgress';
              let response12 = await TableViewService.createTag(dashboardName1, data);
              if(response12){
                setWriteHiHiInProgress(true);
                setOpenInProgressdialog(true);
                setMessage("Parameter value writing is in progress, please wait...");
                  
              }
            }            
          }
          //If device tag is not assigned for HiHi alarm tag
          if(alarmTagData.HiHiDeviceTag === 'false' && (props.initData.HiHiSP !== props.rowData.HiHiSP)){
            let dashboardName1 = "AlarmConfig";
            let rowData = {...alarmTagData, HiHiSP:props.rowData.HiHiSP, 'EventStatus':props.rowData.EventStatus, email: props.email, role: props.role, PWDInterlock: props.rowData.PWDInterlock, PWD:password};
            let auditTrailData = {'EventStatus':props.rowData.EventStatus, 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
              'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
              'Activity': `For parameter - ${alarmTagData.TagName} HiHi setpoint was modified from '${props.initData.HiHiSP}' to '${props.rowData.HiHiSP}'`
            };
            const response20 = await ConfigurationViewService.updateRowData(dashboardName1, rowData, auditTrailData);
            //let response = 1;
            if(response20){
              setOpenInProgressdialog(false);
              setOpenSuccessDialog(true);
              setMessage("Parameter value updated Successfully");
              setSaveData(false);
              setOpen(false); // Close "Save changes" window

            }else{
              setOpenInProgressdialog(false);
              setOpenSuccessDialog(false);
              setOpenFaildialog(true);
              setMessage("Write to parameter failed. Either no change in value or device/server is down ");
              setOpen(false); // Close "Save changes" window
            }
                       
          }
          if(alarmTagData.HighDeviceTag === 'true' && (props.initData.HighSP !== props.rowData.HighSP)){

            // Check TagName already present or not in writeconfig collection, if present update record else create new record.
            if(tagNames.includes(alarmTagData.TagNameHighSP)){ 
              let columnName = 'TagName'
              let columnValue = alarmTagData.TagNameHighSP;
              let response10 = await TableViewService.readTag(dashboardName1, columnName, columnValue);
              if(response10){
                let responseData10 = response10.data[0];
                responseData10['OldValue'] = props.initData.HighSP;
                responseData10['NewValue'] = props.rowData.HighSP;
                responseData10['email'] = props.email;
                responseData10['role'] = props.role;
                responseData10['PWDInterlock'] = props.rowData.PWDInterlock;
                responseData10['PWD'] = password;
                responseData10['WriteStatus'] = 'InProgress';
                let response11 = await TableViewService.updateTag(dashboardName1, responseData10);
                if(response11){
                  setWriteHighInProgress(true);
                  setOpenInProgressdialog(true);
                  setMessage("Parameter value writing is in progress, please wait...");
                    
                }
                
              }
              
            }else{
              let data = {};
              data['TagStatus'] = 'DeviceTag';
              data['TagName'] = alarmTagData.TagNameHighSP;
              data['OldValue'] = props.initData.HighSP;
              data['NewValue'] = props.rowData.HighSP;
              data['WriteStatus'] = 'InProgress';
              let response12 = await TableViewService.createTag(dashboardName1, data);
              if(response12){
                setWriteHighInProgress(true);
                setOpenInProgressdialog(true);
                setMessage("Parameter value writing is in progress, please wait...");
                  
              }
            }            
          }
          //If device tag is not assigned for High alarm tag
          if(alarmTagData.HighDeviceTag === 'false' && (props.initData.HighSP !== props.rowData.HighSP)){
            let dashboardName1 = "AlarmConfig";
            let rowData = {...alarmTagData, HighSP:props.rowData.HighSP, 'EventStatus':props.rowData.EventStatus, email: props.email, role: props.role, PWDInterlock: props.rowData.PWDInterlock, PWD:password};
            let auditTrailData = {'EventStatus':props.rowData.EventStatus, 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
              'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
              'Activity': `For parameter - ${alarmTagData.TagName} High setpoint was modified from '${props.initData.HighSP}' to '${props.rowData.HighSP}'`
            };
            const response20 = await ConfigurationViewService.updateRowData(dashboardName1, rowData, auditTrailData);
            //let response = 1;
            if(response20){
              setOpenInProgressdialog(false);
              setOpenSuccessDialog(true);
              setMessage("Parameter value updated Successfully");
              setSaveData(false);
              setOpen(false); // Close "Save changes" window

            }else{
              setOpenInProgressdialog(false);
              setOpenSuccessDialog(false);
              setOpenFaildialog(true);
              setMessage("Write to parameter failed. Either no change in value or device/server is down ");
              setOpen(false); // Close "Save changes" window
            }
                       
          }
          if(alarmTagData.LowDeviceTag === 'true' && (props.initData.LowSP !== props.rowData.LowSP)){

            // Check TagName already present or not in writeconfig collection, if present update record else create new record.
            if(tagNames.includes(alarmTagData.TagNameLowSP)){ 
              let columnName = 'TagName'
              let columnValue = alarmTagData.TagNameLowSP;
              let response10 = await TableViewService.readTag(dashboardName1, columnName, columnValue);
              if(response10){
                let responseData10 = response10.data[0];
                responseData10['OldValue'] = props.initData.LowSP;
                responseData10['NewValue'] = props.rowData.LowSP;
                responseData10['email'] = props.email;
                responseData10['role'] = props.role;
                responseData10['PWDInterlock'] = props.rowData.PWDInterlock;
                responseData10['PWD'] = password;
                responseData10['WriteStatus'] = 'InProgress';
                let response11 = await TableViewService.updateTag(dashboardName1, responseData10);
                if(response11){
                  setWriteLowInProgress(true);
                  setOpenInProgressdialog(true);
                  setMessage("Parameter value writing is in progress, please wait...");
                    
                }
                
              }
              
            }else{
              let data = {};
              data['TagStatus'] = 'DeviceTag';
              data['TagName'] = alarmTagData.TagNameLowSP;
              data['OldValue'] = props.initData.LowSP;
              data['NewValue'] = props.rowData.LowSP;
              data['WriteStatus'] = 'InProgress';
              //console.log(data)
              let response12 = await TableViewService.createTag(dashboardName1, data);
              if(response12){
                setWriteLowInProgress(true);
                setOpenInProgressdialog(true);
                setMessage("Parameter value writing is in progress, please wait...");
                  
              }
            }            
          }
          //If device tag is not assigned for Low alarm tag
          if(alarmTagData.LowDeviceTag === 'false' && (props.initData.LowSP !== props.rowData.LowSP)){
            let dashboardName1 = "AlarmConfig";
            let rowData = {...alarmTagData, LowSP:props.rowData.LowSP, 'EventStatus':props.rowData.EventStatus, email: props.email, role: props.role, PWDInterlock: props.rowData.PWDInterlock, PWD:password};
            let auditTrailData = {'EventStatus':props.rowData.EventStatus, 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
              'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
              'Activity': `For parameter - ${alarmTagData.TagName} Low setpoint was modified from '${props.initData.LowSP}' to '${props.rowData.LowSP}'`
            };
            const response20 = await ConfigurationViewService.updateRowData(dashboardName1, rowData, auditTrailData);
            //let response = 1;
            if(response20){
              setOpenInProgressdialog(false);
              setOpenSuccessDialog(true);
              setMessage("Parameter value updated Successfully");
              setSaveData(false);
              setOpen(false); // Close "Save changes" window

            }else{
              setOpenInProgressdialog(false);
              setOpenSuccessDialog(false);
              setOpenFaildialog(true);
              setMessage("Write to parameter failed. Either no change in value or device/server is down ");
              setOpen(false); // Close "Save changes" window
            }
                       
          }
          if(alarmTagData.LoLoDeviceTag === 'true' && (props.initData.LoLoSP !== props.rowData.LoLoSP)){

            // Check TagName already present or not in writeconfig collection, if present update record else create new record.
            if(tagNames.includes(alarmTagData.TagNameLoLoSP)){ 
              let columnName = 'TagName'
              let columnValue = alarmTagData.TagNameLoLoSP;
              let response10 = await TableViewService.readTag(dashboardName1, columnName, columnValue);
              if(response10){
                let responseData10 = response10.data[0];
                responseData10['OldValue'] = props.initData.LoLoSP;
                responseData10['NewValue'] = props.rowData.LoLoSP;
                responseData10['email'] = props.email;
                responseData10['role'] = props.role;
                responseData10['PWDInterlock'] = props.rowData.PWDInterlock;
                responseData10['PWD'] = password;
                responseData10['WriteStatus'] = 'InProgress';
                let response11 = await TableViewService.updateTag(dashboardName1, responseData10);
                if(response11){
                  setWriteLoLoInProgress(true);
                  setOpenInProgressdialog(true);
                  setMessage("Parameter value writing is in progress, please wait...");
                    
                }
                
              }
              
            }else{
              let data = {};
              data['TagStatus'] = 'DeviceTag';
              data['TagName'] = alarmTagData.TagNameLoLoSP;
              data['OldValue'] = props.initData.LoLoSP;
              data['NewValue'] = props.rowData.LoLoSP;
              data['WriteStatus'] = 'InProgress';
              let response12 = await TableViewService.createTag(dashboardName1, data);
              if(response12){
                setWriteLoLoInProgress(true);
                setOpenInProgressdialog(true);
                setMessage("Parameter value writing is in progress, please wait...");
                  
              }
            }            
          }
          //If device tag is not assigned for LoLo alarm tag
          //console.log(alarmTagData.LoLoDeviceTag, props.initData.LoLoSP, props.rowData.LoLoSP, props.rowData.EventStatus)
          if(alarmTagData.LoLoDeviceTag === 'false' && (props.initData.LoLoSP !== props.rowData.LoLoSP)){
            let dashboardName1 = "AlarmConfig";
            let rowData = {...alarmTagData, LoLoSP:props.rowData.LoLoSP, 'EventStatus':props.rowData.EventStatus, email: props.email, role: props.role, PWDInterlock: props.rowData.PWDInterlock, PWD:password};
            let auditTrailData = {'EventStatus':props.rowData.EventStatus, 'ActivityType':'Parameter Value Change', 'Remark':props.auditTrailRemark,
              'UserName':props.email, 'Role': props.role, 'TimeStamp': new Date(),
              'Activity': `For parameter - ${alarmTagData.TagName} LoLo setpoint was modified from '${props.initData.LoLoSP}' to '${props.rowData.LoLoSP}'`
            };
            const response20 = await ConfigurationViewService.updateRowData(dashboardName1, rowData, auditTrailData);
            //let response = 1;
            if(response20){
              setOpenInProgressdialog(false);
              setOpenSuccessDialog(true);
              setMessage("Parameter value updated Successfully");
              setSaveData(false);
              setOpen(false); // Close "Save changes" window

            }else{
              setOpenInProgressdialog(false);
              setOpenSuccessDialog(false);
              setOpenFaildialog(true);
              setMessage("Write to parameter failed. Either no change in value or device/server is down ");
              setOpen(false); // Close "Save changes" window
            }
                       
          }

        }
        
      }
      
    } catch (error) {
      setOpenInProgressdialog(false);
      setOpenSuccessDialog(false);
      setOpenFaildialog(true);
      setMessage("Error While Adding Record, Please very all parameters, password entered in form or server is down ");
      setOpen(false); // Close "Save changes" window
      //console.log(error);


    }
  };
  if(saveData){
    saveRowData();
    setSaveData(false);
  };


  }, [saveData]);
  

  return (
    
    <div>
      <Button variant="outlined" startIcon ={<SaveIcon/>} sx={{mt:1}} onClick={handleClickOpen}>SAVE CHANGES </Button>

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Open Confirmation dialog box with or without password entry */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Save changes in value of parameter</DialogTitle>
        <Box sx={{width: 400 }}>
          <DialogContent dividers>
            <DialogContentText>
              Are you sure to update the Changes?.
            </DialogContentText>
            
          </DialogContent>

          {props.rowData.PWDInterlock === 'Enabled' && (<DialogContent dividers>
            <DialogContentText>
              Enter the Password to make changes in parameter value.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="Password"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />

          </DialogContent>
          )}

        </Box>
        <DialogActions>
          <Button onClick={handleClickSaveChanges}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Open wrong value enetered dialog box*/}
      <Dialog open={wrongValue} onClose={handleClose}>
        <DialogTitle>Wrong Value</DialogTitle>
        <Box sx={{width: 400 }}>
        <DialogContent dividers>
          <DialogContentText>
            Wrong Value or data entered. Please Correct it.
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>ok</Button>
        </DialogActions>
      </Dialog>

      {/* Success dialog after upgradtion of the parameter */}
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessClose}
       
      >
        <DialogTitle > success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Save Changes in progress dialog  */}
      <Dialog
        open={openInProgressdialog}
        onClose={handleInProgressClose}
       
      >
        <DialogTitle > In Progress.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
       
      </Dialog>

      {/* Save Changes Failed dialog /Wrong Password */}
      <Dialog
        open={openFaildialog}
        onClose={handleFailedClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFailedClose}>Close</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}


export default function DashboardEdit ({params, open, close}) {
  const { user } = useSelector((state) => state.auth);
  const initialWriteData = {
    TagName: params.row.TagName,
    ProcessValue: params.row.ProcessValue,
    HiHiSP:params.row.HiHiSP,
    HighSP:params.row.HighSP,
    LoLoSP:params.row.LoLoSP,
    LowSP:params.row.LowSP, 

    role:user.role,
    email: user.email,  
    RemarkStatus : "Disabled",
    EventStatus:"Disabled",
    PWDInterlock:"Disabled",

  };
  const [eventDescription, setEventDescription] = useState("--");
  const [auditTrailRemark, setAuditTrailRemark] = useState("--");
  // parameter list for audit trail log
  const oldValues = {ProcessValue: params.row.ProcessValue, HiHiSP:params.row.HiHiSP, HighSP:params.row.HighSP,
    LoLoSP:params.row.LoLoSP, TagUnit: params.row.TagUnit, LowSP:params.row.LowSP,};

  const [newValues, setNewValues] = useState(oldValues); 
 //console.log(params.row)
  const[tagTypeSetDN, setTagTypeSetDN] = React.useState(false);
  const[tagStatus, setTagStatus] = React.useState();
  const[writeStatus, setWriteStatus] = React.useState(false);
  const[TagName, setTagName] = React.useState(params.row.TagName);
  const[functionCode, setFunctionCode] = React.useState();
  const[ProcessValue, setProcessValue] = React.useState(params.row.ProcessValue);
  
  const[HiHiSP, setHiHiSP] = React.useState(params.row.HiHiSP);
  const[HighSP, setHighSP] = React.useState(params.row.HighSP);
  const[LoLoSP, setLoLoSP] = React.useState(params.row.LoLoSP);
  const[LowSP, setLowSP] = React.useState(params.row.LowSP);
  const[alarmType, setAlarmType] = React.useState('');
  const[alarmSetpoint, setAlarmSetpoint] = React.useState(HiHiSP);

  const [writeData, setWriteData] = React.useState(initialWriteData);
  const [valueType, setValueType] = React.useState('Number');
  const [dataType, setDataType] = React.useState('Integer');
  const [onLabel, setOnLabel] = React.useState('ON');
  const [offLabel, setOffLabel] = React.useState('OFF');


  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Setpoint Change';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setWriteData({...writeData, EventStatus: response.data[0].EnableEvent, PWDInterlock: response.data[0].EnablePwdInterlock, RemarkStatus: response.data[0].EnableRemark});

        setEventDescription(response.data[0].DefaultEventDescription);
        // If remark field is disabled then set default remark vlaue to currrent Remark value
        if(response.data[0].EnableRemark === "Disabled"){
          setAuditTrailRemark(response.data[0].DefaultEventRemark);
        }
      }
  
    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);


  // Get data of specific columns from ModbusTagList table for specific tagname
  useEffect(() =>{
    async function getModbusTagListData()  {
    try {

      let dashboardName = "ModbusTagList";
      let columnsToRetrieve = ["DataType", "OnLabel" , "OffLabel" ];
      let columnToFilter ="TagName";
      let valueToMatch = params.row.TagName;

      
      const response = await TableViewService.getMultiColumnsRows(dashboardName, columnsToRetrieve, columnToFilter, valueToMatch );
      //console.log(response.data);
      if(response){
        let  jsonArray = response.data;
        const updatedArray = jsonArray.map((obj) => {
          setDataType(obj.DataType);
          setOnLabel(obj.OnLabel);
          setOffLabel(obj.OffLabel);
        });
          
          setValueType(checkValueType(params.row.ProcessValue));
      }
  
    } catch (error) {
      console.log(error);

    }
  }

  getModbusTagListData();

  }, []);
  
  // SetTagType here with. after setting done only procedd to get alarmconfig data from backend
  useEffect(() =>{
    async function getRowData()  {
    try {
      //setWriteData({...writeData, 'ProcessValue': params.row.ProcessValue, 'HiHiSP': params.row.HiHiSP,  'HighSP': params.row.HighSP,  'LoLoSP': params.row.LoLoSP,  'LowSP': params.row.LowSP,});
      const jsonObject = params.row;
      if(jsonObject.TagStatus === 'DeviceTag'){
        if (params.row.hasOwnProperty('HiHiSP')) {
          setTagStatus('AlarmTag');

        }else{
          setTagStatus('DeviceTag');
        }

      }
      if(params.row.TagStatus === 'SystemTag'){
        setTagStatus('SystemTag');
      }
      setTagTypeSetDN(true);
      //console.log(params.row);
    
    } catch (error) {
      console.log(error);

    }
  };
  getRowData();

  }, []);

  //Get Function Code

  useEffect(() =>{
    async function getFunctionCode()  {
    try {
      let dashboardName = 'ModbusTagList';
      let columnName = 'TagName';
      let columnValue = params.row.TagName;
      let response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue);
      if(response){
        setFunctionCode(parseInt(response.data.map((item) => item.FunctionCode)));

      }


    } catch (error) {
      console.log(error);

    }
  };
  
  getFunctionCode();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [alarmTypeList, setAlarmTypeList] = useState({
    HiHiSP: "",
    HighSP: "",
    LowSP: "",
    LoLoSP: ""
  });
  // Get alarmCongig data for specific tagname
  useEffect(() =>{
    async function getAlarmConfigtData()  {
    try {

      let dashboardName = "AlarmConfig"
      let columnName = 'TagName'
      let columnValue = TagName
      if(tagStatus === 'AlarmTag'){
        const response = await ConfigurationViewService.getColumnData(dashboardName, columnName, columnValue);
        if (response) {
            const updatedAlarmTypeList = {
            HiHiSP: response.data[0].HiHiEnable === "Enabled" ? "HiHiSP" : undefined,
            HighSP: response.data[0].HighEnable === "Enabled" ? "HighSP" : undefined,
            LowSP: response.data[0].LowEnable === "Enabled" ? "LowSP" : undefined,
            LoLoSP: response.data[0].LoLoEnable === "Enabled" ? "LoLoSP" : undefined
            }

          // Filter out undefined values
          const filteredAlarmTypeList = Object.fromEntries(
            Object.entries(updatedAlarmTypeList).filter(([key, value]) => value !== undefined)
          );

          setAlarmTypeList(filteredAlarmTypeList);
          // Set default value for alarmType
          const firstKey = Object.keys(filteredAlarmTypeList)[0]; // Get the first key
          setAlarmType(filteredAlarmTypeList[firstKey]); // Set alarmType to the value of the first key
          // Set appropriate value of selected alarm type
          if(filteredAlarmTypeList[firstKey] === 'HiHiSP'){
            setAlarmSetpoint(HiHiSP);
          };
          if(filteredAlarmTypeList[firstKey] === 'HighSP'){
            setAlarmSetpoint(HighSP);
          };
          if(filteredAlarmTypeList[firstKey] === 'LoLoSP'){
            setAlarmSetpoint(LoLoSP);
          };
          if(filteredAlarmTypeList[firstKey] === 'LowSP'){
            setAlarmSetpoint(LowSP);
          };

        }

      }

  
    } catch (error) {
      console.log(error);

    }
  }
  if(tagTypeSetDN === true){
    getAlarmConfigtData();
    setTagTypeSetDN(false);
  }

  

  }, [tagTypeSetDN]);

  

  const handleProcessValueChange = (event) =>{
    const {name, value} = event.target;
    setProcessValue(value);
    setWriteData({...writeData, [name]:value, OldValue: params.row.ProcessValue, NewValue: value, ValueType:'Process Value'});
    setNewValues ({...newValues, [name] :value});
  }

  const handleSelectFieldChange = (event) =>{
    const {name, value} = event.target;
    //console.log(value)
    setAlarmType(value);
    if(value === 'HiHiSP'){
      setAlarmSetpoint(HiHiSP);
    };
    if(value === 'HighSP'){
      setAlarmSetpoint(HighSP);
    };
    if(value === 'LoLoSP'){
      setAlarmSetpoint(LoLoSP);
    };
    if(value === 'LowSP'){
      setAlarmSetpoint(LowSP);
    };
  }

  const [setpointChangeStatus, setSetpointChangeStatus] = useState({
    'HiHiSP':false,
    'HighSP':false,
    'LoLoSP':false,
    'LowSP':false,
  });

  const handleTextFieldChange0 = (event) => {
    const { value } = event.target;
    
    // Ensure input is numeric and limit decimal places
    if (/^\d*\.?\d*$/.test(value)) { // Regex to allow only digits and one decimal point
      let decimalPlaces =4;
      const trimmedValue = value.trim(); // Remove leading and trailing spaces
      const parts = trimmedValue.split('.'); // Split integer and decimal parts

      // Limit decimal places
      if (parts.length > 1 && parts[1].length > decimalPlaces) {
        parts[1] = parts[1].slice(0, decimalPlaces); // Keep only the first `decimalPlaces` digits
      }
      //console.log(parts.join('.'))

      //setInputValue(parts.join('.')); // Join integer and decimal parts back together
    }
  };
  
  const handleTextFieldChange = (event) => {
    const {name, value} = event.target;
    if (/^-?\d*\.?\d*$/.test(value)) { 
    setAlarmSetpoint(value);
    
    if(alarmType === 'HiHiSP'){
      //setAlarmSetpoint(value);
      setHiHiSP(value);
      setWriteData({...writeData, 'HiHiSP':value, OldValue: params.row.HiHiSP, NewValue: value, ValueType:'HiHi Setpoint'});
      setNewValues ({...newValues, 'HiHiSP':value});
      if(value !== params.row.HiHiSP){
        setSetpointChangeStatus({...setpointChangeStatus, 'HiHiSP':true })
      }
    };
    if(alarmType === 'HighSP'){
      //setAlarmSetpoint(value);
      setHighSP(value);
      setWriteData({...writeData, 'HighSP':value, OldValue: params.row.HighSP, NewValue: value, ValueType:'High Setpoint'});
      setNewValues ({...newValues, 'HighSP':value});
      if(value !== params.row.HighSP){
        setSetpointChangeStatus({...setpointChangeStatus, 'HighSP':true })
      }
    };
    if(alarmType === 'LoLoSP'){
      //setAlarmSetpoint(value);
      setLoLoSP(value);
      setWriteData({...writeData, 'LoLoSP':value, OldValue: params.row.LoLoSP, NewValue: value, ValueType:'LoLo Setpoint'});
      setNewValues ({...newValues, 'LoLoSP':value});
      if(value !== params.row.LoLoSP){
        setSetpointChangeStatus({...setpointChangeStatus, 'LoLoSP':true })
      }
    };
    if(alarmType === 'LowSP'){
      //setAlarmSetpoint(value);
      setLowSP(value);
      setWriteData({...writeData, 'LowSP':value, OldValue: params.row.LowSP, NewValue: value, ValueType:'Low Setpoint'});
      setNewValues ({...newValues, 'LowSP':value});
      if(value !== params.row.LowSP){
        setSetpointChangeStatus({...setpointChangeStatus, 'LowSP':true })
      }
    };
  }

  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    if(writeData.RemarkStatus === "Enabled"){
      setAuditTrailRemark(value);

    }
    
    
  };

  const handleClose = () => {
    //console.log("Close Buton Pressed");
    close() // Close the alarm add main window
  };

  const handleRecordSaveSuccess = (data) =>{
    close(data)
  }



  return (
    <div>
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            maxWidth: '1000px', // Change this value as needed
          },
        }}
      >
        {/* Hnadle multiple functions on single click ie edit switch set to false and close the MyAccount Dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose();  }}>
          Edit parameter value.
        </BootstrapDialogTitle>

        {/*Setpoint Changes*/}
        <DialogContent dividers>
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 200 }}>
          {params.row.TagDescription1}
          </Typography>

          {/* TagName */}
          <TextField
            margin="dense"
            label="parameter"
            //type="number"
            //fullWidth
            variant="standard"
            name="TagName"
            value={TagName}
            disabled={true}
          />

          {/* If ProcessValue is number then show it */}
          {valueType === 'Number' && (
          <TextField
            margin="dense"
            label="Process Value"
            //type={valueType === 'Number' ? 'text' : 'number'}
            type="text"
            //fullWidth
            variant="standard"
            name="ProcessValue"
            value={ProcessValue}
            onChange={handleProcessValueChange}
            disabled={functionCode === 1 || functionCode === 2 || functionCode === 3 || functionCode === 4 || functionCode === 5 || functionCode === 15 ||tagStatus === 'AlarmTag' }
          />
          )}

          {/* If ProcessValue is string then show it */}
          {valueType === 'String' && (
          <FormControl sx={{ m: 0, width: 200, mt: 1, mr:2, }} variant="standard">
            <InputLabel id="demo-customized-select-label">Process Value</InputLabel>
            <Select
            autoFocus
            variant="standard"
            name="ProcessValue"
            value={ProcessValue}
            onChange={handleProcessValueChange}
            disabled={functionCode === 1 || functionCode === 2 || functionCode === 3 || functionCode === 4 || functionCode === 5 || functionCode === 15 ||tagStatus === 'AlarmTag'}
            //input={<BootstrapInput />}
            >
            <MenuItem value={onLabel}>{onLabel}</MenuItem>
            <MenuItem value={offLabel}>{offLabel}</MenuItem>
            </Select>
          </FormControl>
          )}


           
          {/* AlarmType */}
          <FormControl sx={{ m: 0, width: 200, mt: 1, mr:2, }} variant="standard">
              <InputLabel id="demo-customized-select-label">Alarm Type</InputLabel>
              <Select 
               autoFocus
               variant="standard"
               name="AlarmType"
               value={alarmType} 
               //displayEmpty
               onChange={handleSelectFieldChange}
               disabled={tagStatus !== 'AlarmTag'}
               >
                {/* <MenuItem value="" disabled>Select an option</MenuItem> */}
                {Object.entries(alarmTypeList).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}  {/* Customize label as needed */}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>


          {/* Alarm Setpoint */}
          <TextField
            margin="dense"
            label="Alarm Setpoint"
            //type="number"
            //fullWidth
            variant="standard"
            name="AlarmSetpoint"
            value={alarmSetpoint}
            onChange={handleTextFieldChange}
            disabled={tagStatus !== 'AlarmTag'}
          />

          
        </DialogContent>

        {/*Remark */}
        {writeData.RemarkStatus === 'Enabled' && (<DialogContent 
        dividers
        >
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 600 }}>
          Enter remark for parameter value change 
          </Typography>

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            name="Remark"
            placeholder="Enter Remark"
            multiline
            rows={4}
            style={{ width: 800 }}
            onChange={handleRemarkChange}
            //defaultValue="Default Value"
          />

        </DialogContent>
        )}

        <DialogActions>
          <SaveChangesDialog
          initData = {initialWriteData}
          rowData = {writeData}
          tagStatus = {tagStatus}
          alarmSetpoint = {alarmSetpoint}
          dataType = {dataType}
          valueType = {valueType}

          email = {user.email}
          role = {user.role}
          oldValues = {oldValues}
          newValues = {newValues}
          activity = {eventDescription}
          tagName = {params.row.TagName}
          setpointChangeStatus = {setpointChangeStatus}
          auditTrailRemark = {auditTrailRemark}

          success = {handleRecordSaveSuccess}
          />
        </DialogActions>
        
      </BootstrapDialog>
    </div>
  );
}



