import * as React from 'react';
import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TextField, Button, FormControl, makeStyles } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, GridEditInputCell} from '@mui/x-data-grid';
import {randomId} from '@mui/x-data-grid-generator';

import DashboardViewService from '../../services/dashboardview.service.js';
import AuditTrailViewService from '../../services/audittrailview.service.js';
import DashboardGroupListAdd from './DashboardGroupListAdd.js';


import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import Alert from '@mui/material/Alert';


// Add row to the existing rows
function EditToolbar(props) {
  const { setAddRecordForm } = props;

  const handleClick = () => {
    setAddRecordForm(true);
  };


  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add New Dashboard Group
      </Button>
    </GridToolbarContainer>
  );
}


export default function UserGroupList() {
  const { user } = useSelector((state) => state.auth);
  const [fetchData, setFetchData] = React.useState(true); // Fetch all row data enable bit
  const [rowsData, setRowsData] = React.useState([]); // All Row Data fetched 
  const [blockNameList, setBlockNameList] = React.useState([]) // retrived BlockName lsit From received data

  const [rowModesModel, setRowModesModel] = React.useState({}); 

  const [saveData, setSaveData] = React.useState(false);  // Save specific Row Data
  const [rowData, setRowData] = React.useState([]); // Specific Row Data

  const[deleteDialog, setDeleteDialog] = React.useState(false); // Delete Dialog bit
  const noButtonRef = React.useRef(null); 

  const[deleteData, setDeleteData] = React.useState(false); //Delete row bit
  const [snackbar, setSnackbar] = React.useState(null); // if delete Success then set to success otherwise will set to fail

  const handleCloseSnackbar = () => setSnackbar(null);

  const [addRecordForm, setAddRecordForm] = React.useState(false)

  const[remarkDialog, setRemarkDialog] = React.useState(false); // Remark Dialog bit
  const [eventDescription, setEventDescription] = useState("--");
  const [auditTrailRemark, setAuditTrailRemark] = useState("--");
  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);
  const [password, setPassword] = useState('');
  const[pwdinterlock, setPwdInterlock] = useState("Disabled");

 
  // Get  All row data 
  useEffect(() =>{
    async function getAllRowsData()  {
    try {

      let dashboardName = "DashboardGroupList"
      //const offset = page * rowsPerPage;
      //console.log(page);
      //console.log(offset);
      //console.log(rowsPerPage);
      setRowsData([]);
      const response = await DashboardViewService.getAllRowsData(dashboardName);
      if (response){
        setRowsData(response.data);
        //let BlockNames = response.data.map((item) => item.BlockName);
        setBlockNameList(response.data.map((item) => item.BlockName)); 
      }
      
      
    } catch (error) {
      console.log(error);

    }
  }
  if(fetchData){
    getAllRowsData();
    setFetchData(false);
    
  };


  }, [fetchData]);

  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Delete Dashboard Group';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setRowData({...rowData, EventStatus: response.data[0].EnableEvent, PWDInterlock: response.data[0].EnablePwdInterlock, RemarkStatus: response.data[0].EnableRemark});
        setPwdInterlock(response.data[0].EnablePwdInterlock);
        setEventDescription(response.data[0].DefaultEventDescription);
        // If remark field is disabled then set default remark vlaue to currrent Remark value
        if(response.data[0].EnableRemark === "Disabled"){
          setAuditTrailRemark(response.data[0].DefaultEventRemark);
        }
        
      }

    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);


  // Delete  Specific Row row data 
  useEffect(() =>{
    async function deleteRowData()  {
    try {

      let dashboardName = "DashboardGroupList"
      let auditTrailData =[{
        TimeStamp:new Date(),
        Activity: `Removed user group - '${rowData.GroupName}'`,
        ActivityType: "Delete User Group",
        UserName: user.email,
        Role: user.role,
        Remark: auditTrailRemark,
        EventStatus: rowData.EventStatus,
      }]
      //console.log(rowsData);
      //console.log(rowData);
      if (rowData === null){
        console.log("Provide Proper Id to delete record")
      }else{
        if(rowData.GroupName === 'Common'){
          setSnackbar({ children: " 'Common' Group can not be deleted", severity: 'error' });
        }else{
          //console.log(rowData)
          // Delete row from UserGroupList for selected rowData
          let updatedRowData = {...rowData, email:user.email, role: user.role, PWDInterlock: pwdinterlock, PWD: password};
          const response = await DashboardViewService.deleteRow(dashboardName, updatedRowData, auditTrailData);  
          if(response){
            setFetchData(true);  // reload the data again from backend
            setSnackbar({ children: 'Deleted User group successfully', severity: 'success' });
          }

        }

      }
    

    } catch (error) {
      setSnackbar({ children: "User group deletion Failed", severity: 'error' });
      console.log(error);

    }
  };
  if(deleteData){
    deleteRowData();
    setDeleteData(false);
  };


  }, [deleteData]);

  const handleClose = () => {
    setOpenRemarkBlnkMsg(false);
  };

  const handleColorPaletteClick = () =>{

  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  
    handleColorPaletteClick();

  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    
  };

  const handleDeleteYes = () => {
    setDeleteData(true);
    setDeleteDialog(false);
    setRemarkDialog(false);
  };

  const handleDeleteNo = () => {
    setDeleteDialog(false);
    setRemarkDialog(false);
    setAuditTrailRemark("--"); // Clear remark field
  };

  const renderDeleteConfirmDialog0 = () => {
    if (!deleteDialog) {
      return null;
    }

    //const { newRow, oldRow } = promiseArguments;
    //const mutation = computeMutation(newRow, oldRow);
    return (
      <Dialog
        maxWidth="xs"
        //TransitionProps={{ onEntered: handleEntered }}
        open={!!deleteDialog}
      >
        <DialogTitle>Are you sure to delete selected group?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will delete the selecetd Group.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleDeleteNo}>
            No
          </Button>
          <Button onClick={handleDeleteYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderDeleteConfirmDialog = () => {
    if (!deleteDialog) {
      return null;
    }

    //const { newRow, oldRow } = promiseArguments;
    //const mutation = computeMutation(newRow, oldRow);
    return (
      <div>

      <Dialog
        maxWidth="xs"
        //TransitionProps={{ onEntered: handleEntered }}
        open={!!deleteDialog}
      >
        <DialogTitle>Are you sure to delete selected user group?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will delete the selecetd user group and info.`}
        </DialogContent>
        
        {pwdinterlock === 'Enabled' && (<DialogContent dividers>
          <DialogContentText>
            Enter the Password to delete the user group.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          </DialogContent>
        )}

        <DialogActions>
          <Button ref={noButtonRef} onClick={handleDeleteNo}>
            No
          </Button>
          <Button onClick={handleDeleteYes}>Yes</Button>
        </DialogActions>
      </Dialog>

      </div>
    );
  };

  const handleDelete = () => ()=> {   
    if(((rowData.RemarkStatus === "Enabled") && (auditTrailRemark === "--")) || ((rowData.RemarkStatus === "Enabled") && (auditTrailRemark === ""))){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
      //console.log("test2");
      setDeleteDialog(true);
      setRemarkDialog(false);
      
    }
    
  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    if(rowData.RemarkStatus === "Enabled"){
      setAuditTrailRemark(value);

    }
  };

  const renderRemarkDialog = () => {
    if (!remarkDialog) {
      return null;
    }
    //console.log("test1");
    //const { newRow, oldRow } = promiseArguments;
    //const mutation = computeMutation(newRow, oldRow);
    return (
      <div>

      <Dialog
        maxWidth="xs"
        //TransitionProps={{ onEntered: handleEntered }}
        open={!!remarkDialog}
      >
        <DialogTitle>Enter Remark</DialogTitle>
        {/*Remark */}
        <DialogContent 
        dividers
        >
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 350 }}>
          Enter remark or reason for delete action
          </Typography>

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            name="Remark"
            placeholder="Enter Remark"
            multiline
            rows={2}
            style={{ width: 350 }}
            onChange={handleRemarkChange}
            //defaultValue="Default Value"
          />

        </DialogContent>


        <DialogActions>
          <Button ref={noButtonRef} onClick={handleDeleteNo}>
            Cancel
          </Button>
          <Button onClick={handleDelete()}>Delete</Button>
        </DialogActions>
      </Dialog>

      </div>
    );
  };


  const handleDeleteClick = (params) => () => {
    //setRowData({...params.row, email:user.email, role: user.role});
    //setDeleteDialog(true);
    setRowData({...params.row, RemarkStatus: rowData.RemarkStatus, EventStatus:rowData.EventStatus});
    if(rowData.RemarkStatus === "Enabled"){
      //console.log("test0");
      setRemarkDialog(true);
    }else{
      //console.log("test0");
      const withoutRemark = handleDelete();
      withoutRemark();
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rowsData.find((row) => row._id === id);
    if (editedRow.isNew) {
      setRowsData(rowsData.filter((row) => row._id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsData(rowsData.map((row) => (row._id === newRow._id ? updatedRow : row)));
    setRowData(newRow);
    setSaveData(true);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRecordAdded = (data) => {
    
    setAddRecordForm(false);
    setFetchData(true);  // reload the data again from backend
    //console.log("Record Added", data);
    
  };

  const columns = [

    { field: 'ProjectName', headerName: 'Project Name', type:'singleSelect', valueOptions: ['Enabled', 'Disabled'], width: 200, align: 'center', headerAlign: 'center',},
    { field: 'DashboardType', headerName: 'Dashboard Type', type: 'string', width: 200, align: 'center', headerAlign: 'center',},
    { field: 'GroupName', headerName: 'Group Name', type: 'string', width: 200, align: 'center', headerAlign: 'center',},
    { field: 'GroupDescription', headerName: 'Group Description', type: 'string', width: 400, align: 'center', headerAlign: 'center',},

    
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        const id = params.row._id;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  

  return (
    <div>
    {renderDeleteConfirmDialog()}
    {renderRemarkDialog()}
    
    <Paper
      sx={{
        p: 1,
        margin: 'auto',
        maxWidth: 1100,
        maxHeight:600,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
      elevation={24}
    >
     
     <Box sx={{ height: 600 }}>
    
      <DataGrid
        rows={rowsData}
        getRowId={(rowsData) => rowsData._id} // This line will set id of the datagrid row = _id of the mongodb database, otherwisw error can occured.
        columns={columns}
        hideFooterPagination = {true}
        //pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setAddRecordForm },
        }}
      />
      </Box>

      {addRecordForm && (
      <DashboardGroupListAdd open= {addRecordForm} close = {handleRecordAdded} />
      )}

    
      {!!snackbar && (
        <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }}  open onClose={handleCloseSnackbar} autoHideDuration={4000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      
    
    </Paper>
    
    </div>
    
  );
}