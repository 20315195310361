import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Draggable from "react-draggable";
import {Resizable} from "re-resizable";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const DynamicForeignObjectSVG = () => {
  const style = {
    display: "flex",
    height: 240,
    width:580,
    minWidth: 1200,
    minHeight:1580,
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "white"
    };
  const [objects, setObjects] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const addForeignObject = () => {
    if (inputValue.trim() !== '') {
      setObjects([...objects, inputValue]);
      setInputValue('');
    }
  };

  return (
    <React.Fragment>
        <Container maxWidth="xl" sx={{ mt: 4, mb: 14, ml: 10}}  >
        {/* Chart */}
        <Grid container spacing={3}>
        <Draggable >
        
        <Paper sx={{p: 1, display: 'flex', flexDirection: 'column',}} elevation={24} >
        <Resizable defaultSize={{
                        width: 1680,
                        height: 640,
                    }} 
                    style={style}> 

    <div>
      <h4>Dynamic Object</h4>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Enter text for Object"
      />
      <button onClick={addForeignObject}>Add Object</button>

      <svg width="600" height="400" style={{ border: '1px solid black', marginTop: '20px' }}>
        <rect x="0" y="0" width="100%" height="100%" fill="lightblue" />
        {objects.map((text, index) => (
          <foreignObject key={index} x={50} y={50 + index * 60} width="200" height="50">
            <body xmlns="http://www.w3.org/1999/xhtml">
              <div
                style={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '5px',
                  padding: '10px',
                }}
              >
                {text}
              </div>
            </body>
          </foreignObject>
        ))}
      </svg>
    </div>
    </Resizable>
        </Paper>
        
        </Draggable>   
        </Grid>
            




        </Container>
 
    </React.Fragment>
  );
};

export default DynamicForeignObjectSVG;
