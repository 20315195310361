import { SketchPicker, BlockPicker } from "react-color";
import { useState } from 'react';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


export default function ColorPicker({open, close}) {
  //creating state to store our color and also set color using onChange event for sketch picker
  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  // destructuring rgba from state
  const { r, g, b, a } = sketchPickerColor;

  //creating state to store our color and also set color using onChange event for block picker
  const [selectedColor, setSelectedColor] = useState("#F17013");


  const handleClose = () => {

    close("NoChange") // Close the colorSelector window
  };

  const handleSetColor = () => {

    close(selectedColor) // Close the colorSelector window
  };


  return (
    <div
      className="App"
      style={{ display: "flex", justifyContent: "space-around" }}
    >
      
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            maxWidth: '1000px', // Change this value as needed
          },
        }}
      >
        {/* Hnadle multiple functions on single click ie edit switch set to false and close the MyAccount Dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose();  }}>
          Color Selection
        </BootstrapDialogTitle>

        {/*Widget Name setting */}
        <DialogContent dividers>
          
        <div className="sketchpicker">
       
        <Button onClick={() => handleSetColor()} style={{ background: selectedColor, color: 'white', border: 'none', width: '220px', height: '40px' }}> Set Color</Button>

        <p>
            
        </p>
    
        {/* Div to display the color  */}
        <div>
        Selected Color: <span style={{ color: selectedColor }}>{selectedColor}</span>
        </div>
        
        {/* Sketch Picker from react-color and handling color on onChange event */}
        <SketchPicker
          onChange={(color) => {
            setSketchPickerColor(color.rgb);
            setSelectedColor(color.hex);
          }}
          color={sketchPickerColor}
        />
      </div>


        </DialogContent>
        
      </BootstrapDialog>

    </div>
  );
}

//export default App;