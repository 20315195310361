import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import LockResetIcon from '@mui/icons-material/LockReset';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Raosaheb from "../assets/Raosaheb.jpg";

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { downloadProfilePhoto, uploadProfilePhoto, changeuserinfo, changepassword } from "../slices/auth";
import AuthService from "../services/auth.service";
import { clearMessage } from "../slices/message";
import { logout } from "../slices/auth";
import EventBus from "../common/EventBus";

import AuditTrailViewService from '../services/audittrailview.service';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


function ChangePasswordDialog({editSwitchStatus, email}) {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [reconfirmPassword, setReconfirmPassword] = useState('');

  const [wrongPassword, setwrongPassword] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  
  const[auditTrailConfigData, setAuditTrailConfigData] = useState({Remark: '--', RemarkStatus: 'Enabled' });
  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  useEffect(() => {dispatch(clearMessage());}, [dispatch]);

  const logOut = useCallback(() => {
    
    dispatch(logout());
    console.log("Logout button was pressed")
    
  }, [dispatch]);

  useEffect(() => {
    

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [logOut]);

  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Change Password';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setAuditTrailConfigData({...auditTrailConfigData, Remark: '--', RemarkStatus: response.data[0].EnableRemark});
      }
  
    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);



  const handleClickOpen = () => {
    if(editSwitchStatus === true ){
      setOpen(true);
    }
  };

  const handleClose = () => {
    setPassword('');
    setNewPassword('');
    setReconfirmPassword('');
    setOpen(false);
  };

  const handleFailedDialogClose = () =>{

    setPasswordNotMatch(false);


  };

  const handleSuccessDialogClose = () =>{

    setPasswordChangeSuccess(false);
    
    navigate("/login");
    window.location.reload();
    logOut();

  };

  const handleSubmit = () =>{
    // Check remark field is blank or not if not proceed else display dialog
    if((auditTrailConfigData.RemarkStatus === "Enabled") && (auditTrailConfigData.Remark === "--") || (auditTrailConfigData.RemarkStatus === "Enabled") && (auditTrailConfigData.Remark === "")){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
      setPasswordChangeSuccess(false);
      setwrongPassword(false);

      if(newpassword && newpassword.toString().length >= 8 && newpassword.toString().length <= 40 && newpassword === reconfirmPassword){
        let remark = auditTrailConfigData.Remark;
        dispatch(changepassword({email, password, newpassword, remark }))
        .unwrap()
        .then(() => {
          setPasswordChangeSuccess(true);
          //handleSuccessDialogClose();
          console.log("successfuly saved changes");
          
        })
        .catch(() => {
          setPasswordChangeSuccess(false);
          setwrongPassword(true);
          console.log("Failed to save changes");
        });

      }else{
        setPasswordNotMatch(true);
      }

    }

  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    setAuditTrailConfigData({...auditTrailConfigData, [name]:value});
  };

  const handleRemarkBlnkClose = () => {
    setOpenRemarkBlnkMsg(false);
  };



  return (
    <div>
      <Button variant="outlined" startIcon ={<LockResetIcon/>} sx={{mt:1}} onClick={handleClickOpen}>Change password </Button>

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleRemarkBlnkClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleRemarkBlnkClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* New Password entry dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Password</DialogTitle>
        <Box sx={{width: 400 }}>
        <DialogContent dividers>
          <DialogContentText>
            Enter the current and new password to change it.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Current Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          <TextField
            margin="dense"
            id="newpassword"
            label="New Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
          />

          <TextField
            margin="dense"
            id="confirmNewPassword"
            label="Confirm new Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setReconfirmPassword(event.target.value);
            }}
          />

        </DialogContent>

        {/*Remark */}
        {auditTrailConfigData.RemarkStatus === 'Enabled' && (<DialogContent 
          dividers
          >
            <Typography gutterBottom sx={{ mt: 0, mb:3, width: 350 }}>
            Enter remark/reason for Password change  
            </Typography>

            <TextField
              id="outlined-multiline-static"
              label="Remark"
              name="Remark"
              placeholder="Enter Remark"
              multiline
              rows={2}
              style={{ width: 350 }}
              onChange={handleRemarkChange}
              //defaultValue="Default Value"
            />

          </DialogContent>
        )}

        </Box>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Wrong Old Password entered */}
      <Dialog
        open={wrongPassword}
        onClose={handleSuccessDialogClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* {message} */}
            Wrong Old Password entered or new password is from last 4 passwords
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>{setwrongPassword(false);}}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* New Paaword and reconfirm Password is not matching */}
      <Dialog
        open={passwordNotMatch}
        onClose={handleFailedDialogClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-inputs" component={'span'}> {/* component={'span'} required herewith to avoid error of the nesting of <p> */}
            <Typography gutterBottom>
              One of the below condition is not fulfilled...
            </Typography>
            <Typography gutterBottom>
              1. New password and Reconfirmed password is not matching.
            </Typography>
            <Typography gutterBottom>
              2. Password length should be min 8 characters.
            </Typography>           
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFailedDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>


      {/* Password Changed Successfully */}
      <Dialog
        open={passwordChangeSuccess}
        onClose={handleSuccessDialogClose}
       
      >
        <DialogTitle > Success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* {message} */}
            Password Change Successful...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

function SaveChangesDialog({editSwitchStatus, singleImage, userid, username, lastname, email}) {
  const [open, setOpen] = useState(false);

  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  useEffect(() => {dispatch(clearMessage());}, [dispatch]);

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFaildialog, setOpenFaildialog] = useState(false);
  const [password, setPassword] = useState('');


  const[auditTrailConfigData, setAuditTrailConfigData] = useState({Remark: '--', RemarkStatus: 'Enabled' });
  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);
  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Change User Information';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setAuditTrailConfigData({...auditTrailConfigData, Remark: '--', RemarkStatus: response.data[0].EnableRemark});
      }
  
    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);

  const handleClickOpen = () => {
    if(editSwitchStatus === true ){
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccessClose = () => {
    setOpenSuccessDialog(false);
    setOpenFaildialog(false);
  };

  const handleClickSaveChanges = () => {
    // Check remark field is blank or not if not proceed else display dialog
    if((auditTrailConfigData.RemarkStatus === "Enabled") && (auditTrailConfigData.Remark === "--") || (auditTrailConfigData.RemarkStatus === "Enabled") && (auditTrailConfigData.Remark === "")){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
      setSuccessful(false);
      let remark = auditTrailConfigData.Remark;
      dispatch(changeuserinfo({ userid, username, lastname, email, password, remark}))
        .unwrap()
        .then(() => {
          if(singleImage){
            dispatch(uploadProfilePhoto({email, singleImage}))
          .unwrap()
          .then(() => {
            
            //setImageSaveSuccess(true);
            console.log("successfuly saved changes");
          })

          }
          
        })
        .then(() =>{
          setPassword('');
          setOpen(false);
          setOpenSuccessDialog(true);
          setSuccessful(true);
          //console.log("successfuly saved changes");

        })
        .catch(() => {
          setSuccessful(false);
          setOpenFaildialog(true);
          console.log("Failed to save changes");
        });


    }

    

  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    setAuditTrailConfigData({...auditTrailConfigData, [name]:value});
  };

  const handleRemarkBlnkClose = () => {
    setOpenRemarkBlnkMsg(false);
  };

 

  return (
    
    <div>
      <Button variant="outlined" startIcon ={<SaveIcon/>} sx={{mt:1}} onClick={handleClickOpen}>SAVE CHANGES </Button>

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleRemarkBlnkClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleRemarkBlnkClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>SAVE CHANGES</DialogTitle>
        <Box sx={{width: 400 }}>
          {/*Remark */}
          {auditTrailConfigData.RemarkStatus === 'Enabled' && (<DialogContent 
            dividers
            >
              <Typography gutterBottom sx={{ mt: 0, mb:3, width: 350 }}>
              Enter remark/reason for User info change  
              </Typography>

              <TextField
                id="outlined-multiline-static"
                label="Remark"
                name="Remark"
                placeholder="Enter Remark"
                multiline
                rows={2}
                style={{ width: 350 }}
                onChange={handleRemarkChange}
                //defaultValue="Default Value"
              />

            </DialogContent>
          )}

          
          <DialogContent dividers>
            <DialogContentText>
              Enter the Password to make changes in user information.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="oldPassword"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />

          </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClickSaveChanges}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Success dialog after upgradtion of teh user info. */}
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessClose}
       
      >
        <DialogTitle > success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Save Changes Failed dialog /Wrong Password */}
      <Dialog
        open={openFaildialog}
        onClose={handleSuccessClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Close</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}


export default function MyAccount ({open, close}) {
  const { user } = useSelector((state) => state.auth);
  const [username, setUsername] = useState(user.username);
  const [lastname, setLastname] = useState(user.lastname);
  const [userid, setUserid] = useState(user.id);
  const [role, setRole] = useState(user.role);
  const [email, setEmail] = useState(user.email);

  const [targetImage, setTargetImage] = useState(null);
 
  const [editSwitch, setEditSwitch] = useState(false);
  const handleEditSwitchChange = (event) => {
    setEditSwitch(event.target.checked);
    
  };


  const sendImageToServer = async(file) => {

    if (!file) return
    try{
      return await fetch('api/...', {method: 'POST', body: file})
      .then(res => {
        console.log("Success", res);
      });
    } catch(error){
      console.log(error);
    }
  
  }

  const fileInput = React.useRef();
  const [image, setImage] = useState(null);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  useEffect(() => {dispatch(clearMessage());}, [dispatch]);

  const[imageSaveSuccess, setImageSaveSuccess] = useState(false);
  const handleClickIconButton = (event) => {
    if(editSwitch){
      //setImage(Raosaheb);
      fileInput.current.click()

    }
    
  };
  const handleImageUpload = (event) => {
    //console.log(event.target.files[0]);
    
    if(event.target.files.length !== 0){
      setTargetImage(event.target.files[0]); 
      setImage( URL.createObjectURL(event.target.files[0]));   

    }
    
    //Revoke the image after logout or clsoing the windows to make browser memory free
    //URL.revokeObjectURL(image);

  };

  const handleImageDownload= async (email) => {
    try {

      const response = await AuthService.downloadProfilePhoto(email);

      //console.log(response.data);
      const base64 = btoa(new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte),
         ''));
      setImage('data:image/jpeg; base64,' + base64);
     
      //setImage(response.data);
    } catch (error) {
      console.log(error);
      
    }
  }

  useEffect(() => {handleImageDownload(email);}, []);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const handleClose = () => {
    setEditSwitch(false);


  };

  


  return (
    <div>
      
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* Hnadle multiple functions on single click ie edit switch set to false and close the MyAccount Dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose(); close()}}>
          Personal Info
          <FormControlLabel
          control={<Switch color="primary" />}
          label="Edit"
          labelPlacement="end"
          sx={{
            position: 'absolute',
            right: 50,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          checked={editSwitch}
          onChange={handleEditSwitchChange}
          />
          
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Typography gutterBottom>
          Manage personal profile information.... 
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center',  justifyContent: 'center', textAlign: 'center' }}>
            <Tooltip title="Profile Picture">
              <IconButton
                //onClick={handleClickIconButton}
                onClick={handleClickIconButton}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                
              >
                <input 
                  ref={fileInput} 
                  
                  type="file" 
                  style={{ display: 'none' }} 

                  onChange={handleImageUpload}
                  accept="image/*"
                  id="image-upload"

                />

                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar >
                      <ModeEditOutlineIcon />
                    </SmallAvatar>
                  }
                  invisible={!editSwitch}
                >
                  <Avatar alt="Raosaheb Patil" src={image} sx={{ width: 56, height: 56 }} />
                </Badge>

              </IconButton>
             
            </Tooltip>
          </Box>
          

          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            disabled={!editSwitch}
          />

          <TextField
            autoFocus
            margin="dense"
            id="lastname"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            value={lastname}
            onChange={(event) => {
              setLastname(event.target.value);
            }}
            disabled={!editSwitch}
          />

          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            disabled={true}
          />

          <TextField
            autoFocus
            margin="dense"
            id="role"
            label="Role"
            type="text"
            fullWidth
            variant="standard"
            value={role}
            disabled={true}
          />

          <TextField
            autoFocus
            margin="dense"
            id="userid"
            label="User ID"
            type="text"
            fullWidth
            variant="standard"
            value={userid}
            disabled={true}
          />
          <ChangePasswordDialog
          editSwitchStatus = {editSwitch}
          email = {email}
          />

        </DialogContent>
        <DialogActions>
          <SaveChangesDialog
          editSwitchStatus = {editSwitch}
          singleImage = {targetImage}
          userid = {userid}
          username = {username}
          lastname = {lastname}
          email = {email}
          />
        </DialogActions>
        
      </BootstrapDialog>
    </div>
  );
}